import React, { useState } from "react";
import { ReactComponent as InformationIcon } from "../../../imgs/InfoIcon/InfoIcon.svg";

const HelpTextIcon = ({ infoText }) => {
    const [showInfoModal, setShowInfoModal] = useState(false);

    const renderInfoText = infoText.split("<br/>").map((line, index) => (
        <p key={index} className="mb-2">
            {line}
        </p>
    ));

    return (
        <div
            className="relative flex justify-start items-start"
            onMouseOver={() => setShowInfoModal(true)}
            onMouseOut={() => setShowInfoModal(false)}
            onTouchStart={() => setShowInfoModal(true)}
            onTouchEnd={() => setShowInfoModal(false)}
        >
            <InformationIcon
                width={24}
                height={24}
                className="text-black ml-2"
            />

            {showInfoModal && (
                <div className="absolute top-8 left-0 bg-white border border-gray-300 rounded-lg p-4 shadow-md w-72 text-sm z-10">
                    {renderInfoText}
                </div>
            )}
        </div>
    );
};

export default HelpTextIcon;
