import HelpTextIcon from "../../../Components/InformationIcon";

function QuickButikStepThree({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Skapa API-nyckel
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Markera kryssrutan "Aktivera API-åtkomst".</li>
                        <li>Ange ett namn för API-nyckeln.</li>
                        <li>
                            Tryck på "Spara ändringar" så kommer din API-nyckel
                            att visas.
                        </li>
                        <div className="flex items-start pt-32">
                            <li>
                                Kopiera din API-nyckel och klistra in den i
                                fältet nedan.
                            </li>
                            <HelpTextIcon infoText="API-nyckeln visas direkt efter att du har tryckt 'Spara ändringar'. Värdet är markerat i rött på bild 2." />
                        </div>

                        <div>
                            <label
                                htmlFor="apiKey"
                                className="block font-medium"
                            >
                                API-nyckel
                            </label>
                            <input
                                type="text"
                                name="apiKey"
                                value={configuration.apiKey}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/quickbutik/apisettings.png`}
                        alt="API-inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                    <img
                        src={`/images/extensions/quickbutik/apikey.png`}
                        alt="API-nyckel visning"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default QuickButikStepThree;
