import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Api from "../../../services/Api/Api";
import Context from "../../../context/Global";

const useCustomerIntegrations = ({ customerId }) => {
    const [extensions, setExtensions] = useState([]);
    const [loading, setLoading] = useState(true);
    const context = useContext(Context);

    useEffect(() => {
        const fetchExtensions = async () => {
            setLoading(true);
            try {
                const invalidTokenExtensions =
                    await context.checkIntegrationsExpiry();

                const fetchedExtensions = await Api.getCustomerIntegrations({
                    customerId,
                });

                const mergedExtensions = fetchedExtensions.map((extension) => {
                    const invalidExtension = invalidTokenExtensions?.find(
                        (invalidExt) =>
                            invalidExt.integration.id === extension.id,
                    );
                    // Some integrations store configuration in multiple nested properties (e.g., data.data, config, settings).
                    // To ensure all relevant data is available, we merge these nested objects when fetching the integration.
                    return {
                        ...extension,
                        ...extension.data,
                        status: invalidExtension?.status,
                    };
                });
                setExtensions(mergedExtensions);
            } catch (error) {
                toast.error("Misslyckades med att hämta dina integrationer");
            } finally {
                setLoading(false);
            }
        };

        fetchExtensions();
    }, []);

    return {
        extensions,
        loading,
    };
};

export default useCustomerIntegrations;
