import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

const parsedBusinessCentral = JSON.parse(
    localStorage.getItem("businessCentral"),
);

export const msalConfig = {
    auth: {
        clientId: parsedBusinessCentral?.clientId,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: process.env.REACT_APP_BUSINESS_CENTRAL_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    redirectUri: process.env.REACT_APP_BUSINESS_CENTRAL_REDIRECT_URL,
    scopes: ["https://api.businesscentral.dynamics.com/.default"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

/**
 * API for getting all orders in Business Central
 * To be able to get sales orders company needs to be selected.
 */
export const getSalesOrdersApi = {
    endpoint:
        "https://api.businesscentral.dynamics.com/v2.0/production/api/v2.0/companies(b9345bf1-529e-ed11-988a-000d3a39a068)/salesOrders", // For queries ?$filter=number gt '101002' and status eq 'Created'
};

/**
 * Custom logic to get refresh token because we don't have inbuilt method for it
 */
export const getRefreshToken = () => {
    let refreshToken;
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.indexOf("login.windows.net-refreshtoken") !== -1) {
            refreshToken = localStorage.getItem(key);
            break; // stop the loop once the first matching key is found
        }
    }
    if (refreshToken) return JSON.parse(refreshToken);
};

export const clearLoginStorage = () => {
    let keys = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.indexOf("-login.windows.net-") !== -1) {
            keys.push(key);
        }
    }
    keys.forEach((key) => {
        localStorage.removeItem(key);
    });
    sessionStorage.removeItem("msal.interaction.status");
    localStorage.removeItem("businessCentral");
};

/**
 *
 * @param {*} refreshToken
 * @param {*} clientId
 */
export const refreshAccessToken = async (refreshToken, clientId) => {
    const response = await fetch(
        "https://login.microsoftonline.com/common/oauth2/v2.0/token",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `
      grant_type=refresh_token
      &client_id=${clientId}
      &refresh_token=${refreshToken}
      &scope=https://api.businesscentral.dynamics.com/.default`,
        },
    );
    const data = await response.json();
    return data;
};
