import { Link } from "react-router-dom";
import ExtensionCard from "./Components/ExtensionCard";
import HeaderSection from "./Components/HeaderSection";
import React, { useContext } from "react";
import Context from "../../context/Global";
import useCustomerIntegrations from "./hooks/useCustomerIntegrations";
import Loader from "../../components/Loader/Loader";
import {
    EXPIRE_SOON,
    EXPIRED,
} from "../../constants/integrations/IntegrationStatuses";

const ExtensionLandingPage = ({ history }) => {
    const context = useContext(Context);
    const { extensions, loading } = useCustomerIntegrations({
        customerId: context.user.customer.id,
    });

    if (loading) {
        return <Loader />;
    }

    const expiredExtensions = extensions.filter(
        (ext) => ext.status === EXPIRED,
    );

    return (
        <div className="mt-10 ml-20 mr-20 space-y-8">
            <HeaderSection
                title="Tillägg"
                description={
                    extensions.length === 0
                        ? "Lägg till en ny integration för att importera ordrar automatiskt"
                        : "Här kan du lägga till eller se dina integrationer. Tryck på en integration för att redigera."
                }
            />

            <button
                className="c-button c-button--raised w-72 h-10"
                onClick={() => {
                    history.push("/extensions/create");
                }}
            >
                Lägg till ny integration
            </button>
            {expiredExtensions.length > 0 && (
                <>
                    <h2 className="text-3xl font-semibold text-accent">
                        Dina utgångna integrationer:
                    </h2>
                    {expiredExtensions.map((extension) => (
                        <Link
                            key={extension.id}
                            to={`/extensions/${extension.id}`}
                            className="block rounded hover:bg-gray-100 cursor-pointer transition-colors duration-200 pl-2"
                        >
                            <ExtensionCard extension={extension}>
                                <h1 className="text-xl font-semibold">
                                    {extension.data.type}
                                </h1>
                                <p className="text-base font-medium text-accent">
                                    Utgått
                                </p>
                                <div className="flex items-center mt-2">
                                    <img
                                        src="/images/icons/error-icon.svg"
                                        alt="Error icon"
                                        className="w-5 h-5 mr-2"
                                    />
                                    <p className="text-sm text-error">
                                        Aktivera igen för att fortsätta
                                        importera ordrar automatiskt
                                    </p>
                                </div>
                            </ExtensionCard>
                        </Link>
                    ))}
                </>
            )}
            {extensions.length > 0 && (
                <>
                    <h2 className="text-3xl font-semibold text-accent">
                        Dina aktiva integrationer:
                    </h2>
                    {extensions
                        .filter((extension) => extension.status !== EXPIRED)
                        .map((extension) => (
                            <Link
                                key={extension.id}
                                to={`/extensions/${extension.id}`}
                                className="block rounded hover:bg-gray-100 cursor-pointer transition-colors duration-200 pl-2"
                            >
                                <ExtensionCard extension={extension}>
                                    <h1 className="text-xl font-semibold">
                                        {extension.data.type}
                                    </h1>

                                    <p className="text-base font-medium text-accent">
                                        Aktiv
                                    </p>

                                    {extension.status === EXPIRE_SOON && (
                                        <div className="flex items-center mt-2">
                                            <img
                                                src="/images/icons/warning-icon.svg"
                                                alt="Warning icon"
                                                className="w-5 h-5 mr-2"
                                            />
                                            <p className="text-sm text-warning">
                                                Går snart ut. Vänligen uppdatera
                                            </p>
                                        </div>
                                    )}
                                </ExtensionCard>
                            </Link>
                        ))}
                </>
            )}
        </div>
    );
};

export default ExtensionLandingPage;
