export const updateCustomerIntegrationsList = (context, extensionId) => {
    const updatedIntegrationsList = context.user.customer.integrations.filter(
        (integration) => integration.id !== extensionId,
    );

    context.setUser({
        ...context.user,
        customer: {
            ...context.user.customer,
            integrations: updatedIntegrationsList,
        },
    });
};

export const renewFortnoxTokens = async () => {
    return new Promise((resolve, reject) => {
        try {
            const oauthWindow = window.open(
                `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FORTNOX_OAUTH_REDIRECT_URL}&scope=article%20order%20customer&state=random%20string&access_type=offline&response_type=code`,
                "Connect to Fortnox",
                "width=500,height=500",
            );

            const windowCheckInterval = setInterval(() => {
                if (oauthWindow && oauthWindow.closed) {
                    clearInterval(windowCheckInterval);
                    resolve();
                }
            }, 500);
        } catch (error) {
            reject(error);
        }
    });
};
