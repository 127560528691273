import Input from "components/OldInput";
import InputClientNumber from "components/Input/ClientNumber";
import InputCompanyName from "components/Input/CompanyName";
import InputEmail from "components/Input/Email";
import InputMultiToggle from "components/Input/MultiToggle";
import InputPhoneNumber from "components/Input/PhoneNumber";
import InputPostalCode from "components/Input/PostalCode";
import { countryList } from "constants/countryList";
import Context from "context/Global";
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import ContactList from "./ContactList";
import useCreateDraftsStore from "../../createDraftsStore";
import toast from "react-hot-toast";

let Container = styled.div`
    > .title {
        font-size: 1.3rem;
        color: rgba(0, 0, 0, 0.6);
        padding: 0 1em 0.5em;
    }

    > .section {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        align-items: flex-start;
        flex-grow: 1;

        > .addressBook {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;
            padding-bottom: 1rem;
            margin-bottom: 0.5rem;
        }

        > .fields {
            display: flex;
            flex-direction: row;

            > form {
                width: calc(50% - 8px);
                min-width: calc(50% - 8px);
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        > .buttons {
            margin-top: auto;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 1rem;
            margin-top: 2rem;
        }

        &.differentPickup {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 16px;
            margin-top: 16px;
            display: flex;
            flex-direction: row;
        }
    }
`;

const validationMessages = {
    required: "Fältet är obligatoriskt",
    maxLength: "Fältet innehåller för många tecken",
    minLength: "Fältet innehåller för få tecken",
    invalidCharacter: "Telefonnumret innehåller ogiltiga tecken",
};

function Component({ onChange, onSelect, ...props }, ref) {
    const context = useContext(Context);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [customer, setCustomer] = useState(
        props.company || {
            countryCode: "SE",
        },
    );
    const [contact, setContact] = useState(
        props.company ? props.company.contact : null,
    );
    const [pickup, setPickup] = useState(
        props.company ? props.company.pickup : "",
    );
    const [pickupContact, setPickupContact] = useState(
        props.company && props.company.pickup
            ? props.company.pickup.contact
            : "",
    );
    const [showStateCode, setShowStateCode] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [showAddressBook, setShowAddressBook] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [customsRequired, setCustomsRequired] = useState(false);
    const [showDifferentPickupAddress, setShowDifferentPickupAddress] =
        useState(false);
    const [enableDifferentPickupAddress] = useState(
        !!props.enableDifferentPickupAddress,
    );
    const setReceiverPaysContactId = useCreateDraftsStore(
        (state) => state.setReceiverPaysContactId,
    );
    const receiverPaysContactId = useCreateDraftsStore(
        (state) => state.receiverPaysContactId,
    );
    const [enablePrivateCustomer] = useState(!!props.enablePrivateCustomer);
    const [postalCodeIsDisabled, setPostalCodeIsDisabled] = useState(false);
    const [requireEmail, setRequireEmail] = useState(true);

    const [requireCompanyName, setRequireCompanyName] = useState(
        typeof props.requireCompanyName === "boolean"
            ? props.requireCompanyName
            : true,
    );
    const [requireAddress, setRequireAddress] = useState(
        typeof props.requireAddress === "boolean" ? props.requireAddress : true,
    );
    const [requireContactName, setRequireContactName] = useState(
        typeof props.requireContactName === "boolean"
            ? props.requireContactName
            : true,
    );
    const [requirePhoneNumber, setRequirePhoneNumber] = useState(
        typeof props.requirePhoneNumber === "boolean"
            ? props.requirePhoneNumber
            : true,
    );
    const [enableDhlPalletExchangeNumber, setEnableDhlPalletExchangeNumber] =
        useState(
            typeof props.enableDhlPalletExchangeNumber === "boolean"
                ? props.enableDhlPalletExchangeNumber
                : true,
        );
    const [enableAddressBook, setEnableAddressBook] = useState(
        typeof props.enableAddressBook === "boolean"
            ? props.enableAddressBook
            : true,
    );

    const addressBookCallbackRef = useRef();
    const customerRef = useRef();
    const contactRef = useRef();
    const pickupRef = useRef();
    const pickupContactRef = useRef();

    useEffect(() => {
        setRequireEmail(props.requireEmail);
    }, [props.requireEmail]);
    useEffect(() => {
        if (typeof props.requireCompanyName === "boolean") {
            setRequireCompanyName(props.requireCompanyName);
        }
    }, [props.requireCompanyName]);
    useEffect(() => {
        if (typeof props.requireAddress === "boolean") {
            setRequireAddress(props.requireAddress);
        }
    }, [props.requireAddress]);
    useEffect(() => {
        if (typeof props.requireContactName === "boolean") {
            setRequireContactName(props.requireContactName);
        }
    }, [props.requireContactName]);
    useEffect(() => {
        if (typeof props.requirePhoneNumber === "boolean") {
            setRequirePhoneNumber(props.requirePhoneNumber);
        }
    }, [props.requirePhoneNumber]);
    useEffect(() => {
        if (typeof props.enableDhlPalletExchangeNumber === "boolean") {
            setEnableDhlPalletExchangeNumber(
                props.enableDhlPalletExchangeNumber,
            );
        }
    }, [props.enableDhlPalletExchangeNumber]);
    useEffect(() => {
        if (typeof props.enableAddressBook === "boolean") {
            setEnableAddressBook(props.enableAddressBook);
        }
    }, [props.enableAddressBook]);

    const set = (customer) => {
        if (customer) {
            let customerData = {
                countryCode: "",
                postalCode: "",
                postalTown: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                dhlPalletExchangeNumber: "",
                pickup: null,
            };
            customerData = {
                ...customerData,
                ...customer,
            };
            let contactData = {
                company: "",
                clientNumber: "",
                name: "",
                phoneNumber: "",
                email: "",
                vatNumber: "",
                private: false,
                contactId: "",
            };
            customerData.contact = {
                ...contactData,
                ...customer.contact,
            };
            customer.contact = contactData;
            if (!enableDifferentPickupAddress) {
                customerData.otherPickupAddress = false;
            }
            if (!enablePrivateCustomer) {
                customerData.contact.private = false;
            }

            if (enableDifferentPickupAddress && customer.pickup) {
                setPickup(customer.pickup);
                setPickupContact(customer.pickup.contact);
            }

            setCustomer(customerData);
            setContact(
                customerData.contact || {
                    private: false,
                },
            );
            customerRef.current.set(customerData);
            contactRef.current.set(
                customerData.contact || {
                    private: false,
                },
            );

            if (onChange) {
                onChange(customerData);
            }
            if (parseInt(customerData.id)) {
                setSelectedCustomer(customerData);
                loadContact(customerData.id);
            } else {
                setSelectedCustomer(null);
            }
        } else {
            setCustomer({});
            setContact({
                private: false,
            });
            setSelectedCustomer(null);
            customerRef.current.set({});
            contactRef.current.set({
                private: false,
            });
            if (onChange) {
                onChange({});
            }
        }
    };

    const clear = () => {
        const cleanCustomer = {
            countryCode: "SE",
            postalCode: "",
            postalTown: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            dhlPalletExchangeNumber: "",
            pickup: null,
            otherPickupAddress: false,
            contact: {
                company: "",
                clientNumber: "",
                name: "",
                phoneNumber: "",
                email: "",
                vatNumber: "",
                private: false,
                contactId: "",
            },
        };
        setCustomer(cleanCustomer);
        setContact(cleanCustomer.contact);
        setSelectedCustomer(null);
        customerRef.current.set(cleanCustomer);
        contactRef.current.set(cleanCustomer.contact);
        if (onChange) {
            onChange(cleanCustomer);
        }
    };

    const validate = () => {
        let valid = true;
        let customerData = customerRef.current.value();
        if (!customerData.countryCode) {
            valid = false;
        }

        if (customerData.countryCode === "NL") {
            if (!/[0-9]{4} ?[A-Za-z]{2}/.test(customerData.postalCode)) {
                customerRef.current.setValidationMessage({
                    key: "postalCode",
                    message:
                        'Postnummer i nederländerna måste ha formatet "NNNN AA". T.ex. "1019 AB"',
                });
                valid = false;
            }
        }

        if (customerData.otherPickupAddress) {
            if (pickupRef.current && !pickupRef.current.validate()) {
                valid = false;
            }
            if (
                pickupContactRef.current &&
                !pickupContactRef.current.validate()
            ) {
                valid = false;
            }
        }
        if (!customerRef.current.validate()) {
            valid = false;
        }
        if (!contactRef.current.validate()) {
            valid = false;
        }

        return valid;
    };

    const value = () => {
        return {
            ...customerRef.current.value(),
            contact: contactRef.current.value(),
        };
    };

    useImperativeHandle(ref, () => {
        return {
            set: set,
            value: value,
            validate: validate,
            clear: clear,
        };
    });

    async function loadContact(customerId) {
        setSelectedCustomer(
            await Api.getContact({
                contactId: customerId,
            }),
        );
    }

    useEffect(() => {
        if (onChange) {
            let c = customer || {};
            c.contact = contact || {
                private: false,
            };

            onChange(c);
        }
    }, [customer, contact]);

    function updateStateCodeVisibility(countryCode) {
        switch (countryCode) {
            case "CA":
            case "IN":
            case "BR":
            case "IE":
            case "US": {
                setShowStateCode(true);
                break;
            }
            default: {
                setShowStateCode(false);
            }
        }
    }

    useEffect(() => {
        let doShowSaveButton = false;
        let customerIsPrivate = false;
        if (customer) {
            updateStateCodeVisibility(customer.countryCode);
            setShowDifferentPickupAddress(!!customer.otherPickupAddress);

            if (
                customer.contact &&
                customer.contact.private &&
                props.enablePrivateCustomer
            ) {
                customerIsPrivate = true;
            }
            if (onChange) {
                onChange({
                    ...customer,
                    contact: contact,
                    pickup: showDifferentPickupAddress
                        ? {
                              ...pickup,
                              contact: pickupContact,
                          }
                        : null,
                });
            }

            if (selectedCustomer) {
                if (parseInt(selectedCustomer.id)) {
                    let differenceFound = [
                        "addressLine1",
                        "addressLine2",
                        "addressLine3",
                        "dhlPalletExchangeNumber",
                        "postalCode",
                        "postalTown",
                        "stateCode",
                        "countryCode",
                        "otherPickupAddress",
                    ].find((key) => {
                        return selectedCustomer[key] !== customer[key];
                    });
                    if (!differenceFound) {
                        differenceFound = [
                            "company",
                            "clientNumber",
                            "name",
                            "phoneNumber",
                            "email",
                            "private",
                            "vatNumber",
                        ].find((key) => {
                            return (
                                selectedCustomer.contact[key] !== contact[key]
                            );
                        });
                    }
                    if (differenceFound && (contact.company || contact.name)) {
                        doShowSaveButton = true;
                    }
                }
            } else if (
                customer.id !== "self" &&
                contact &&
                (contact.name || contact.company)
            ) {
                doShowSaveButton = true;
            }
        }

        setShowSaveButton(doShowSaveButton);
        setIsPrivate(customerIsPrivate);
    }, [
        customer,
        contact,
        pickup,
        pickupContact,
        showDifferentPickupAddress,
        selectedCustomer,
    ]);

    useEffect(() => {
        setCustomsRequired(!!props.customsRequired);
    }, [props.customsRequired]);

    function showContacts(ev) {
        addressBookCallbackRef.current = selectCustomer;
        setShowAddressBook(true);
        ev.stopPropagation();
        ev.preventDefault();
        return false;
    }

    function showPickupContacts(ev) {
        addressBookCallbackRef.current = selectPickup;
        setShowAddressBook(true);
        ev.stopPropagation();
        ev.preventDefault();
        return false;
    }

    function newContact() {
        setSelectedCustomer(null);
        setReceiverPaysContactId(null);
        setCustomer({
            countryCode: "SE",
        });
        setContact({
            private: false,
        });
        setPickup(null);
        setPickupContact(null);

        customerRef.current.empty();
        customerRef.current.set({
            countryCode: "SE",
        });
        contactRef.current.empty();
        contactRef.current.set({
            private: false,
        });
    }

    const getContactReceiverPays = async () => {
        try {
            if (!receiverPaysContactId) return;
            return await Api.getReceiverPays(receiverPaysContactId);
        } catch (error) {
            toast.error("Fel uppstod när mottagarfraktuppgifter skulle hämtas");
        }
    };

    async function saveContact() {
        const contactReceiverPays = await getContactReceiverPays();
        let data = {
            ...customer,
            contact: contact,
            pickup: showDifferentPickupAddress
                ? {
                      ...pickup,
                      contact: pickupContact,
                  }
                : null,
        };
        if (selectedCustomer && selectedCustomer.id) {
            try {
                const dataToUpdate = {
                    ...data,
                    receiverPays: contactReceiverPays || null,
                };
                await Api.updateContact({
                    contactId: selectedCustomer.id,
                    name: data.contact.company || data.contact.name,
                    data: dataToUpdate,
                });
                setCustomer({
                    id: selectedCustomer.id,
                    ...dataToUpdate,
                });
                setSelectedCustomer({
                    id: selectedCustomer.id,
                    ...dataToUpdate,
                });
                setReceiverPaysContactId(selectedCustomer.id);
                toast.success("Kontakten uppdaterades!");
            } catch (error) {
                toast.error("Fel uppstod när kontakten skulle uppdateras.");
            }
        } else {
            try {
                const response = await Api.createContact({
                    name: data.contact.company || data.contact.name,
                    data: data,
                });
                setCustomer({
                    id: response.data.id,
                    ...data,
                });
                setSelectedCustomer({
                    id: response.data.id,
                    ...data,
                });
                toast.success("Ny kontakt sparades!");
                setReceiverPaysContactId(response.data.id);
            } catch (error) {
                toast.error("Fel uppstod när kontakten skulle uppdateras.");
            }
        }
    }

    function selectCustomer(selectedCustomer) {
        selectedCustomer.contact.clientNumber =
            selectedCustomer.contact.clientNumber ||
            selectedCustomer.clientNumber;

        setSelectedCustomer(JSON.parse(JSON.stringify(selectedCustomer)));

        let customerData = {
            addressLine1: selectedCustomer.addressLine1 || "",
            addressLine2: selectedCustomer.addressLine2 || "",
            addressLine3: selectedCustomer.addressLine3 || "",
            dhlPalletExchangeNumber:
                selectedCustomer.dhlPalletExchangeNumber || "",
            postalTown: selectedCustomer.postalTown || "",
            postalCode: selectedCustomer.postalCode || "",
            stateCode: selectedCustomer.stateCode || "",
            countryCode: selectedCustomer.countryCode || "SE",
            otherPickupAddress: customerRef.current.value().otherPickupAddress,
        };
        let contactData = {
            name: selectedCustomer.contact.name || "",
            phoneNumber: selectedCustomer.contact.phoneNumber || "",
            vatNumber: selectedCustomer.contact.vatNumber || "",
            clientNumber: selectedCustomer.contact.clientNumber || "",
            company: selectedCustomer.contact.company || "",
            email: selectedCustomer.contact.email || "",
            private:
                typeof selectedCustomer.contact.private === "boolean"
                    ? selectedCustomer.contact.private
                    : null,
            contactId: selectedCustomer.id,
        };
        setCustomer(customerData);
        setContact(contactData);
        setShowAddressBook(false);

        if (customerData.otherPickupAddress) {
            setPickup({
                ...pickup,
                countryCode: customerData.countryCode,
            });
            pickupRef.current.set({
                countryCode: customerData.countryCode,
            });
        }
        if (props.isReceiver) {
            setReceiverPaysContactId(selectedCustomer.id);
        }
        customerRef.current.set(customerData);
        contactRef.current.set(contactData);
    }

    function selectPickup(selectedLocation) {
        selectedLocation.contact.clientNumber =
            selectedLocation.contact.clientNumber ||
            selectedLocation.clientNumber;

        let customerData = {
            addressLine1: selectedLocation.addressLine1 || "",
            addressLine2: selectedLocation.addressLine2 || "",
            addressLine3: selectedLocation.addressLine3 || "",
            postalTown: selectedLocation.postalTown || "",
            postalCode: selectedLocation.postalCode || "",
            stateCode: selectedLocation.stateCode || "",
            countryCode: customerRef.current.value().countryCode,
        };

        let contactData = {
            name: selectedLocation.contact.name || "",
            phoneNumber: selectedLocation.contact.phoneNumber || "",
            vatNumber: selectedLocation.contact.vatNumber || "",
            clientNumber: selectedLocation.contact.clientNumber || "",
            company: selectedLocation.contact.company || "",
            email: selectedLocation.contact.email || "",
            private:
                typeof selectedLocation.contact.private === "boolean"
                    ? selectedLocation.contact.private
                    : null,
        };
        setPickup(customerData);
        setPickupContact(contactData);
        setShowAddressBook(false);

        pickupRef.current.set(customerData);
        pickupContactRef.current.set(contactData);
    }

    function selectContact(contact) {
        onSelect?.(contact);
        addressBookCallbackRef.current(contact);
    }

    return (
        <Container>
            <div className="section">
                {enableAddressBook && (
                    <div className="addressBook">
                        <button
                            className="c-button c-button--ghost"
                            onClick={showContacts}
                        >
                            Välj från adressbok
                        </button>
                    </div>
                )}
                <div className="fields">
                    <form autoComplete="off">
                        <Input
                            ref={customerRef}
                            messages={validationMessages}
                            onChange={setCustomer}
                            type="list"
                            object={{
                                countryCode: {
                                    title: "Land eller territorium",
                                    type: "dropdown",
                                    autoComplete: "chrome-off",
                                    options: countryList,
                                    required: true,
                                },
                                postalCode: {
                                    type: InputPostalCode,
                                    title: "Postnummer",
                                    autoComplete: "chrome-off",
                                    disabled: postalCodeIsDisabled,
                                    countryCode: customer
                                        ? customer.countryCode
                                        : "SE",
                                    onPostalTownSelected: (item) => {
                                        customerRef.current.set({
                                            postalTown: item.value.postalTown,
                                            stateCode: item.value.stateCode,
                                        });
                                        setCustomer({
                                            ...customer,
                                            postalTown: item.value.postalTown,
                                            stateCode: item.value.stateCode,
                                        });
                                    },
                                },
                                postalTown: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Postort",
                                    required: true,
                                },
                                stateCode: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Stat",
                                    hidden: !showStateCode,
                                },
                                addressLine1: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Adress",
                                    maxLength: 30,
                                    required: requireAddress,
                                },
                                addressLine2: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    maxLength: 30,
                                },
                                addressLine3: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    maxLength: 30,
                                },
                                dhlPalletExchangeNumber: {
                                    title: "DHL PÖS-nummer",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    maxLength: 6,
                                    hidden:
                                        !context.user.customer.settings
                                            .dhlPalletExchange ||
                                        !enableDhlPalletExchangeNumber,
                                },
                                otherPickupAddress: {
                                    title: "Annan upphämtningsadress",
                                    type: "checkbox",
                                    hidden: !enableDifferentPickupAddress,
                                },
                            }}
                            value={customer}
                        />
                    </form>
                    <form className="section" autoComplete="off">
                        <Input
                            ref={contactRef}
                            messages={validationMessages}
                            onChange={setContact}
                            type="list"
                            object={{
                                private: {
                                    type: InputMultiToggle,
                                    autoComplete: "chrome-off",
                                    title: "Typ av mottagare",
                                    value: false,
                                    required: true,
                                    options: [
                                        {
                                            title: "Företag",
                                            value: false,
                                        },
                                        {
                                            title: "Privat",
                                            value: true,
                                        },
                                    ],
                                    hidden: !enablePrivateCustomer,
                                },
                                company: {
                                    type: InputCompanyName,
                                    autoComplete: "chrome-off",
                                    title: "Företag",
                                    maxLength: 35,
                                    required: !isPrivate && requireCompanyName,
                                    hidden: isPrivate,
                                    onSelect: selectCustomer,
                                },
                                clientNumber: {
                                    type: InputClientNumber,
                                    autoComplete: "chrome-off",
                                    maxLength: 64,
                                    title: "Kund-id",
                                    required: false,
                                    onSelect: selectCustomer,
                                },
                                name: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    maxLength: 35,
                                    title: "Kontakt",
                                    required: requireContactName,
                                },
                                phoneNumber: {
                                    type: InputPhoneNumber,
                                    autoComplete: "chrome-off",
                                    title: "Telefonnummer",
                                    minLength: 5,
                                    maxLength: 15,
                                    required: requirePhoneNumber,
                                },
                                email: {
                                    type: InputEmail,
                                    autoComplete: "chrome-off",
                                    title: "E-postadress",
                                    required: requireEmail,
                                    messages: {
                                        required:
                                            "Fältet är obligatoriskt för utrikessändningar",
                                        invalid:
                                            "Det ifyllda värdet verkar inte vara en e-postadress",
                                    },
                                },
                                vatNumber: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Tull-id",
                                    required: customsRequired,
                                    helpText:
                                        "Momsnr, EORI-nr, VAT-nr, Tax-nr, etc. Rekommenderas vid transporter utanför EU",
                                    hidden: isPrivate,
                                    maxLength: 25,
                                },
                            }}
                            value={contact}
                        />
                    </form>
                </div>
                <div className="buttons">
                    {!props.disableSaveButton &&
                        showSaveButton &&
                        enableAddressBook && (
                            <button
                                className="c-button c-button--raised"
                                onClick={saveContact}
                            >
                                Spara kontakt
                            </button>
                        )}
                    <button
                        className="c-button c-button--text"
                        onClick={newContact}
                    >
                        Rensa
                    </button>
                </div>
            </div>
            {showDifferentPickupAddress && (
                <>
                    <div className="title">Annan upphämtningsadress</div>
                    <div className="section">
                        <div className="addressBook">
                            <button
                                className="c-button c-button--ghost"
                                onClick={showPickupContacts}
                            >
                                Välj från adressbok
                            </button>
                        </div>
                        <div className="fields differentPickup">
                            <form autoComplete="off">
                                <Input
                                    ref={pickupRef}
                                    type="list"
                                    onChange={setPickup}
                                    value={pickup || {}}
                                    object={{
                                        countryCode: {
                                            title: "Land eller territorium",
                                            type: "dropdown",
                                            autoComplete: "chrome-off",
                                            options: countryList,
                                            value: customer
                                                ? customer.countryCode
                                                : "SE",
                                            disabled: true,
                                        },
                                        postalCode: {
                                            type: InputPostalCode,
                                            autoComplete: "chrome-off",
                                            title: "Postnummer",
                                            countryCode: customer
                                                ? customer.countryCode
                                                : "SE",
                                            onPostalTownSelected: (item) => {
                                                pickupRef.current.set({
                                                    postalTown:
                                                        item.value.postalTown,
                                                    stateCode:
                                                        item.value.stateCode,
                                                });
                                                setPickup({
                                                    ...pickup,
                                                    postalTown:
                                                        item.value.postalTown,
                                                    stateCode:
                                                        item.value.stateCode,
                                                });
                                            },
                                        },
                                        postalTown: {
                                            type: "text",
                                            autoComplete: "chrome-off",
                                            title: "Postort",
                                            required: true,
                                        },
                                        addressLine1: {
                                            type: "text",
                                            autoComplete: "chrome-off",
                                            title: "Adress",
                                            maxLength: 30,
                                            required: true,
                                        },
                                        addressLine2: {
                                            type: "text",
                                            autoComplete: "chrome-off",
                                            maxLength: 30,
                                        },
                                        addressLine3: {
                                            type: "text",
                                            autoComplete: "chrome-off",
                                            maxLength: 30,
                                        },
                                    }}
                                />
                            </form>
                            <form className="section" autoComplete="off">
                                <Input
                                    ref={pickupContactRef}
                                    type="list"
                                    onChange={setPickupContact}
                                    value={
                                        pickupContact
                                            ? pickupContact
                                            : { private: false }
                                    }
                                    object={{
                                        company: {
                                            type: "text",
                                            autoComplete: "chrome-off",
                                            maxLength: 35,
                                            title: "Företag",
                                            required: true,
                                        },
                                        name: {
                                            type: "text",
                                            autoComplete: "chrome-off",
                                            maxLength: 35,
                                            title: "Kontakt",
                                            required: true,
                                        },
                                        phoneNumber: {
                                            type: InputPhoneNumber,
                                            autoComplete: "chrome-off",
                                            title: "Telefonnummer",
                                            maxLength: 15,
                                            required: true,
                                        },
                                        email: {
                                            type: "email",
                                            autoComplete: "chrome-off",
                                            title: "E-postadress",
                                            required: true,
                                        },
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                </>
            )}
            <ContactList
                visible={showAddressBook}
                onSelect={selectContact}
                onClose={() => setShowAddressBook(false)}
            />
        </Container>
    );
}

export default forwardRef(Component);
