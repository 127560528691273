export const DSV_INSURANCE_MULTIPLIER = 1.2;

const minPremiums = { standard: 250, highRisk: 700, vehicles: 1400 };

export const DSV_INSURANCE_CATEGORIES = [
    {
        categoryNumber: 1,
        categoryStr: "STD",
        title: "Nya emballerade handelsvaror",
        minPremium: minPremiums.standard,
        rates: {
            domestic: 0.0026,
            zone1: 0.0042,
            zone2: 0.008,
        },
    },
    {
        categoryNumber: 2,
        categoryStr: "UUG",
        title: "Begagnat samt oemballerat gods",
        minPremium: minPremiums.standard,
        rates: {
            domestic: 0.0026,
            zone1: 0.0042,
            zone2: 0.008,
        },
    },
    {
        categoryNumber: 3,
        categoryStr: "HHD",
        title: "Hög risk (Stöldbegärligt, Ömtåligt, etc..)",
        minPremium: minPremiums.highRisk,
        rates: {
            domestic: 0.011,
            zone1: 0.011,
            zone2: 0.011,
        },
    },
    {
        categoryNumber: 4,
        categoryStr: "FRA",
        title: "Ömtåligt (Glas, Porslin, etc..)",
        minPremium: minPremiums.highRisk,
        rates: {
            domestic: 0.011,
            zone1: 0.011,
            zone2: 0.011,
        },
    },
    {
        categoryNumber: 5,
        categoryStr: "MTR",
        title: "Fordon",
        minPremium: minPremiums.vehicles,
        rates: {
            domestic: 0.011,
            zone1: 0.011,
            zone2: 0.011,
        },
    },
];

/**
 *  Determines the insurance zone based on the sender and recipient countries.
 * @param {string} senderCountryCode
 * @param {string} recipientCountryCode
 * @returns {string}
 */
export function determineInsuranceZone(
    senderCountryCode,
    recipientCountryCode,
) {
    const zone1Countries = [
        "SE",
        "AT",
        "BE",
        "BG",
        "HR",
        "CY",
        "CZ",
        "DK",
        "EE",
        "FI",
        "FR",
        "DE",
        "GR",
        "HU",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SK",
        "SI",
        "ES",
        "NO",
        "CH",
        "IS",
        "LI",
        "UK",
        "US",
        "CA",
        "AU",
        "NZ",
        "AE",
        "BH",
        "IL",
        "JO",
        "KW",
        "OM",
        "QA",
        "SA",
        "TR",
        "CN",
        "JP",
        "KR",
        "SG",
        "TW",
        "TH",
        "VN",
        "MY",
        "ID",
        "HK",
        "RU",
        "IN",
    ];

    // Function to check if a country is in Zone 1
    const isZone1 = (countryCode) => zone1Countries.includes(countryCode);

    // Check if both sender and recipient are in the domestic country
    if (senderCountryCode === recipientCountryCode) {
        return "domestic";
    }

    // Check if both sender and recipient are in Zone 1
    if (isZone1(senderCountryCode) && isZone1(recipientCountryCode)) {
        return "zone1";
    }

    // If either sender or recipient is not in Zone 1, it's considered Zone 2
    return "zone2";
}

/**
 * @param {number} goodsValue - The value of the goods.
 * @param {number} freightCost - The freight cost in SEK.
 * @returns {number} - The calculated insurance value in SEK.
 */
function calculateInsuranceValue(goodsValue, freightCost) {
    return (goodsValue + freightCost) * 1.1; // Adding 10% for trade profit
}

/**
 * Calculates the insurance price for a given insurance value and category.
 *
 * @param {number} goodsValue - The insurance value in SEK.
 * @param {number} freightCost - The freight cost in SEK.
 * @param {number} category - The category of the insurance.
 * @param {string} zone - The zone of the insurance.
 * @returns {number} - The calculated insurance price in SEK.
 */
export function calculateDsvInsurancePrice(
    goodsValue,
    freightCost,
    category,
    zone,
) {
    // Calculate the insurance value

    const insuranceValue = calculateInsuranceValue(goodsValue, freightCost);

    // Determine the correct rate (based on zone & category)
    const insuranceCategory = DSV_INSURANCE_CATEGORIES.find(
        (c) => c.categoryNumber === category,
    );
    const rate = insuranceCategory.rates[zone] || 0;

    let premium = insuranceValue * rate;

    // Special offer for category 1 in zone 1 (250SEK for values up to 100000SEK)
    if (category === 1 && zone === "zone1" && insuranceValue <= 100000) {
        return minPremiums.standard;
    }

    return Math.round(Math.max(premium, insuranceCategory.minPremium)); // Round to nearest whole SEK
}

/**
 *
 * Calculates the maximum insurance value for a given category and zone without exceeding
 * the maximum premium of 3408 SEK (300 EUR).
 * @param {number} category - The insurance category number (1-5).
 * @param {string} zone - The insurance zone ('domestic', 'zone1', or 'zone2').
 * @param {number} freightCost - The cost of freight in SEK.
 * @returns {number} The maximum insurance value in SEK, rounded down to the nearest 1000.
 */
export const calculateMaxInsuranceValue = (category, zone, freightCost) => {
    const MAX_PREMIUM_SEK = 3408; // 300 EUR in SEK
    const insuranceCategory = DSV_INSURANCE_CATEGORIES.find(
        (c) => c.categoryNumber === category,
    );

    const rate = insuranceCategory.rates[zone];
    const minPremium = insuranceCategory.minPremium;

    if (!rate || !minPremium) {
        return 0;
    }

    const maxInsuranceValue = Math.max(
        MAX_PREMIUM_SEK / (1.1 * rate) - freightCost,
        minPremium / (1.1 * rate) - freightCost,
    );

    // Round down to the nearest 1000 SEK
    return Math.floor(maxInsuranceValue / 1000) * 1000;
};

/**
 * Maps a category number to a text value.
 * @param {number} category - The category number.
 * @returns {string} - The category description or undefined if not found.
 */
export const mapCategoryNumberToText = (category) => {
    return DSV_INSURANCE_CATEGORIES.find(
        (insurance) => insurance.categoryNumber === category,
    )?.categoryStr;
};

/**
 * Maps a category text to a number value.
 * @param {string} categoryText - The category text.
 * @returns {number} - The category number or undefined if not found.
 */
export const mapCategoryTextToNumber = (categoryText) => {
    return DSV_INSURANCE_CATEGORIES.find(
        (insurance) => insurance.categoryStr === categoryText,
    )?.categoryNumber;
};
