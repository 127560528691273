import Loader from "../../../components/Loader/Loader";

const DeliveryCodeSelector = ({
    deliveryCodes,
    selectedDeliveryCodes,
    fetchingDeliveryCodes,
    onDeliveryCodeChange,
    onDeliveryCodeRemove,
}) => {
    return (
        <div className="flex flex-col">
            <label htmlFor="deliveryCodes" className="block font-medium">
                Välj vilka leveranskoder ni vill filtrera på.
            </label>
            {fetchingDeliveryCodes ? (
                <Loader />
            ) : (
                <select
                    className="w-48 p-2 h-10 border border-gray-300 rounded-md"
                    onChange={(e) => onDeliveryCodeChange(e.target.value)}
                    name="deliveryCodes"
                    defaultValue=""
                >
                    <option value="" disabled>
                        Välj alternativ
                    </option>
                    {deliveryCodes.map((deliveryCode) => (
                        <option
                            key={deliveryCode.code}
                            value={deliveryCode.code}
                            disabled={selectedDeliveryCodes.includes(
                                deliveryCode.code,
                            )}
                            className={
                                selectedDeliveryCodes.includes(
                                    deliveryCode.code,
                                )
                                    ? "text-gray-400"
                                    : "text-black"
                            }
                        >
                            {selectedDeliveryCodes.includes(deliveryCode.code)
                                ? `${deliveryCode.code} - ${deliveryCode.displayName} (Vald)`
                                : `${deliveryCode.code} - ${deliveryCode.displayName}`}
                        </option>
                    ))}
                </select>
            )}

            {selectedDeliveryCodes.length > 0 && (
                <div className="mt-4">
                    <label>Valda Leveranskoder:</label>
                    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
                        {selectedDeliveryCodes.map((code) => (
                            <li
                                key={code}
                                className="flex items-center justify-between border border-gray-300 rounded-md p-2 bg-gray-100"
                            >
                                <span>{code}</span>
                                <button
                                    className="c-button c-button--outline-red ml-2 px-4"
                                    onClick={() => onDeliveryCodeRemove(code)}
                                >
                                    Ta bort
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DeliveryCodeSelector;
