import DisplayField from "../../../Components/DisplayField";
import HelpTextIcon from "../../../Components/InformationIcon";

function QuickButikStepTwo({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Skapa Webhook
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Markera kryssrutan "Aktivera Webhook".</li>
                        <li>Ange en titel för webhooken.</li>
                        <div className="flex flex-col">
                            <li>
                                Kopiera och klistra in följande URL i fältet
                                "Länkadresser":
                            </li>
                            <DisplayField
                                value={`${process.env.REACT_APP_API_URL}/api/wh/9cOiJGxs07di61420qkdJvWO21K1GSI821HA3xxu61035cEd4Da8Z0emWRi6buE`}
                                copyToClipboard={true}
                            />
                        </div>
                        <li>Markera kryssrutan "Använd hemlig nyckel".</li>
                        <div className="flex items-start">
                            <li>
                                Välj vilket "Event" som ska trigga överföringen
                                av orderdata.
                            </li>
                            <HelpTextIcon
                                infoText={
                                    "Exempelvis:<br/>'Order.new' skickar ordern direkt vid en ny beställning.<br/>'Order.done' skickar ordern när den har markerats som skickad."
                                }
                            />
                        </div>
                        <li>
                            Tryck på "Spara ändringar" så kommer din hemlighet
                            att visas.
                        </li>
                        <div className="flex items-start pt-56">
                            <li>
                                Kopiera värdet av din hemlighet och klistra in
                                den i fältet nedan.
                            </li>
                            <HelpTextIcon infoText="Hemligheten visas direkt efter att du har tryckt 'Spara ändringar'. Värdet är markerat i rött på bild 2." />
                        </div>

                        <div>
                            <label
                                htmlFor="identifier"
                                className="block font-medium"
                            >
                                Hemlighet
                            </label>
                            <input
                                type="text"
                                name="identifier"
                                value={configuration.identifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/quickbutik/webhook.png`}
                        alt="Webhook inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-md"
                    />
                    <img
                        src={`/images/extensions/quickbutik/secret.png`}
                        alt="Webhook inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default QuickButikStepTwo;
