import HelpTextIcon from "../../../Components/InformationIcon";
import React from "react";

function MonitorG5StepThree({
    configuration,
    handleChange,
    handleCheckboxChange,
}) {
    const fetchPeriods = [
        { value: "1day", title: "1 dag" },
        { value: "1week", title: "1 vecka" },
        { value: "2weeks", title: "2 veckor" },
        { value: "3weeks", title: "3 veckor" },
        { value: "1month", title: "1 månad" },
    ];

    const triggerStatus = [
        { value: "1", title: "1. Registrerad" },
        { value: "2", title: "2. Utskriven" },
        { value: "3", title: "3. Leveranstid kan ej bekräftas" },
        { value: "4", title: "4. Pågående plockning" },
        { value: "5", title: "5. Dellevererad" },
        { value: "9", title: "9. Slutlevererad" },
    ];

    const referenceOptions = [
        { value: "orderNumber", label: "Ordernummer" },
        { value: "customerOrderNumber", label: "Kundens Ordernummer" },
        { value: "goodsReferenceName", label: "Godsmott. ref." },
    ];

    const goodsDescriptionOptions = [
        { value: "orderType", label: "Ordertyp" },
        { value: "goodsLabel", label: "Godsmärke" },
        { value: "customText", label: "Egen Godsbeskrivning" },
    ];

    const isCustomTextSelected =
        configuration.goodsDescription?.includes("customText");

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Konfigurera Integrationen
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-full">
                    <label className="block font-medium">
                        Fyll i information som styr hur och när dina ordrar ska
                        hämtas från Monitor G5
                    </label>
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="initialFetchPeriod"
                                    className="block font-medium"
                                >
                                    Från vilken tidpunkt ska ordrar hämtas
                                </label>
                                <HelpTextIcon infoText="Välj hur långt bak i tiden du vill hämta ordrar ifrån Monitor G5" />
                            </div>
                            <select
                                name="initialFetchPeriod"
                                value={configuration.initialFetchPeriod}
                                onChange={handleChange}
                                className="w-96 p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                defaultValue=""
                                required
                            >
                                <option value="" disabled>
                                    Välj ett alternativ
                                </option>
                                {fetchPeriods.map((period) => (
                                    <option
                                        key={period.value}
                                        value={period.value}
                                    >
                                        {period.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="transferTriggerStatus"
                                    className="block font-medium"
                                >
                                    Status för överföring
                                </label>
                                <HelpTextIcon infoText="När en orderstatus ändras i Monitor G5 till den valda statusen nedan kommer den föras över till Zendr's portal" />
                            </div>
                            <select
                                name="transferTriggerStatus"
                                value={configuration.transferTriggerStatus}
                                onChange={handleChange}
                                className="w-96 p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                defaultValue=""
                                required
                            >
                                <option value="" disabled>
                                    Välj ett alternativ
                                </option>
                                {triggerStatus.map((status) => (
                                    <option
                                        key={status.value}
                                        value={status.value}
                                    >
                                        {status.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <div className="flex items-start mb-2">
                                <label className="block font-medium">
                                    Välj värde för Referens
                                </label>
                                <HelpTextIcon infoText="Välj ett eller flera alternativ för vilken referens ni önskar hämta från ordern i Monitor G5.<br/> Exempelvis: 123 - 321 - 444" />
                            </div>
                            <div className="flex flex-col gap-2">
                                {referenceOptions.map((option) => (
                                    <label
                                        key={option.value}
                                        className="flex items-center space-x-2 font-medium ml-2"
                                    >
                                        <input
                                            type="checkbox"
                                            name="reference"
                                            value={option.value}
                                            checked={configuration.reference?.includes(
                                                option.value,
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(
                                                    e,
                                                    "reference",
                                                )
                                            }
                                            className="form-checkbox w-5 h-5"
                                            style={{
                                                accentColor:
                                                    "rgba(76, 80, 175, 1)",
                                            }}
                                        />
                                        <span>{option.label}</span>
                                    </label>
                                ))}
                            </div>
                        </div>

                        <div>
                            <div className="flex items-start mb-2">
                                <label className="block font-medium">
                                    Välj värde för Godsbeskrivning
                                </label>
                                <HelpTextIcon infoText="Välj ett eller flera alternativ för vilken godsbeskrivning ni önskar hämta från ordern i Monitor G5. Eller lägg till en egen godsbeskrivning som alltid kommer anges på samtliga ordrar<br/> Exempelvis: ordertyp - godsmärke" />
                            </div>
                            <div className="flex flex-col gap-2">
                                {goodsDescriptionOptions.map((option) => (
                                    <label
                                        key={option.value}
                                        className="flex items-center space-x-2 font-medium ml-2"
                                    >
                                        <input
                                            type="checkbox"
                                            name="goodsDescription"
                                            value={option.value}
                                            checked={configuration.goodsDescription?.includes(
                                                option.value,
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(
                                                    e,
                                                    "goodsDescription",
                                                )
                                            }
                                            disabled={
                                                isCustomTextSelected &&
                                                option.value !== "customText"
                                            }
                                            className="form-checkbox w-5 h-5"
                                            style={{
                                                accentColor:
                                                    "rgba(76, 80, 175, 1)",
                                            }}
                                        />
                                        <span>{option.label}</span>
                                    </label>
                                ))}
                            </div>
                            {isCustomTextSelected && (
                                <input
                                    type="text"
                                    name="customGoodsDescription"
                                    value={
                                        configuration.customGoodsDescription ||
                                        ""
                                    }
                                    onChange={handleChange}
                                    placeholder="Ange egen beskrivning"
                                    className="w-96 mt-4 p-2 h-10 border border-gray-300 rounded-md"
                                />
                            )}
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MonitorG5StepThree;
