import moment from "moment";

export const calculateFetchTimestamp = (period) => {
    const now = moment();
    const periodMapping = {
        "1day": { length: 1, periodString: "days" },
        "1week": { length: 1, periodString: "weeks" },
        "2weeks": { length: 2, periodString: "weeks" },
        "3weeks": { length: 3, periodString: "weeks" },
        "1month": { length: 1, periodString: "months" },
    };

    const { length, periodString } = periodMapping[period] || {};
    if (length && periodString) {
        now.subtract(length, periodString);
    }

    return now.toISOString();
};
