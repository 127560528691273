import {
    EXPIRED,
    EXPIRE_SOON,
} from "../constants/integrations/IntegrationStatuses";

/**
 * Get single integration status by ID
 * This is supposed to be used on single view of integrations where we
 * are goind to find integrations by ID from expiring integrations
 * @param expiryIntegrations
 * @param integrationId
 * @returns {Promise<AxiosResponse<*>>}
 */
export const getIntegrationExpiryStatusById = (
    expiryIntegrations,
    integrationId,
) => {
    return expiryIntegrations?.find(
        ({ integration }) => integration.id === integrationId,
    );
};

/**
 * If any integration have status expired return true
 * @returns {boolean}
 * @param expiryIntegrations
 */
export const hasExpiredIntegration = (expiryIntegrations) => {
    return expiryIntegrations.some((item) => item.status === EXPIRED);
};

/**
 * If any integration have status expire soon return true
 * @param expiryIntegrations
 * @returns {boolean}
 */
export const hasExpireSoonIntegration = (expiryIntegrations) => {
    return expiryIntegrations.some((item) => item.status === EXPIRE_SOON);
};

/**
 * This method is basically going to sort expire soon integrations
 * and return only one integrations that is closest to expiration days
 * @param expiryIntegrations
 * @returns {*|*[]}
 */
export const getClosestExpireSoonIntegration = (expiryIntegrations) => {
    const onlyExpiredSoonIntegrations = expiryIntegrations?.filter(
        (item) => item.status === EXPIRE_SOON,
    );
    // sort expire integrations from closest to furthest expirations
    onlyExpiredSoonIntegrations.sort(
        (a, b) => a.daysUntilExpire - b.daysUntilExpire,
    );

    return !!onlyExpiredSoonIntegrations.length
        ? onlyExpiredSoonIntegrations[0]
        : [];
};
