import DisplayField from "../../../Components/DisplayField";
import HelpTextIcon from "../../../Components/InformationIcon";

function ShopifyStepTwo({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Konfigurera Webhook
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>
                            Välj ett event i Shopify som ska trigga webhooken,
                            exempelvis när en orderstatus ändras till "Order
                            Payment".
                        </li>
                        <li>Välj "Format" som JSON.</li>

                        <div className="flex flex-col">
                            <li>
                                Kopiera och klistra in följande URL i fältet
                                "URL":
                            </li>
                            <DisplayField
                                value={`${process.env.REACT_APP_API_URL}/api/wh/Ti1I3J1140QYp2kYnsAccY2g9cnfo4EGw8OWLi9hG2801q4G6FItK9koPFPY24WP`}
                                copyToClipboard={true}
                            />
                        </div>

                        <li>Välj den senaste versionen av Webhook API.</li>

                        <div className="flex items-start pt-20">
                            <li>
                                Kopiera bas-URL:en för din Shopify-butik och
                                klistra in den i fältet nedan.
                            </li>
                            <HelpTextIcon infoText="Bas-URL:en finns i inställningarna och är markerad i rött på bild 2." />
                        </div>

                        <div>
                            <label
                                htmlFor="identifier"
                                className="block font-medium"
                            >
                                Bas-URL
                            </label>
                            <input
                                type="text"
                                name="identifier"
                                value={configuration.identifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/shopify/webhook.png`}
                        alt="Webhook inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-2xl"
                    />
                    <img
                        src={`/images/extensions/shopify/baseurl.png`}
                        alt="Bas-URL"
                        className="object-contain w-[32rem] h-[32rem] max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default ShopifyStepTwo;
