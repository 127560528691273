import { useEffect, useState } from "react";
import { clearLoginStorage } from "../authConfig";
import toast from "react-hot-toast";
import DeliveryCodeSelector from "../../../Components/DeliveryCodeSelector";
import HelpTextIcon from "../../../Components/InformationIcon";
import Loader from "../../../../../components/Loader/Loader";
import Api from "../../../../../services/Api/Api";

function BusinessCentralStepFour({
    configuration,
    setConfiguration,
    loading,
    setLoading,
    history,
}) {
    const [companies, setCompanies] = useState([]);
    const [deliveryCodes, setDeliveryCodes] = useState([]);
    const [selectedDeliveryCodes, setSelectedDeliveryCodes] = useState([]);

    const handleCompanyChange = (e) => {
        const selectedCompanyId = e.target.value;
        const selectedCompany = companies.find(
            (company) => company.id === selectedCompanyId,
        );

        if (selectedCompany) {
            setConfiguration((prevConf) => ({
                ...prevConf,
                companyId: selectedCompany.id,
                companyName: selectedCompany.name,
            }));
        }
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            setLoading(true);
            if (configuration.accessToken) {
                try {
                    const companiesData = await Api.getCompanies(configuration);
                    setCompanies(companiesData);
                } catch (error) {
                    clearLoginStorage();
                    toast.error(
                        "Misslyckades med att hämta företag, försök igen!",
                    );
                    history.push("/extensions/create");
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchCompanies();
    }, [configuration.accessToken]);

    useEffect(() => {
        const fetchDeliveryCodes = async () => {
            setLoading(true);
            if (configuration.companyId) {
                try {
                    const codes = await Api.getDeliveryCodes(configuration);
                    setDeliveryCodes(codes);
                } catch (error) {
                    clearLoginStorage();
                    toast.error(
                        "Misslyckades med att hämta leveranskoder, försök igen!",
                    );
                    history.push("/extensions/create");
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchDeliveryCodes();
    }, [configuration.companyId]);

    const handleDeliveryCodesChange = (code) => {
        if (code && !selectedDeliveryCodes.includes(code)) {
            setSelectedDeliveryCodes((prevCodes) => [...prevCodes, code]);
            setConfiguration((prevConf) => ({
                ...prevConf,
                deliveryCodes: [...prevConf.deliveryCodes, code],
            }));
        }
    };

    const handleRemoveDeliveryCode = (codeToRemove) => {
        setSelectedDeliveryCodes((prevCodes) =>
            prevCodes.filter((code) => code !== codeToRemove),
        );
        setConfiguration((prevConf) => ({
            ...prevConf,
            deliveryCodes: prevConf.deliveryCodes.filter(
                (code) => code !== codeToRemove,
            ),
        }));
    };

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 4 -{" "}
                <span className="text-lg font-medium text-accent">
                    Anslut och konfigurera Business Central
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Efter lyckad anslutning till Microsoft Azure så
                            kommer era Business Central företag att listas
                            nedan.
                        </li>
                        {loading ? (
                            <Loader />
                        ) : companies.length > 0 ? (
                            <>
                                <label
                                    htmlFor="companyId"
                                    className="block font-medium"
                                >
                                    Välj vilket företag i listan du önskar att
                                    integrera med.
                                </label>
                                <select
                                    name="companyId"
                                    value={configuration.companyId}
                                    onChange={handleCompanyChange}
                                    className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                    required
                                >
                                    <option value="" disabled hidden>
                                        Välj ett företag
                                    </option>
                                    {companies.map((company) => (
                                        <option
                                            key={company.id}
                                            value={company.id}
                                        >
                                            {company.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        ) : (
                            <p>Inga företag hittades.</p>
                        )}

                        {deliveryCodes.length > 0 && (
                            <>
                                <div className="flex items-start">
                                    <li>
                                        Ni har nu möjlighet att välja vilka
                                        leveranskoder från Business Central ni
                                        önskar använda som filter för vilka
                                        ordrar som ska hämtas.
                                    </li>
                                    <HelpTextIcon infoText="Dessa koder lägger man till på ordern under fliken 'Levrans och fakturering' i Business Central. Om ni inte väljer någon leveranskod kommer alla ordrar att hämtas in." />
                                </div>
                                <DeliveryCodeSelector
                                    deliveryCodes={deliveryCodes}
                                    selectedDeliveryCodes={
                                        selectedDeliveryCodes
                                    }
                                    fetchingDeliveryCodes={loading}
                                    onDeliveryCodeChange={
                                        handleDeliveryCodesChange
                                    }
                                    onDeliveryCodeRemove={
                                        handleRemoveDeliveryCode
                                    }
                                />
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default BusinessCentralStepFour;
