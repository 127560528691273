import React, { useContext, useState } from "react";
import Integrations from "../../../../constants/integrations";
import ExtensionCard from "../../Components/ExtensionCard";
import StepNavigation from "../../Components/StepNavigation";
import Api from "../../../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../../../context/Global";
import MonitorG5StepOne from "./Steps/MonitorG5StepOne";
import MonitorG5StepTwo from "./Steps/MonitorG5StepTwo";
import MonitorG5StepThree from "./Steps/MonitorG5StepThree";
import { calculateFetchTimestamp } from "../../../../utils/fetchDateUtil";
import MonitorG5StepFour from "./Steps/MonitorG5StepFour";

function CreateMonitorG5Extension(props) {
    const context = useContext(Context);
    const [currentStep, setCurrentStep] = useState(1);
    const [solutionType, setSolutionType] = useState("");
    const [prerequisites, setPrerequisites] = useState(false);
    const [successfulLogin, setSuccessfulLogin] = useState(false);
    const [loading, setLoading] = useState(false);

    const [configuration, setConfiguration] = useState({
        username: "",
        password: "",
        hostname: "",
        port: "",
        languageCode: "",
        companyNumber: "",
        transferTriggerStatus: "",
        reference: [],
        goodsDescription: [],
        customGoodsDescription: "",
        initialFetchPeriod: "",
    });

    const handleLoginMonitor = async () => {
        try {
            setLoading(true);

            const response = await Api.monitorLoginRequest(configuration);
            if (response) {
                setSuccessfulLogin(true);
                toast.success(
                    "Lyckades med försök att logga in till Monitor G5!",
                );
            }
        } catch (error) {
            toast.error(
                "Misslyckades med att logga in till Monitor G5, kontrollera dina uppgifter och försök igen!",
            );
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguration((prevConf) => ({
            ...prevConf,
            [name]: value,
        }));
    };

    function handleCheckboxChange(event, field) {
        const { value, checked } = event.target;

        setConfiguration((prevConf) => {
            let updatedValues;

            if (field === "goodsDescription" && value === "customText") {
                updatedValues = checked
                    ? ["customText"]
                    : prevConf[field].filter((item) => item !== "customText");
            } else if (
                field === "goodsDescription" &&
                prevConf.goodsDescription.includes("customText")
            ) {
                updatedValues = prevConf.goodsDescription;
            } else {
                updatedValues = checked
                    ? [...prevConf[field], value]
                    : prevConf[field].filter((item) => item !== value);
            }

            return {
                ...prevConf,
                [field]: updatedValues,
            };
        });
    }

    const handleSubmit = async () => {
        try {
            const lastFetchedOrderTimestamp = calculateFetchTimestamp(
                configuration.initialFetchPeriod,
            );

            const data = {
                type: "MonitorG5",
                config: {
                    ...configuration,
                },
                lastFetchedOrderTimestamp,
            };

            const response = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data,
            });

            props.history.push(`/extensions/${response.id}`);
        } catch (error) {
            toast.error(
                "Misslyckades med att visa den nya integrationen, gå tillbaka till Tillägg fliken och försök igen!",
            );
        }
    };

    const steps = [
        {
            component: (
                <MonitorG5StepOne
                    prerequisites={prerequisites}
                    setPrerequisites={setPrerequisites}
                    solutionType={solutionType}
                    setSolutionType={setSolutionType}
                />
            ),
            isValid: () => prerequisites === true,
        },
        {
            component: (
                <MonitorG5StepTwo
                    configuration={configuration}
                    handleChange={handleChange}
                    handleLoginMonitor={handleLoginMonitor}
                    loading={loading}
                    successfulLogin={successfulLogin}
                />
            ),
            isValid: () =>
                configuration.hostname.trim() !== "" &&
                configuration.port.trim() !== "" &&
                configuration.languageCode.trim() !== "" &&
                configuration.companyNumber.trim() !== "" &&
                successfulLogin,
        },
        {
            component: (
                <MonitorG5StepThree
                    configuration={configuration}
                    handleChange={handleChange}
                    handleCheckboxChange={handleCheckboxChange}
                />
            ),
            isValid: () =>
                configuration.initialFetchPeriod.trim() !== "" &&
                configuration.transferTriggerStatus.trim() !== "" &&
                configuration.reference.length > 0 &&
                configuration.goodsDescription.length > 0,
        },
        {
            component: <MonitorG5StepFour configuration={configuration} />,
            isValid: () => true,
        },
    ];

    const currentStepComponent = steps[currentStep - 1].component;

    return (
        <div className="min-h-full flex flex-col justify-between ml-20 pt-10">
            <div>
                <ExtensionCard extension={{ type: Integrations.MONITOR_G5 }}>
                    <h1 className="text-2xl font-bold">Lägg till Monitor G5</h1>
                    <p className="text-base font-medium mt-2">
                        För att kunna aktivera denna integration behöver du
                        följa stegen som beskrivs nedan.
                    </p>
                </ExtensionCard>
            </div>

            <div className="flex-grow flex">
                <div className="max-w w-full pt-10">{currentStepComponent}</div>
            </div>

            <div className="pb-12">
                <StepNavigation
                    currentStep={currentStep}
                    totalSteps={steps.length}
                    onStepChanged={setCurrentStep}
                    isCurrentStepValid={steps[currentStep - 1].isValid()}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default CreateMonitorG5Extension;
