import toast from "react-hot-toast";

const DisplayField = ({ label, value, copyToClipboard, error }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(value);
        toast.success("Kopierad till urklipp!");
    };

    return (
        <div className="mt-2 w-full">
            {label && <label className="font-medium">{label}</label>}
            <div className="p-2 mb-2">
                <div className="flex items-center w-full">
                    <div className="flex-grow p-1 bg-gray-100 rounded-sm text-gray-900 break-all">
                        {value}
                    </div>
                    {copyToClipboard && (
                        <button
                            type="button"
                            onClick={handleCopy}
                            className="ml-4 c-button--raised rounded-sm px-2 py-1 text-sm transition duration-300 ease-in-out"
                        >
                            Kopiera
                        </button>
                    )}
                </div>
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </div>
    );
};

export default DisplayField;
