import DisplayField from "../../../Components/DisplayField";
import HelpTextIcon from "../../../Components/InformationIcon";

function VismaAdminStepFour({ configuration, activateExtension }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 4 -{" "}
                <span className="text-lg font-medium text-accent">
                    {!configuration.identifier
                        ? "Aktivera integration"
                        : "Konfigurera integration"}
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col text-md font-medium space-y-8 w-1/2">
                    <ul className="list-disc list-outside pl-6 space-y-4">
                        {!configuration.identifier ? (
                            <>
                                <li>
                                    För att få tillgång till en
                                    integrationsnyckel och slutföra skapandet av
                                    integrationen krävs det att du aktiverar den
                                    nedan.
                                </li>
                                <button
                                    className="c-button c-button--raised mt-4 w-60"
                                    onClick={activateExtension}
                                >
                                    Aktivera Integration
                                </button>
                            </>
                        ) : (
                            <>
                                <li>
                                    Kopiera och klistra in API-nyckeln nedan i
                                    fältet "Integrationsnyckel".
                                </li>
                                <DisplayField
                                    value={configuration.identifier}
                                    copyToClipboard={true}
                                />
                                <li>
                                    Välj om du vill att Zendr Applikationen ska
                                    starta när Windows startar.
                                </li>
                                <li>
                                    Välj om du vill få en notis varje gång en
                                    order skickas över till portalen.
                                </li>
                                <div className="flex items-start">
                                    <li>
                                        Välj hur ofta du önskar att Zendr
                                        Applikationen kollar efter nya ordrar.
                                    </li>
                                    <HelpTextIcon
                                        infoText={
                                            "Detta anges i sekunder.<br/>Exempelvis: 30"
                                        }
                                    />
                                </div>
                                <li>
                                    Klicka sedan "Spara alla uppgifter" i
                                    applikationen och "Slutför" nedan.
                                </li>
                            </>
                        )}
                    </ul>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={"/images/extensions/visma/application.png"}
                        alt="Visma Inställningar"
                        className="w-[32rem] h-[32rem] object-contain max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default VismaAdminStepFour;
