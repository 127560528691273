import {
    clearLoginStorage,
    getRefreshToken,
    loginRequest,
} from "../Create/BusinessCentral/authConfig";
import IntegrationsTokenLifespans from "../../../constants/integrations/IntegrationsTokenLifespans";
import Api from "../../../services/Api/Api";
import toast from "react-hot-toast";
import { useMsal } from "@azure/msal-react";

export const useBusinessCentralAuthorization = () => {
    const { instance } = useMsal();

    const handleLoginMicrosoft = async () => {
        try {
            const login = await instance.loginPopup(loginRequest);
            if (login?.accessToken) {
                return login;
            }
        } catch (error) {
            throw error;
        }
    };

    const renewTokens = async (extension) => {
        try {
            const login = await instance.loginPopup(loginRequest);
            if (login?.accessToken) {
                const updateData = {
                    ...extension.data,
                    accessToken: login.accessToken,
                    refreshToken: getRefreshToken().secret,
                    refreshTokenExpiration:
                        IntegrationsTokenLifespans.BUSINESS_CENTRAL_REFRESH_TOKEN_LIFESPAN,
                };
                await Api.updateIntegration({
                    integrationId: extension.id,
                    data: updateData,
                });
            }
        } catch (error) {
            toast.error("Misslyckades med att förnya din integration");
        }
    };

    return { renewTokens, handleLoginMicrosoft };
};
