function QuickButikStepFour({ configuration }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 4 -{" "}
                <span className="text-lg font-medium text-accent">
                    Bekräfta och Slutför
                </span>
            </h3>

            <div className="flex flex-row justify-between items-start space-x-8">
                <div className="w-3/5 flex flex-col space-y-8">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Granska dina inställningar nedan. Om allt ser
                            korrekt ut, klicka på "Slutför" för att aktivera
                            integrationen.
                        </li>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <label className="block font-medium">
                                Quickbutik Webhook Hemlighet
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.identifier}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Quickbutik API-nyckel
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.apiKey}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuickButikStepFour;
