import DisplayField from "../../../Components/DisplayField";
import HelpTextIcon from "../../../Components/InformationIcon";

function StarwebStepTwo() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Skapa Webhook
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Klicka på "Lägg till ny webhook".</li>
                        <li>Ange ett namn för din webhook.</li>
                        <div className="flex items-start">
                            <li>
                                Välj ett "Event" som ska trigga överföringen av
                                orderdata.
                            </li>
                            <HelpTextIcon
                                infoText={
                                    "Exempel:<br/>'Order Skapad' skickar ordern direkt vid ny beställning.<br/>'Order Slutförd' skickar ordern när den är markerad som slutförd."
                                }
                            />
                        </div>
                        <div className="flex flex-col">
                            <li>
                                Kopiera och klistra in följande URL i fältet
                                "URL":
                            </li>
                            <DisplayField
                                value={`${process.env.REACT_APP_API_URL}/api/wh/R9qSyZTouIg29lOpTF0s03xHqP77QTLRCa76wxm4eJxxDXmfwlXZQ2t5DbVjruoW`}
                                copyToClipboard={true}
                            />
                        </div>
                        <li>
                            Klicka på "Spara" och fortsätt till nästa steg
                            nedan.
                        </li>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/starweb/webhook.png`}
                        alt="Inställningar för webhook"
                        className="object-contain w-[32rem] h-[32rem] max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default StarwebStepTwo;
