import HelpTextIcon from "../../../Components/InformationIcon";
import { countryList } from "../../../../../components/Company/ShipmentContactForm/countryList";

function MonitorG5StepTwo({
    configuration,
    handleChange,
    handleLoginMonitor,
    loading,
    successfulLogin,
}) {
    const isConfigurationValid =
        configuration.username.trim() !== "" &&
        configuration.password.trim() !== "" &&
        configuration.hostname.trim() !== "" &&
        configuration.port.trim() !== "" &&
        configuration.languageCode.trim() !== "" &&
        configuration.companyNumber.trim() !== "";

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Anslutning
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-full">
                    <label className="block font-medium">
                        Vänligen fyll i konfiguration som är specifik för er
                        Monitoruppsättning.
                    </label>
                    <ul className="flex text-md font-medium list-disc list-outside">
                        <div className="w-96 space-y-4">
                            <div>
                                <div className="flex items-start">
                                    <label
                                        htmlFor="username"
                                        className="block font-medium"
                                    >
                                        API Användarnamn
                                    </label>
                                    <HelpTextIcon infoText="Användarnamn för den API-användare som skapades i steg 1" />
                                </div>
                                <input
                                    type="text"
                                    name="username"
                                    value={configuration.username}
                                    onChange={handleChange}
                                    className="w-full p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <div>
                                <div className="flex items-start">
                                    <label
                                        htmlFor="password"
                                        className="block font-medium"
                                    >
                                        API Lösenord
                                    </label>
                                    <HelpTextIcon infoText="Lösenord för den API-användare som skapades i steg 1" />
                                </div>
                                <input
                                    type="password"
                                    name="password"
                                    value={configuration.password}
                                    onChange={handleChange}
                                    className="w-full p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                        </div>

                        <div className="w-96 space-y-4 ml-8">
                            <div>
                                <div className="flex items-start">
                                    <label
                                        htmlFor="hostname"
                                        className="block font-medium"
                                    >
                                        Värdnamn (bas-url)
                                    </label>
                                    <HelpTextIcon infoText="Exempelvis: https://företagsnamn.monitorcloud.se" />
                                </div>
                                <input
                                    type="text"
                                    name="hostname"
                                    value={configuration.hostname}
                                    onChange={handleChange}
                                    className="w-full p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <div>
                                <div className="flex items-start">
                                    <label
                                        htmlFor="port"
                                        className="block font-medium"
                                    >
                                        Port
                                    </label>
                                    <HelpTextIcon infoText="Exempelvis: 8001" />
                                </div>
                                <input
                                    type="text"
                                    name="port"
                                    value={configuration.port}
                                    onChange={handleChange}
                                    className="w-full p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <div>
                                <div className="flex items-start">
                                    <label
                                        htmlFor="languageCode"
                                        className="block font-medium"
                                    >
                                        Språkkod
                                    </label>
                                    <HelpTextIcon infoText="Valt språk för ert Monitor G5.<br/> Exempelvis: se, en" />
                                </div>
                                <select
                                    name="languageCode"
                                    value={configuration.languageCode}
                                    onChange={handleChange}
                                    className="w-full p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                    required
                                >
                                    <option value="" disabled>
                                        Välj en språkkod
                                    </option>
                                    {countryList
                                        .filter(
                                            (country) =>
                                                country.value.length === 2,
                                        )
                                        .map((country, index) => (
                                            <option
                                                key={`${country.value}-${index}`}
                                                value={country.value}
                                            >
                                                {country.title} ({country.value}
                                                )
                                            </option>
                                        ))}
                                </select>
                            </div>

                            <div>
                                <div className="flex items-start">
                                    <label
                                        htmlFor="companyNumber"
                                        className="block font-medium"
                                    >
                                        Företagsnummer
                                    </label>
                                    <HelpTextIcon infoText="Ange databasnummer.företagsnummer som ni önskar integrera emot?<br/> Exempelvis: 004.1" />
                                </div>
                                <input
                                    type="text"
                                    name="companyNumber"
                                    value={configuration.companyNumber}
                                    onChange={handleChange}
                                    className="w-full p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                        </div>
                    </ul>
                    <div className="pt-8">
                        {successfulLogin ? (
                            <p className="text-green-500">
                                Inloggning lyckades! Gå vidare till nästa steg
                                nedan.
                            </p>
                        ) : (
                            <div className="flex flex-col space-y-4">
                                <label className="text-md font-medium">
                                    Testa anslutning genom att klicka på knappen
                                    nedan.
                                </label>
                                <button
                                    className="c-button c-button--raised w-60"
                                    onClick={handleLoginMonitor}
                                    disabled={loading || !isConfigurationValid}
                                >
                                    Logga in
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MonitorG5StepTwo;
