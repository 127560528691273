import {
    BringImportExport,
    DHLExport,
    DHLImport,
    DSVExportUK,
    FlightExport,
    FlightImport,
    UPSExport,
    UPSImport,
} from "./termsList";

export function getCustomsTermsOptions({
    carrier,
    fromCountryCode,
    toCountryCode,
}) {
    const GB_COUNTRY_CODES = ["GB-EN", "GB", "GB-SC", "GB-WA", "GB-NI"];

    if (GB_COUNTRY_CODES.includes(toCountryCode) && carrier === "DSV") {
        return DSVExportUK;
    }

    if (toCountryCode === "SE" && fromCountryCode !== "SE") {
        switch (carrier) {
            case "BRING":
                return BringImportExport;
            case "DHL":
                return DHLImport;
            case "UPS":
                return UPSImport;
            default:
                return FlightImport;
        }
    } else {
        switch (carrier) {
            case "BRING":
                return BringImportExport;
            case "DHL":
                return DHLExport;
            case "UPS":
                return UPSExport;
            default:
                return FlightExport;
        }
    }
}
