import DisplayField from "../../../Components/DisplayField";

function BusinessCentralStepOne() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Föreberedelser i Azure
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>Logga in på ert Microsoft Azure-konto.</li>
                        <li>
                            Navigera till "Appregistrering" under
                            Azure-tjänster.
                        </li>
                        <li>
                            Klicka på "Ny registrering" för att skapa en ny
                            appregistrering.
                        </li>
                        <li>Välj ett beskrivande namn för appen.</li>
                        <li>
                            Under "Kontotyper som stöds", välj alternativet
                            "Konton i valfri organisationskatalog".
                        </li>
                        <li>
                            Lägg till en Omdirigerings-URI genom att välja
                            "Ensidesapplikation (SPA)" och använd följande URI:
                            <DisplayField
                                value={`${process.env.REACT_APP_BUSINESS_CENTRAL_REDIRECT_URL}`}
                                copyToClipboard={true}
                            />
                        </li>
                        <li>
                            Klicka på "Registrera" och gå vidare till nästa steg
                            nedan.
                        </li>
                    </ul>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={`/images/extensions/businesscentral/appregistration.png`}
                        alt="Step one"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default BusinessCentralStepOne;
