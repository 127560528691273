import HelpTextIcon from "../../../Components/InformationIcon";

function OnGoingWmsStepOne({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Skapa API Användare
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>Logga in på OnGoing WMS.</li>
                        <li>
                            Tryck på "Administration" i den övre huvudmenyn.
                        </li>
                        <div className="flex items-start">
                            <li>Välj "API för godsägare/automation".</li>
                            <HelpTextIcon infoText="API för godsägare används när man endast har en enskild godsägare.<br/>API för automation används för att hantera flera olika godsägares information." />
                        </div>
                        <div className="flex items-start">
                            <li>
                                Skapa en API-användare enligt instruktionerna.
                            </li>
                            <HelpTextIcon infoText="Om du använder *API för automation*: Klicka på 'Skapa ny användare' för att skapa en ny API-användare.<br/>Om du använder *API för godsägare*: Välj den godsägare du vill tilldela API-användaren till, och skapa därefter användaren för den valda godsägaren." />
                        </div>
                        <li>Fyll sedan i informationen som krävs nedan.</li>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <label
                                htmlFor="username"
                                className="block font-medium"
                            >
                                API Användarnamn
                            </label>
                            <input
                                type="text"
                                name="username"
                                value={configuration.username}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="password"
                                className="block font-medium"
                            >
                                API Lösenord
                            </label>
                            <input
                                type="password"
                                name="password"
                                value={configuration.password}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="identifier"
                                    className="block font-medium"
                                >
                                    Warehouse
                                </label>
                                <HelpTextIcon infoText="Ditt 'warehouse' namn kan hittas i URL:en som du använder för att logga in på Ongoing WMS. Det är den del av URL:en som kommer före '.ongoingsystems.se'. Exempelvis, om din inloggnings-URL är 'https://mywarehouse.ongoingsystems.se', då är 'mywarehouse' ditt 'warehouse'-namn. Om du är osäker, kontakta din ansvarige på lagret för att få det korrekta 'warehouse'-namnet." />
                            </div>
                            <input
                                type="text"
                                name="identifier"
                                value={configuration.identifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="selectedApi"
                                className="block font-medium"
                            >
                                Välj det API som användaren skapades för
                            </label>
                            <select
                                name="selectedApi"
                                value={configuration.selectedApi}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            >
                                <option value="" disabled hidden>
                                    Välj ett API
                                </option>
                                <option value="godsagare">
                                    API för godsägare
                                </option>
                                <option value="automation">
                                    API för automation
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={`/images/extensions/ongoingwms/apiUser.png`}
                        alt="Step one"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default OnGoingWmsStepOne;
