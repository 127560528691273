import React, { useContext, useState } from "react";
import Integrations from "../../../../constants/integrations";
import ExtensionCard from "../../Components/ExtensionCard";
import StepNavigation from "../../Components/StepNavigation";
import Api from "../../../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../../../context/Global";
import WoocommerceStepOne from "./Steps/WoocommerceStepOne";
import WoocommerceStepTwo from "./Steps/WoocommerceStepTwo";
import WoocommerceStepThree from "./Steps/WoocommerceStepThree";

function CreateWoocommerceExtension(props) {
    const context = useContext(Context);
    const [currentStep, setCurrentStep] = useState(1);

    const [configuration, setConfiguration] = useState({
        type: "WooCommerce",
        identifier: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguration((prevConf) => ({
            ...prevConf,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const response = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: configuration,
            });
            props.history.push(`/extensions/${response.id}`);
        } catch (error) {
            toast.error(
                "Misslyckades med att skapa integrationen, kontrollera uppgifterna och försök igen!",
            );
        }
    };

    const steps = [
        {
            component: <WoocommerceStepOne />,
            isValid: () => true,
        },
        {
            component: (
                <WoocommerceStepTwo
                    configuration={configuration}
                    handleChange={handleChange}
                />
            ),
            isValid: () => configuration.identifier.trim() !== "",
        },
        {
            component: <WoocommerceStepThree configuration={configuration} />,
            isValid: () => true,
        },
    ];

    const currentStepComponent = steps[currentStep - 1].component;

    return (
        <div className="min-h-full flex flex-col justify-between ml-20 pt-10">
            <div>
                <ExtensionCard extension={{ type: Integrations.WOO_COMMERCE }}>
                    <h1 className="text-2xl font-bold">
                        Lägg till Woocommerce
                    </h1>
                    <p className="text-base font-medium mt-2">
                        För att kunna aktivera denna integration behöver du
                        följa stegen som beskrivs nedan.
                    </p>
                </ExtensionCard>
            </div>

            <div className="flex-grow flex">
                <div className="max-w w-full pt-10">{currentStepComponent}</div>
            </div>

            <div className="pb-12">
                <StepNavigation
                    currentStep={currentStep}
                    totalSteps={steps.length}
                    onStepChanged={setCurrentStep}
                    isCurrentStepValid={steps[currentStep - 1].isValid()}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default CreateWoocommerceExtension;
