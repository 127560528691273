import { useContext, useEffect, useState } from "react";
import Context from "../../../context/Global";
import Api from "../../../services/Api/Api";
import {
    EXPIRE_SOON,
    EXPIRED,
} from "../../../constants/integrations/IntegrationStatuses";
import toast from "react-hot-toast";
import Integrations from "../../../constants/integrations";
import { useBusinessCentralAuthorization } from "./useBusinessCentralAuthorization";
import { renewFortnoxTokens } from "../utils/authUtils";

const useIntegration = ({ customerId, extensionId }) => {
    const context = useContext(Context);
    const [extension, setExtension] = useState(null);
    const [expiringExtension, setExpiringExtension] = useState(false);
    const [expiredExtension, setExpiredExtension] = useState(false);
    const [loading, setLoading] = useState(true);
    const { renewTokens: renewBusinessCentralTokens } =
        useBusinessCentralAuthorization();

    useEffect(() => {
        const fetchExtension = async () => {
            setLoading(true);
            try {
                const fetchedExtension = await Api.getCustomerIntegration({
                    customerId,
                    integrationId: extensionId,
                });

                // Check for expiring or expired tokens and set states accordingly
                const invalidTokenExtensions =
                    await context.checkIntegrationsExpiry();
                const invalidExtension = invalidTokenExtensions?.find(
                    (invalidExt) =>
                        invalidExt.integration.id === fetchedExtension.id,
                );

                if (invalidExtension) {
                    if (invalidExtension.status === EXPIRE_SOON) {
                        setExpiringExtension(true);
                    } else if (invalidExtension.status === EXPIRED) {
                        setExpiredExtension(true);
                    }
                }

                setExtension(fetchedExtension);
            } catch (error) {
                toast.error("Misslyckades med att hämta dina integrationer");
            } finally {
                setLoading(false);
            }
        };

        fetchExtension();
    }, [customerId, extensionId]);

    const handleRenewExtensionAuthorization = async () => {
        try {
            await renewAuthorizationTokens(extension);

            setExpiringExtension(false);
            setExpiredExtension(false);

            toast.success("Integrationen har förnyats");
        } catch (error) {
            toast.error("Misslyckades med att förnya integrationen");
        }
    };

    const renewAuthorizationTokens = async (extension) => {
        switch (extension.type) {
            case Integrations.BUSINESS_CENTRAL:
                return await renewBusinessCentralTokens(extension);
            case Integrations.FORTNOX:
                return await renewFortnoxTokens();
            default:
                return;
        }
    };

    return {
        extension,
        expiringExtension,
        expiredExtension,
        loading,
        handleRenewExtensionAuthorization,
    };
};

export default useIntegration;
