import React, { useState } from "react";
import GlobalContext from "./index";
import { version } from "../../../package.json";
import useCreateDraftsStore from "../../views/Drafts/Create/createDraftsStore";
import { fetchExpiredIntegrations } from "../../utils/fetchExpiredIntegrations";

const TemplateProvider = ({ children }) => {
    const [ticketNotificationCount, setTicketNotificationCount] = useState(0);
    const [supportNotificationCount, setSupportNotificationCount] = useState(0);
    const resetStates = useCreateDraftsStore((state) => state.resetStates);
    const [integrationsExpiry, setExpiryIntegrations] = useState([]);

    const clearUserData = () => {
        localStorage.removeItem("order");
        localStorage.removeItem("token");
        localStorage.removeItem("layout");
        clearOrder();
        setUser(null);
        setSocket(null);
    };

    const setUser = (user) => {
        setState((prevState) => {
            return {
                ...prevState,
                user: user,
            };
        });
    };

    const setSocket = (socket) => {
        setState((prevState) => {
            return {
                ...prevState,
                socket: socket,
            };
        });
    };

    const setLatestVersion = (latestVersion) => {
        setState((prevState) => {
            return {
                ...prevState,
                latestVersion: latestVersion,
            };
        });
    };

    const setLayout = (layout) => {
        localStorage.setItem("layout", layout);
        setState((prevState) => {
            return {
                ...prevState,
                layout: layout,
            };
        });
    };

    const setQuotation = (quotation) => {
        setState((prevState) => {
            return {
                ...prevState,
                quotation: quotation,
            };
        });
    };

    const getOrder = () => {
        return JSON.parse(localStorage.getItem("order"));
    };

    const updateOrder = (order) => {
        setState((prevState) => {
            order = {
                ...prevState.order,
                ...order,
            };
            localStorage.setItem("order", JSON.stringify(order));
            return {
                ...prevState,
                order: order,
            };
        });
    };

    const clearOrder = () => {
        resetStates();
        setState((prevState) => {
            localStorage.setItem("order", JSON.stringify({}));
            return {
                ...prevState,
                order: {},
            };
        });
    };

    const getApiOrderObject = () => {
        let order = {
            route: {},
            service: null,
        };
        let orderData = JSON.parse(localStorage.getItem("order"));
        order.route.from = orderData.sender;
        order.route.to = orderData.receiver;
        order.packages = orderData.packages;
        order.information = orderData.information;
        order.pickup = orderData.pickup;
        order.customs = orderData.customs;
        order.customsInvoice = orderData.customsInvoice;
        order.customsCommodities = orderData.customsCommodities;
        order.draftId = orderData.draftId || undefined;
        order.importedOrderId = orderData.importedOrderId || undefined;

        return order;
    };

    const [state, setState] = useState({
        user: null,
        socket: null,
        appVersion: version,
        version: null,
        latestVersion: false,
        setLatestVersion: setLatestVersion,
        layout: localStorage.getItem("layout")
            ? localStorage.getItem("layout")
            : "user",
        setSocket: setSocket,
        setUser: setUser,

        order: localStorage.getItem("order")
            ? JSON.parse(localStorage.getItem("order"))
            : {},
        getOrder: getOrder,
        updateOrder: updateOrder,
        clearOrder: clearOrder,
        quotation: null,
        setQuotation: setQuotation,
        getApiOrderObject: getApiOrderObject,
        setLayout: setLayout,
        clearUserData: clearUserData,
        setTicketNotificationCount,
        setSupportNotificationCount,
    });

    const checkIntegrationsExpiry = async () => {
        if (state.user.customer.integrations?.length === 0) return;
        const invalidTokenExtensions = await fetchExpiredIntegrations(
            state.user.customer.integrations,
        );
        setExpiryIntegrations(invalidTokenExtensions);
        return invalidTokenExtensions;
    };

    return (
        <GlobalContext.Provider
            value={{
                ...state,
                integrationsExpiry,
                setExpiryIntegrations,
                checkIntegrationsExpiry,
                ticketNotificationCount: ticketNotificationCount,
                supportNotificationCount: supportNotificationCount,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default TemplateProvider;
