import HelpTextIcon from "../../../Components/InformationIcon";

function VismaAdminStepThree() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Order Inställningar
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col text-md font-medium space-y-8 w-1/2">
                    <p>
                        I detta steg får du välja vilka data som ska hämtas in
                        från ordrarna i Visma samt andra inställningar
                        relaterade till hur informationen hanteras.
                    </p>
                    <ul className="list-disc list-outside pl-6 space-y-4">
                        <li>
                            Gör dina val för vilken data som hämtas in från
                            Visma ordrarna.
                        </li>
                        <div className="flex items-start">
                            <li>
                                Välj sedan vilket ordernummer du vill börja
                                hämta ordrar ifrån.
                            </li>
                            <HelpTextIcon
                                infoText={
                                    "Detta är det ordernummer som visas på ordern i Visma.<br/>Exempelvis '1234'"
                                }
                            />
                        </div>
                        <li>
                            Tryck sedan bekräfta och fortsätt till nästa steg
                        </li>
                    </ul>
                </div>

                <div className="ml-10 pr-10">
                    <img
                        src={"/images/extensions/visma/order.png"}
                        alt="Visma Inställningar"
                        className="w-[32rem] h-[32rem] object-contain max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default VismaAdminStepThree;
