import DisplayField from "../../../Components/DisplayField";
import HelpTextIcon from "../../../Components/InformationIcon";

function WoocommerceStepTwo({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Konfigurera Webhook
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Ange ett namn för webhooken.</li>
                        <li>Ändra "Status" till "Active".</li>
                        <div className="flex items-start">
                            <li>
                                Välj vilken "Topic" som ska trigga överföringen
                                av orderdata.
                            </li>
                            <HelpTextIcon infoText="Exempelvis om ni väljer 'Order Created', skickas ordern över när en ny order skapas i WooCommerce." />
                        </div>

                        <div className="flex flex-col">
                            <li>
                                Kopiera och klistra in följande URL i fältet
                                "Delivery URL":
                            </li>
                            <DisplayField
                                value={`${process.env.REACT_APP_API_URL}/api/wh/Rf83GaAcM82DqgOtME3sYpL2Dd97kxrdJSuhqj36fu2YAl96TME5AHihKQHfN2XT`}
                                copyToClipboard={true}
                            />
                        </div>

                        <li>Välj den senaste API-versionen.</li>

                        <div className="flex items-start pt-40">
                            <li>
                                Kopiera bas-URL:en för din WooCommerce-butik och
                                klistra in den i fältet nedan.
                            </li>
                            <HelpTextIcon infoText="Bas-URL:en kan kopieras från admin-sidan under 'My Home', markerad i rött på bild 2." />
                        </div>

                        <div>
                            <label
                                htmlFor="identifier"
                                className="block font-medium"
                            >
                                Bas-URL
                            </label>
                            <input
                                type="text"
                                name="identifier"
                                value={configuration.identifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/woocommerce/webhook.png`}
                        alt="Webhook inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                    <img
                        src={`/images/extensions/woocommerce/baseurl.png`}
                        alt="Webhook inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-3xl"
                    />
                </div>
            </div>
            <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                <li>
                    Tryck på "Save webhook" och gå vidare till nästa steg nedan.
                </li>
            </ul>
        </div>
    );
}

export default WoocommerceStepTwo;
