import React from "react";
import Table from "components/Table";

export function ContactsTable({
    currentPage,
    selectedContactIds,
    handleCheckboxChange,
    handleSelectAllChange,
    navigateToContact,
}) {
    const renderHeader = () => (
        <thead>
            <tr>
                <th className="w-12">
                    <input
                        type="checkbox"
                        checked={
                            currentPage.length > 0 &&
                            currentPage.every((contact) =>
                                selectedContactIds.includes(contact.id),
                            )
                        }
                        onChange={(e) =>
                            handleSelectAllChange(e.target.checked, currentPage)
                        }
                    />
                </th>
                <th>Namn</th>
                <th>Land</th>
                <th>Ort</th>
                <th>Kontaktperson</th>
                <th>Adress</th>
                <th></th>
            </tr>
        </thead>
    );

    const renderRow = (contact) => (
        <tr key={contact.id} onClick={() => navigateToContact(contact.id)}>
            <td onClick={(event) => event.stopPropagation()}>
                <input
                    type="checkbox"
                    checked={selectedContactIds.includes(contact.id)}
                    onChange={(e) => handleCheckboxChange(contact.id)}
                />
            </td>
            <td>{contact.contact.company || contact.contact.name}</td>
            <td>{contact.countryCode}</td>
            <td>{contact.postalTown}</td>
            <td>{!!contact.contact.company ? contact.contact.name : ""}</td>
            <td>
                {[
                    contact.addressLine1,
                    contact.addressLine2,
                    contact.addressLine3,
                ]
                    .filter((v) => v)
                    .join(", ")}
            </td>
            <td />
        </tr>
    );

    return (
        <Table>
            {renderHeader()}
            <tbody>
                {currentPage.map((contactData) => renderRow(contactData))}
            </tbody>
        </Table>
    );
}
