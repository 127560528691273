import { isMemberOfEU } from "constants/countryList";
import Context from "context/Global";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";
import DropInvoice from "./Drop";
import CustomsTerms from "../Terms";
import IntegratedInvoicePreview from "./IntegratedInvoicePreview";
import { isAlandPostCode } from "helpers/Country";

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding-top: 16px;
`;

let Container = styled.div`
    box-sizing: border-box;
    padding: 16px;
    max-width: 1000px;

    > .options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 32px 0;

        > .option {
            display: flex;
            flex-direction: column;
            width: calc(100% / 3);
            max-width: 400px;
            padding: 0 1rem 1rem 0;

            > .button {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                margin-bottom: 8px;
                background: #fff;
                padding: 64px 32px;
                height: 220px;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                &:last-child {
                    margin-right: 0;
                }
                &:disabled {
                    cursor: no-drop;
                    background: #ddd;
                }

                > .icon {
                    color: rgba(0, 0, 0, 0.3);
                    height: 48px;
                    width: 48px;
                    margin-right: 32px;
                    flex-shrink: 0;

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                > .text {
                    color: rgba(0, 0, 0, 0.7);
                    font-size: 1em;
                    font-weight: bold;
                    text-align: left;
                }
            }

            > .description {
                padding: 0rem 0;
            }

            &.disabled {
                > .button {
                    cursor: no-drop;
                    background: #ddd;

                    > .text {
                        color: #777;
                    }
                }
            }
        }
    }
`;

let Card = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    align-items: flex-start;
    background: #fff;
    padding: 16px;
`;

class View extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showUploadPopup: false,
            showDHLEXWMessage: false,
            showDHLDAPMessage: false,
            showIntegratedInvoicePreview: false,
        };

        this.attachInvoice = this.attachInvoice.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.uploadInvoice = this.uploadInvoice.bind(this);
        this.integrateInvoice = this.integrateInvoice.bind(this);
        this.createCustomsDeclaration =
            this.createCustomsDeclaration.bind(this);
    }

    componentWillMount() {
        // TODO: Check if customs is needed.
        let order = this.context.order;

        const senderCountryCode = order.sender.countryCode.substr(0, 2);
        const recipientCountryCode = order.receiver.countryCode.substr(0, 2);

        const forceInvoice =
            (senderCountryCode === "IE" || recipientCountryCode === "IE") &&
            ["FEDEX", "TNT"].indexOf(
                this.context?.quotation?.service?.name.toUpperCase(),
            ) < 0;

        if (forceInvoice) {
            return;
        }

        if (
            isMemberOfEU(senderCountryCode) &&
            isMemberOfEU(recipientCountryCode) &&
            !isAlandPostCode(order.sender.postalCode, senderCountryCode) &&
            !isAlandPostCode(order.receiver.postalCode, recipientCountryCode)
        ) {
            this.context.updateOrder({
                customs: null,
                customsInvoice: null,
            });
            this.props.history.push(`/orders/create`);
        }

        const isDHLEuroline =
            this.context?.quotation?.service.productCode === "DHLEuroline";
        const isExport =
            this.context.order.sender.countryCode === "SE" &&
            this.context.order.receiver.countryCode !== "SE";
        const isImport =
            this.context.order.sender.countryCode !== "SE" &&
            this.context.order.receiver.countryCode === "SE";

        this.setState({
            showDHLEXWMessage: isDHLEuroline && isImport,
            showDHLDAPMessage: isDHLEuroline && isExport,
        });
    }

    attachInvoice() {
        this.context.updateOrder({
            customs: null,
            customsInvoice: null,
        });
        this.props.history.push(`/orders/create`);
    }

    createInvoice() {
        this.context.updateOrder({
            customs: null,
            customsInvoice: null,
        });
        this.props.history.push(`/orders/customs/create`);
    }

    createCustomsDeclaration() {
        this.context.updateOrder({
            customs: null,
            customsInvoice: null,
        });
        this.props.history.push(`/orders/customs/declaration/create`);
    }

    uploadInvoice() {
        this.context.updateOrder({
            customs: null,
            customsInvoice: null,
            customsCommodities: null,
        });
        this.setState({
            showUploadPopup: true,
        });
    }

    integrateInvoice() {
        this.setState({ showIntegratedInvoicePreview: true });
    }

    render() {
        if (!this.context.quotation) {
            return <Redirect to="/quotations/create" />;
        }

        const forceCustomsDeclaration =
            this.context.quotation.service.name.toUpperCase() === "BRING";

        const customsInvoice = this.context.order?.customsInvoice || {};
        const customsValue = this.context.order?.information?.customsValue;

        let canUsePhysicalInvoice = true;
        let physicalInvoiceText =
            "Skickar ni med en egen utskriven tull-faktura väljer ni detta alternativ. Glöm inte att fakturan måste bifogas i minst 3 exemplar.";

        if (
            ["DHL EXPRESS"].indexOf(
                this.context?.quotation?.service?.name.toUpperCase(),
            ) >= 0
        ) {
            if (
                ["IN", "UA"].indexOf(
                    this.context?.order.receiver.countryCode,
                ) >= 0 ||
                ["IN", "UA"].indexOf(this.context?.order.sender.countryCode) >=
                    0
            ) {
                if (
                    this.context?.order.receiver.countryCode === "IN" ||
                    this.context?.order.sender.countryCode === "IN"
                ) {
                    canUsePhysicalInvoice = true;
                } else {
                    canUsePhysicalInvoice = false;
                    physicalInvoiceText =
                        "DHL Express tillåter inte att man skickar med tull-fakturan fysiskt till vissa länder. För att boka denna försändelse måste ni ladda upp fakturan digitalt eller skapa en ny med alternativen till höger.";
                }
            }
        }

        return (
            <Container>
                <Card>
                    Ni vill skicka en försändelse utanför EU och behöver därför
                    fylla i en tull-faktura.
                </Card>
                <CustomsTerms />
                <Title>Tull-faktura</Title>
                {customsValue < 1 && (
                    <Card>
                        <p className="text-red-400">
                            Fyll i tull-villkor och tullvärde för att kunna gå
                            vidare.
                        </p>
                    </Card>
                )}
                <div className="options">
                    {forceCustomsDeclaration ? (
                        <div className="option">
                            <button
                                className="button"
                                onClick={this.createCustomsDeclaration}
                                disabled={customsValue < 1}
                            >
                                <div className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M15.812 4.819c-.33-.341-.312-.877.028-1.207l3.469-3.365c.17-.164.387-.247.603-.247.219 0 .438.085.604.257l-4.704 4.562zm-5.705 8.572c-.07.069-.107.162-.107.255 0 .194.158.354.354.354.089 0 .178-.033.247-.1l.583-.567-.493-.509-.584.567zm4.924-6.552l-1.994 1.933c-1.072 1.039-1.619 2.046-2.124 3.451l.881.909c1.419-.461 2.442-.976 3.514-2.016l1.994-1.934-2.271-2.343zm5.816-5.958l-5.137 4.982 2.586 2.671 5.138-4.98c.377-.366.566-.851.566-1.337 0-1.624-1.968-2.486-3.153-1.336zm-11.847 12.119h-4v1h4v-1zm9-1.35v1.893c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-20h12.629l2.062-2h-16.691v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-4.687l-2 1.951z"
                                        />
                                    </svg>
                                </div>
                                <div className="text">
                                    Skapa tulldeklaration
                                </div>
                            </button>
                            <div className="description">
                                Klicka här för att gå vidare till nästa steg och
                                färdigställa tulldeklarationen.
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className={`option ${
                                    !canUsePhysicalInvoice ? "disabled" : ""
                                }`}
                            >
                                <button
                                    disabled={
                                        customsValue < 1 ||
                                        this.context.order?.information
                                            ?.thirdPartyContactId
                                    }
                                    className="button"
                                    onClick={this.attachInvoice}
                                >
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M9 1v7.5c0 1.933-1.566 3.5-3.5 3.5s-3.5-1.567-3.5-3.5v-6c0-1.381 1.119-2.5 2.5-2.5s2.5 1.119 2.5 2.5v4.5c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-4h1v4c0 .275.225.5.5.5s.5-.225.5-.5v-4.5c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v6c0 1.378 1.121 2.5 2.5 2.5s2.5-1.122 2.5-2.5v-7.5h1zm2 0v2c3.282 0 3.772 2.946 3 6 0 0 6-1.65 6 2.457v10.543h-15v-8.025c-.715-.065-1.39-.269-2-.582v10.607h19v-13.386c0-1.843-5.583-9.614-11-9.614zm6 13h-9v1h9v-1zm0 3h-9v-1h9v1zm0 2h-9v-1h9v1z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="text">Egen tullfaktura</div>
                                </button>
                                <div className="description">
                                    {physicalInvoiceText}
                                </div>
                            </div>
                            <div className="option">
                                <button
                                    className="button"
                                    onClick={this.uploadInvoice}
                                    disabled={customsValue < 1}
                                >
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M16 16h-3v5h-2v-5h-3l4-4 4 4zm3.479-5.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="text">
                                        Tryck här för att ladda upp en egen
                                        tullfaktura
                                    </div>
                                </button>
                                <div className="description">
                                    Här kan ni ladda upp er egna tullfaktura som
                                    en PDF. Då kan ni enkelt komma åt fakturan
                                    här i portalen om det skulle behövas. Vissa
                                    transportörer ger oss möjligheten att skicka
                                    fakturan digitalt och ni slipper då bifoga
                                    en fysisk faktura. Är ni osäker på vad som
                                    gäller just er försändelse rekommenderar vi
                                    att ni skickar med 3 exemplar tillsammans
                                    med er försändelse.
                                </div>
                            </div>
                            <div className="option">
                                <button
                                    className="button"
                                    onClick={this.createInvoice}
                                    disabled={customsValue < 1}
                                >
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M15.812 4.819c-.33-.341-.312-.877.028-1.207l3.469-3.365c.17-.164.387-.247.603-.247.219 0 .438.085.604.257l-4.704 4.562zm-5.705 8.572c-.07.069-.107.162-.107.255 0 .194.158.354.354.354.089 0 .178-.033.247-.1l.583-.567-.493-.509-.584.567zm4.924-6.552l-1.994 1.933c-1.072 1.039-1.619 2.046-2.124 3.451l.881.909c1.419-.461 2.442-.976 3.514-2.016l1.994-1.934-2.271-2.343zm5.816-5.958l-5.137 4.982 2.586 2.671 5.138-4.98c.377-.366.566-.851.566-1.337 0-1.624-1.968-2.486-3.153-1.336zm-11.847 12.119h-4v1h4v-1zm9-1.35v1.893c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-20h12.629l2.062-2h-16.691v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-4.687l-2 1.951z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="text">
                                        Skapa tullfaktura nu
                                    </div>
                                </button>
                                <div className="description">
                                    Här skapar ni en faktura direkt i portalen.
                                    Vissa transportörer ger oss möjligheten att
                                    skicka fakturan digitalt och ni slipper då
                                    bifoga en fysisk faktura. Är ni osäker på
                                    vad som gäller just er försändelse
                                    rekommenderar vi att ni skickar med 3
                                    exemplar tillsammans med er försändelse.
                                </div>
                            </div>
                            {this.context.order.importedOrderId &&
                                customsInvoice.fileName &&
                                customsInvoice.fileContent && (
                                    <div className="option">
                                        <button
                                            className="button"
                                            onClick={this.integrateInvoice}
                                            disabled={customsValue < 1}
                                        >
                                            <div className="icon">
                                                <img
                                                    alt="Close"
                                                    src="/images/icons/integrated-customs-invoice.svg"
                                                    style={{
                                                        transform: "scale(2.2)",
                                                    }}
                                                />
                                            </div>
                                            <div className="text">
                                                Använd integrerad tullfaktura
                                            </div>
                                        </button>
                                        <div className="description">
                                            Här kan ni använda en redan
                                            integrerad tullfaktura från ert
                                            system. Om ni vill använda en annan
                                            tullfaktura kan ni ladda upp en
                                            eller skapa en helt ny.
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                </div>
                {this.state.showUploadPopup && (
                    <DropInvoice
                        onClose={() => {
                            this.setState({
                                showUploadPopup: false,
                            });
                            if (this.context.order.customsInvoice) {
                                this.props.history.push(`/orders/create`);
                            }
                        }}
                    />
                )}
                {this.state.showIntegratedInvoicePreview && (
                    <IntegratedInvoicePreview
                        onClose={() => {
                            this.setState({
                                showIntegratedInvoicePreview: false,
                            });
                        }}
                        history={this.props.history}
                    />
                )}
                {this.state.showDHLEXWMessage && (
                    <div className="absolute top-0 left-0 w-full h-full z-50 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-4 rounded max-w-lg flex flex-col gap-2 items-start">
                            <div>
                                För att importera försändelser via DHL Euroline
                                är det nödvändigt att tullvillkoren specificeras
                                som EXW. Om ert dokument <b>inte</b> redovisar
                                dessa villkor ber vi er vänligen att korrigerar
                                detta för att undvika potentiella förseningar
                                vid tullhanteringen.
                            </div>
                            <button
                                className="c-button c-button--raised self-end"
                                onClick={() => {
                                    this.setState({
                                        showDHLEXWMessage: false,
                                    });
                                }}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )}
                {this.state.showDHLDAPMessage && (
                    <div className="absolute top-0 left-0 w-full h-full z-50 bg-black bg-opacity-10 flex justify-center items-center">
                        <div className="bg-white p-4 rounded max-w-lg flex flex-col gap-2 items-start">
                            <div>
                                För att exportera försändelser via DHL Euroline
                                krävs det att tullvillkoren angivs som DAP. Om
                                ert dokument <b>inte</b> redovisar dessa
                                specifika villkor ber vi er vänligen att
                                korrigerar detta för att undvika eventuella
                                förseningar vid tullhanteringen.
                            </div>
                            <button
                                className="c-button c-button--raised self-end"
                                onClick={() => {
                                    this.setState({
                                        showDHLDAPMessage: false,
                                    });
                                }}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )}
            </Container>
        );
    }
}

View.contextType = Context;

export default View;
