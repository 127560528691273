import toast from "react-hot-toast";

function FortnoxStepThree({ configuration, handleChange }) {
    const handleFortnoxLogin = () => {
        const windowRef = window.open(
            `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FORTNOX_OAUTH_REDIRECT_URL}&scope=article%20order%20customer&state=createExtension&access_type=offline&response_type=code`,
            "Connect to Fortnox",
            "width=1000,height=800",
        );

        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;

            const { type, authorizationCode } = event.data;

            if (type === "FORTNOX_AUTH_CODE" && authorizationCode) {
                handleChange({
                    target: {
                        name: "authorizationCode",
                        value: authorizationCode,
                    },
                });
                window.removeEventListener("message", handleMessage);
                windowRef?.close();

                toast.success("Inloggningen lyckades!");
            }
        };

        window.addEventListener("message", handleMessage);
    };

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Auktorisera med Fortnox
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-6 w-1/2">
                    {!configuration.authorizationCode ? (
                        <>
                            <p>
                                Klicka på "Logga In" för att auktorisera din
                                Fortnox-integration och hämta din
                                auktoriseringskod.
                            </p>
                            <button
                                className="c-button c-button--raised w-1/5"
                                onClick={handleFortnoxLogin}
                            >
                                Logga In
                            </button>
                        </>
                    ) : (
                        <div>
                            <p className="text-green-600 font-semibold mb-4">
                                Inloggningen lyckades!
                            </p>
                            <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                                <li>
                                    Gå vidare för att verfiera din inställningar
                                    och slutföra uppsättningen av din Fortnox
                                    integration.
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FortnoxStepThree;
