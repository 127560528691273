import Integrations from "../constants/integrations";
import Api from "../services/Api/Api";
import toast from "react-hot-toast";

/**
 * Fetches the expiration status for specified integrations.
 * It filters specific integration types and retrieves their expiration statuses.
 *
 * @param {Object[]} integrations - The array of integration objects to check.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of
 *          integration expiry statuses or an empty array if no integrations
 *          match the filter or if the input is invalid.
 */
export const fetchExpiredIntegrations = async (integrations) => {
    if (!integrations || !integrations.length) {
        return [];
    }

    const filterTypes = [Integrations.FORTNOX, Integrations.BUSINESS_CENTRAL];
    const integrationIds = integrations
        .filter((integration) => filterTypes.includes(integration?.type))
        .map((integration) => integration.id);

    if (integrationIds.length > 0) {
        try {
            return await Api.getIntegrationTokenExpiryStatuses({
                integrationIds,
            });
        } catch (error) {
            toast.error("Misslyckades med att förnya integrationer.");
        }
    }
    return [];
};
