import Input from "components/OldInput";
import InputMultiToggle from "components/Input/MultiToggle";
import InputPostalCode from "components/Input/PostalCode";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import InputText from "components/Input/Text";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
`;

let Form = styled.form`
    padding: 16px;

    > .button {
        margin-top: 32px;
    }
`;

let Title = styled.form`
    padding: 16px;
    padding-bottom: 4px;
    font-size: 26px;
`;

let cutoffTimeOptions = [];

for (var i = 8; i <= 18; i++) {
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":00",
        value: ("0" + i).slice(-2) + ":00",
    });
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":30",
        value: ("0" + i).slice(-2) + ":30",
    });
}

function View({ customerId, history, ...props }) {
    const context = useContext(Context);
    const [customer, setCustomer] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [clientManagers, setClientManagers] = useState();
    const customerInputRef = useRef();
    const settingsInputRef = useRef();

    const [forcedMargins, setForcedMargins] = useState({});

    const upsProducts = [
        "UPS Express",
        "UPS Expedited",
        "UPS Standard",
        "UPS Saver",
        "UPS AccessPoint",
        "UPS AccessPoint Zendr",
        "UPS Expedited Zendr",
        "UPS Express Zendr",
        "UPS Saver Zendr",
        "UPS Standard Zendr",
    ];

    const bringProducts = [
        "Bring Business Parcel Utrikes",
        "Bring Business Pallet",
        "Bring Business Halfpallet",
        "Bring Business Quarterpallet",
    ];

    const dsvProducts = [
        "DSV Export",
        "DSV Export Pall",
        "DSV Import",
        "DSV Import Pall",
    ];

    useEffect(() => {
        if (
            context.user.administrator ||
            (context.user.brand && context.user.brand.id) ||
            context.user.customer.id === parseInt(customerId)
        ) {
            loadCustomer();
        } else {
            history.replace("/admin");
        }
    }, []);

    async function loadCustomer() {
        const customer = await Api.getCustomer({
            customerId: customerId,
        });
        setCustomer(customer);
        setClientManagers(
            [
                {
                    name: "Ingen kundansvarig vald",
                    id: "",
                },
            ].concat(
                await Api.getClientManagers({
                    brandId: customer.brand.id,
                    includeOwners: true,
                }),
            ),
        );
        setForcedMargins(customer.settings.dynamicPriceForcedMargins || {});
    }

    const prepareForcedMargins = (forcedMargins) => {
        return Object.keys(forcedMargins).reduce((obj, key) => {
            let numValue = Number(forcedMargins[key]);
            return numValue ? { ...obj, [key]: numValue } : obj;
        }, {});
    };

    async function updateCustomer() {
        setUpdating(true);
        const result = await Api.updateCustomer({
            customerId: customerId,
            customer: customerInputRef.current.value(),
            settings: {
                ...settingsInputRef.current.value(),
                dynamicPriceForcedMargins: prepareForcedMargins(forcedMargins),
            },
        });
        const updatedUser = {
            ...context.user,
            customer: {
                ...context.user.customer,
                settings: {
                    ...customer.settings,
                    receiverPays: false,
                },
            },
        };
        context.setUser(updatedUser);
        setTimeout(() => {
            history.goBack();
        }, 500);
    }

    if (!customer || !clientManagers) {
        return <Loader />;
    }

    return (
        <Container>
            <Title>Redigera företag</Title>
            <Form>
                <Input
                    ref={customerInputRef}
                    value={customer}
                    type="list"
                    object={{
                        name: {
                            title: "Företagsnamn",
                            type: "text",
                            maxLength: 35,
                            autoComplete: "chrome-off",
                            required: true,
                        },
                        clientNumber: {
                            title: "Kundnummer",
                            type: "text",
                            autoComplete: "chrome-off",
                            required: false,
                        },
                        clientManagerId: {
                            title: "Kundansvarig",
                            type: "dropdown",
                            value: customer.clientManager?.id,
                            options: clientManagers.map((clientManager) => {
                                return {
                                    title: clientManager.name,
                                    value: clientManager.id,
                                };
                            }),
                        },
                        countryCode: {
                            title: "Land",
                            type: "dropdown",
                            autoComplete: "chrome-off",
                            disabled: true,
                            value: "SE",
                            options: [
                                {
                                    title: "Sverige",
                                    value: "SE",
                                },
                            ],
                        },
                        postalCode: {
                            type: InputPostalCode,
                            title: "Postnummer",
                            autoComplete: "chrome-off",
                            required: true,
                            countryCode: "SE",
                            onPostalTownSelected: (item) => {
                                customerInputRef.current.set({
                                    postalTown: item.value.postalTown,
                                });
                            },
                        },
                        postalTown: {
                            title: "Postort",
                            type: "text",
                            autoComplete: "chrome-off",
                            placeholder: "",
                            required: true,
                        },
                        addressLine1: {
                            title: "Adress",
                            type: "text",
                            autoComplete: "chrome-off",
                            placeholder: "",
                            maxLength: 30,
                            required: true,
                        },
                        addressLine2: {
                            title: "",
                            type: "text",
                            autoComplete: "chrome-off",
                            placeholder: "",
                            maxLength: 30,
                            required: false,
                        },
                        vatNumber: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Tull-id",
                            required: true,
                            helpText:
                                "Momsnummer, VAT number, Tax number, etc.",
                        },
                        contactName: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Kontaktperson",
                            required: true,
                        },
                        contactEmail: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Kontakt-mail",
                            required: true,
                        },
                        contactPhoneNumber: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Kontakt-telefonnummer",
                            required: true,
                        },
                        invoiceEmail: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Faktura-mail",
                            required: true,
                        },
                        alias: {
                            type: "text",
                            autoComplete: "chrome-off",
                            title: "Alias för att matcha fakturor (separera med semikolon)",
                            placeholder: "Alias 1; Alias 2; Alias 3; osv.",
                            required: false,
                        },
                        labelFormat: {
                            type: InputMultiToggle,
                            autoComplete: "chrome-off",
                            title: "Föredraget format på etiketter",
                            helpText:
                                "Används på de leverantörer där det är applicerbart.",
                            required: true,
                            options: [
                                {
                                    title: "Etikett",
                                    value: "label",
                                },
                                {
                                    title: "A4 - halv sida",
                                    value: "a4",
                                },
                                {
                                    title: "A4 - 2 liggande etiketter",
                                    value: "a4-2-landscape",
                                },
                                {
                                    title: "A4 - 3 liggande etiketter",
                                    value: "a4-3-landscape",
                                },
                                {
                                    title: "A4 - 2 stående etiketter",
                                    value: "a4-2-portrait",
                                },
                                {
                                    title: "A4 - 2 stående etiketter centrerat",
                                    value: "a4-2-portrait-center",
                                },
                                {
                                    title: "A4 - 1 stående etikett",
                                    value: "a4-1-portrait",
                                },
                                {
                                    title: "Etikettskrivare 107 x 251 mm",
                                    value: "107x251",
                                },
                                {
                                    title: "Etikettskrivare 103 x 165 mm",
                                    value: "103x165",
                                },
                            ],
                        },
                    }}
                />
                <Input
                    ref={settingsInputRef}
                    value={customer.settings}
                    type="list"
                    object={{
                        dhlCutoffTime: {
                            title: "Stopptid DHL",
                            type: "dropdown",
                            options: cutoffTimeOptions,
                            autoComplete: "chrome-off",
                        },
                        dangerousGoods: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera farligt gods",
                        },
                        limitedQuantity: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera limited quantity",
                        },
                        drafts: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera sparade försändelser",
                        },
                        dhlPalletExchange: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera DHL PÖS",
                        },
                        emissions: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera miljö/utsläpp",
                        },
                        searchEmissions: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera miljö/utsläpp i sökresultat",
                        },
                        goodsRowDescription: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera godsrads-beskrivning",
                        },
                        productSpecificPackages: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Produktspecifika pakettyper",
                        },
                        togglePackageSizeDefinition: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Byta paketbeskrivning mellan dimensioner och volym",
                        },
                        bookBatchDirectWithoutQuote: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Boka batch direkt utan att söka pris",
                        },
                        invoiceSpecificationFileType: {
                            type: InputMultiToggle,
                            autoComplete: "chrome-off",
                            title: "Filformat på specifikation vid fakturering",
                            required: true,
                            options: [
                                {
                                    title: "Bifoga specifikation som excel-fil",
                                    value: "excel",
                                },
                                {
                                    title: "Bifoga specifikation som PDF",
                                    value: "pdf",
                                },
                            ],
                        },
                        receiverPays: {
                            type: "checkbox",
                            autoComplete: "chrome-off",
                            title: "Aktivera mottagarfrakt (måste aktiveras av kund)",
                            disabled:
                                !context.user.customer.settings.receiverPays,
                        },
                    }}
                />

                <h2 className="text-3xl font-bold mt-12">
                    Fast marignaler för dynamiska priser
                </h2>
                <p className="text-red-500 font-bold">
                    Ändra endast denna efter godkännande från ägarna
                </p>
                {upsProducts.map((upsProduct) => (
                    <div className="mt-4" key={`forced_margin_${upsProduct}`}>
                        <div className="text-sm mb-2 flex items-center">
                            <p className="font-bold">{upsProduct}</p>
                        </div>
                        <InputText
                            onChange={(value) =>
                                setForcedMargins({
                                    ...forcedMargins,
                                    [upsProduct]: value,
                                })
                            }
                            value={forcedMargins[upsProduct]}
                        />
                    </div>
                ))}
                {bringProducts.map((bringProduct) => (
                    <div className="mt-4" key={`forced_margin_${bringProduct}`}>
                        <div className="text-sm mb-2 flex items-center">
                            <p className="font-bold">{bringProduct}</p>
                        </div>
                        <InputText
                            onChange={(value) =>
                                setForcedMargins({
                                    ...forcedMargins,
                                    [bringProduct]: value,
                                })
                            }
                            value={forcedMargins[bringProduct]}
                        />
                    </div>
                ))}
                {dsvProducts.map((dsvProduct) => (
                    <div className="mt-4" key={`forced_margin_${dsvProduct}`}>
                        <div className="text-sm mb-2 flex items-center">
                            <p className="font-bold">{dsvProduct}</p>
                        </div>
                        <InputText
                            onChange={(value) =>
                                setForcedMargins({
                                    ...forcedMargins,
                                    [dsvProduct]: value,
                                })
                            }
                            value={forcedMargins[dsvProduct]}
                        />
                    </div>
                ))}
                <button
                    className="button c-button c-button--raised"
                    disabled={!!updating}
                    onClick={updateCustomer}
                >
                    Spara
                </button>
            </Form>
        </Container>
    );
}

export default withRouter(View);
