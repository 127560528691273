import HelpTextIcon from "../../../Components/InformationIcon";
import DisplayField from "../../../Components/DisplayField";
import React, { useState } from "react";

function MonitorG5StepOne({
    prerequisites,
    setPrerequisites,
    solutionType,
    setSolutionType,
}) {
    const handleCheckboxToggle = () => {
        setPrerequisites(!prerequisites);
    };

    const handleSolutionChange = (e) => {
        setSolutionType(e.target.value);
    };

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Tillåt anslutning till Monitor G5
                </span>
            </h3>

            <div className="mb-6">
                <label className="block font-medium mb-2">
                    Välj er lösning:
                </label>
                <div className="flex space-x-4">
                    <div className="flex items-center">
                        <input
                            type="radio"
                            value="cloud"
                            checked={solutionType === "cloud"}
                            onChange={handleSolutionChange}
                            className="form-radio w-4 h-4"
                            style={{
                                accentColor: "rgba(76, 80, 175, 1)",
                            }}
                        />
                        <label className="ml-2">
                            Cloud (hostad av Monitor)
                        </label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            value="onpremise"
                            checked={solutionType === "onpremise"}
                            onChange={handleSolutionChange}
                            className="form-radio w-4 h-4"
                            style={{
                                accentColor: "rgba(76, 80, 175, 1)",
                            }}
                        />
                        <label className="ml-2">
                            On-premise (hostad av er)
                        </label>
                    </div>
                </div>
            </div>

            {solutionType && (
                <div className="flex items-start">
                    <div className="flex flex-col space-y-8 w-3/4">
                        <label className="block font-medium">
                            Förbered följande steg för att ansluta till Monitor
                            G5 (
                            {solutionType === "cloud" ? "Cloud" : "On-premise"}
                            ).
                        </label>
                        <ul className="text-md font-medium list-decimal list-outside pl-6 space-y-4">
                            <div className="flex items-start">
                                <li>
                                    {solutionType === "cloud"
                                        ? "Kontakta Monitor Cloud Support via e-post för att öppna access för vår statiska IP-adress på er server."
                                        : "Kontakta er IT-support för att öppna upp access för vår statiska IP-adress till er server."}
                                </li>
                                <HelpTextIcon
                                    infoText={`Skicka ${
                                        solutionType === "cloud"
                                            ? "e-post"
                                            : "information"
                                    } med företags- eller system-ID, ${
                                        solutionType === "cloud"
                                            ? "be om certifikat för Monitor och"
                                            : ""
                                    } whitelista följande IP-adress:<br/>${
                                        process.env.REACT_APP_API_WHITELIST_IP
                                    }`}
                                />
                            </div>

                            <DisplayField
                                label={
                                    solutionType === "cloud"
                                        ? "Nödvändig information för e-post:"
                                        : "Nödvändig information för IT-support:"
                                }
                                value={
                                    <>
                                        {solutionType === "cloud" ? (
                                            <>
                                                Öppna upp access till
                                                cloud-instans för Zendr AB.
                                                <br />
                                                Företags/system-id: [Ersätt med
                                                ID]
                                                <br />
                                                Statisk IP för access:{" "}
                                                {
                                                    process.env
                                                        .REACT_APP_API_WHITELIST_IP
                                                }
                                                <br />
                                                Certifikat för åtkomst via
                                                https://[Ersätt med ert
                                                företagsnamn].monitorcloud.se.
                                                <br />
                                                Be dem återkomma med information
                                                om bas-URL och port för
                                                anslutningen.
                                            </>
                                        ) : (
                                            <>
                                                Öppna upp access för statisk
                                                IP-adress:{" "}
                                                {
                                                    process.env
                                                        .REACT_APP_API_WHITELIST_IP
                                                }
                                                <br />
                                                Säkerställ att Zendr kan nå er
                                                server.
                                                <br />
                                                Be dem återkomma med information
                                                om bas-URL och port för
                                                anslutningen.
                                            </>
                                        )}
                                    </>
                                }
                            />
                            <li>
                                Skapa en dedikerad API-användare för Zendr i
                                Monitor G5.
                                <ul className="text-md font-medium list-outside pl-6 space-y-4">
                                    <li className="flex flex-col">
                                        Använd Monitors guide för att skapa en
                                        API-användare.
                                        <a
                                            className="text-blue-700 cursor-pointer underline inline-block"
                                            href="https://vimeopro.com/monitoracademy/api/video/683698462"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Monitor G5 - Skapa API användare
                                        </a>
                                    </li>
                                    <li>
                                        Tilldela följande behörigheter:
                                        <ul className="text-md font-medium list-disc list-outside pl-10 space-y-1">
                                            <li>Visa kund</li>
                                            <li>Visa kundordrar</li>
                                            <li>Visa ordertyper</li>
                                            <li>Visa länder</li>
                                            <li>Visa valutor</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Spara användarnamn och lösenord för
                                        nästa steg.
                                    </li>
                                </ul>
                            </li>

                            <div>
                                Vid oklarheter, kontakta er kundansvarige på
                                Zendr för hjälp.
                            </div>

                            <li>
                                <p className="mb-4 mt-4">
                                    När förberedelserna är genomförda, kryssa i
                                    rutan och fortsätt till nästa steg.
                                </p>
                                <div className="flex items-center mb-4">
                                    <input
                                        type="checkbox"
                                        name="prerequisites"
                                        checked={prerequisites}
                                        onChange={handleCheckboxToggle}
                                        className="form-checkbox w-5 h-5"
                                        style={{
                                            accentColor: "rgba(76, 80, 175, 1)",
                                        }}
                                    />
                                    <label className="ml-2">
                                        Jag har genomfört ovanstående steg.
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MonitorG5StepOne;
