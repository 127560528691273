import HelpTextIcon from "../../../Components/InformationIcon";

function StarwebStepThree({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Skapa API Klient
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>Klicka på "Lägg till ny klient".</li>
                        <div className="flex items-start">
                            <li>Ange ett unikt "Klient-id".</li>
                            <HelpTextIcon infoText="Klient-id är ett unikt ID som identifierar klienten. Generera eller skapa ett så unikt ID som möjligt för att öka säkerhet och undvika konflikter." />
                        </div>
                        <li>Välj "Zendr AB" i listan för API-grupp.</li>
                        <li>Välj "Ordrar" i listan för rättigheter.</li>
                        <li>
                            Klicka på "Spara" och fyll sedan i uppgifterna
                            nedan.
                        </li>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="clientId"
                                    className="block font-medium"
                                >
                                    Klient Id
                                </label>
                                <HelpTextIcon infoText="Ditt klient-id fick ett suffix. Klistra in hela ID:t i fältet nedan." />
                            </div>
                            <input
                                type="text"
                                name="clientId"
                                value={configuration.clientId}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="clientSecret"
                                    className="block font-medium"
                                >
                                    Klient Hemlighet
                                </label>
                                <HelpTextIcon infoText="Efter att du sparat din API-klient genereras en unik 'Klienthemlighet'. Klistra in den i fältet nedan." />
                            </div>
                            <input
                                type="text"
                                name="clientSecret"
                                value={configuration.clientSecret}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="shopIdentifier"
                                    className="block font-medium"
                                >
                                    Butiks Id
                                </label>
                                <HelpTextIcon infoText="Butiks Id är unikt för er butik. Använd värdet från 'API Endpoint' (markerat i rött på bilden) eller kontakta Starweb support." />
                            </div>
                            <input
                                type="text"
                                name="shopIdentifier"
                                value={configuration.shopIdentifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md mb-10"
                                required
                            />
                        </div>
                        <li>Fortsätt till nästa steg nedan.</li>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/starweb/apiclient.png`}
                        alt="Inställningar för API-klient"
                        className="object-contain w-[32rem] h-[32rem] max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default StarwebStepThree;
