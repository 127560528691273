import HelpTextIcon from "../../../Components/InformationIcon";
import moment from "moment";

function BusinessCentralStepThree({
    configuration,
    handleChange,
    handleLogin,
}) {
    const getTodaysDate = () => {
        return new moment().format("YYYY-MM-DD");
    };

    const getPreviousMonthDate = () => {
        return moment().subtract(1, "months").format("YYYY-MM-DD");
    };

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Logga in till Microsoft Azure
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>Navigera tillbaka till "Översikt" i sidomenyn.</li>
                        <li>
                            Kopiera värdet för "Program-ID (klient)" och klistra
                            in det i fältet nedan.
                        </li>
                        <div className="flex items-start">
                            <li>
                                Om ni har en specifik miljö ni vill använda,
                                fyll i miljöns namn.
                            </li>
                            <HelpTextIcon infoText="Om ni inte är säker på vilken miljö ni ska använda, rekommenderar vi att ni använder 'production'." />
                        </div>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <label
                                htmlFor="clientId"
                                className="block font-medium"
                            >
                                Program-ID
                            </label>
                            <input
                                type="text"
                                name="clientId"
                                value={configuration.clientId}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="environment"
                                className="block font-medium"
                            >
                                Miljö
                            </label>
                            <input
                                type="text"
                                name="environment"
                                placeholder={"production"}
                                value={configuration.environment}
                                onChange={handleChange}
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="fetchStatus"
                                    className="block font-medium"
                                >
                                    Status för hämtning
                                </label>
                                <HelpTextIcon infoText="Välj om ni vill att ordern ska hämtas in när status sätts till 'Öppen' eller 'Släppt'." />
                            </div>
                            <select
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                name="fetchStatus"
                                onChange={handleChange}
                                value={configuration.fetchStatus}
                            >
                                <option value="Draft">Öppen</option>
                                <option value="Open">Släppt</option>
                            </select>
                        </div>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="initialFetchDate"
                                    className="block font-medium"
                                >
                                    Startdatum för hämtning
                                </label>
                                <HelpTextIcon infoText="Välj ett startdatum baserat på dokumentdatum i Business Central. Ordrar med 'dokumentdatum' från och med detta datum kommer att hämtas in." />
                            </div>
                            <input
                                className="w-3/5 p-2 h-10 border border-gray-300 rounded-md"
                                name="initialFetchDate"
                                type="date"
                                value={configuration.initialFetchDate}
                                onChange={handleChange}
                                min={getPreviousMonthDate()}
                                max={getTodaysDate()}
                            />
                        </div>
                    </div>

                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            För att aktivera kopplingen till Business Central så
                            krävs det att du loggar in med ditt Microsoft-konto.
                        </li>
                        <li>Klicka på "Logga in" för att fortsätta.</li>
                        <button
                            className="c-button c-button--raised mt-4 w-60"
                            onClick={handleLogin}
                            disabled={
                                !configuration.clientId ||
                                configuration.accessToken
                            }
                        >
                            {configuration.accessToken
                                ? "Inloggad"
                                : "Logga in"}
                        </button>
                    </ul>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={`/images/extensions/businesscentral/overview.png`}
                        alt="Step one"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default BusinessCentralStepThree;
