import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function getText(obj) {
    return obj?._text ?? "";
}

function parse({ data, fileName }) {
    if (!data.Download?.Invoice_Download) {
        return [];
    }

    const invoices = Array.isArray(data.Download.Invoice_Download)
        ? data.Download.Invoice_Download
        : [data.Download.Invoice_Download];

    const parsedInvoiceRows = [];
    for (let invoice of invoices) {
        const taxInvoice =
            invoice.Invoice_Type &&
            getText(invoice.Invoice_Type) === "Duty/Tax";

        let chargeLabels;
        let chargePrice;

        if (taxInvoice) {
            chargeLabels = invoice.Air_Waybill_Charge_Label || [];
            chargePrice = invoice.Air_Waybill_Charge_Amount || [];
        } else {
            chargeLabels = invoice.Avgiftsetikett_fr_fraktsedel || [];
            chargePrice = invoice.Avgiftsbelopp_fr_fraktsedel || [];
        }

        chargeLabels = Array.isArray(chargeLabels)
            ? chargeLabels
            : [chargeLabels];
        chargePrice = Array.isArray(chargePrice) ? chargePrice : [chargePrice];

        let totalShipmentCostVat = 0;
        let totalShipmentCostNoVat = 0;
        let totalShipmentCost = 0;
        const rows = [];

        let vat = false;
        for (let i = 0; i < chargeLabels.length; i++) {
            if (getText(chargeLabels[i]) === "Sverige moms 25") {
                const amount = getText(chargePrice[i]);

                if (amount) {
                    vat = true;
                    break;
                }
            }
        }

        for (let i = 0; i < chargeLabels.length; i++) {
            const chargeLabel = getText(chargeLabels[i]);
            const chargeAmount = getText(chargePrice[i]).replace(",", "");

            if (!chargeLabel || chargeLabel === "Sverige moms 25") {
                continue;
            }

            const amount = parseFloat(chargeAmount || 0);
            totalShipmentCost += amount;

            if (!["Transportavgift", "Rabatt"].includes(chargeLabel)) {
                const surcharge = chargeLabel === "Brnsleavgift";

                rows.push({
                    title: chargeLabel,
                    cost: amount,
                    surcharge,
                    isInsurance: chargeLabel === "Avgift fr deklarerat vrde",
                    vat: vat,
                    key: uuidv4(),
                });
            }
        }

        const weight = getText(
            taxInvoice ? invoice.Rated_Weight_Amount : invoice.Uppskattad_vikt,
        );

        rows.push({
            title: `Fakturerad vikt: ${weight} kg`,
            key: uuidv4(),
        });

        if (vat) {
            totalShipmentCostVat += totalShipmentCost;
        } else {
            totalShipmentCostNoVat += totalShipmentCost;
        }

        const invoiceData = {
            invoice,
            fileName,
            transporter: "Fedex",
            rows,
            totalShipmentCost: Math.round(totalShipmentCost * 100) / 100,
            totalShipmentCostVat: Math.round(totalShipmentCostVat * 100) / 100,
            totalShipmentCostNoVat:
                Math.round(totalShipmentCostNoVat * 100) / 100,
            vat,
        };

        parsedInvoiceRows.push(
            taxInvoice ? parseTax(invoiceData) : parseTransport(invoiceData),
        );
    }

    return parsedInvoiceRows.filter((row) => row.totalShipmentCost > 0);
}

function parseTransport(invoiceData) {
    return {
        fileName: invoiceData.fileName,
        transporter: invoiceData.transporter,
        trackingNumber: getText(invoiceData.invoice.Fraktsedelnummer),
        reference: getText(invoiceData.invoice.Avsndarens_referens_1),
        currentInvoiceNumber: getText(invoiceData.invoice.FedExfakturanummer),
        date: moment(
            getText(invoiceData.invoice.Transportdatum),
            "YYYYMMDD",
        ).format("YYYY-MM-DD"),
        title: getText(invoiceData.invoice.SvcPkgetikett),
        sender:
            getText(invoiceData.invoice.Avsndarens_fretagsnamn) ||
            getText(invoiceData.invoice.Avsndarens_kontaktnamn),
        senderCountry: getText(invoiceData.invoice.Avsndaradressens_landomrde),
        recipient:
            getText(invoiceData.invoice.Mottagarens_fretagsnamn) ||
            getText(invoiceData.invoice.Mottagarens_kontaktnamn),
        recipientCountry: getText(
            invoiceData.invoice.Mottagaradressens_landomrde,
        ),
        totalShipmentCost: invoiceData.totalShipmentCost,
        totalShipmentCostVat: invoiceData.totalShipmentCostVat,
        totalShipmentCostNoVat: invoiceData.totalShipmentCostNoVat,
        vat: invoiceData.vat,
        rows: invoiceData.rows,
    };
}

function parseTax(invoiceData) {
    return {
        fileName: invoiceData.fileName,
        transporter: invoiceData.transporter,
        trackingNumber: getText(invoiceData.invoice.Air_Waybill_Number),
        reference: getText(invoiceData.invoice.Shipper_Reference_1),
        currentInvoiceNumber: getText(invoiceData.invoice.FedEx_Invoice_Number),
        date: moment(getText(invoiceData.invoice.Ship_Date), "YYYYMMDD").format(
            "YYYY-MM-DD",
        ),
        title: getText(invoiceData.invoice.SvcPkg_Label),
        sender:
            getText(invoiceData.invoice.Sender_Company_Name) ||
            getText(invoiceData.invoice.Sender_Contact_Name),
        senderCountry: getText(
            invoiceData.invoice.Sender_Address_CountryTerritory,
        ),
        recipient:
            getText(invoiceData.invoice.Recipient_Company_Name) ||
            getText(invoiceData.invoice.Recipient_Contact_Name),
        recipientCountry: getText(
            invoiceData.invoice.Recipient_Address_CountryTerritory,
        ),
        totalShipmentCost: invoiceData.totalShipmentCost,
        totalShipmentCostVat: invoiceData.totalShipmentCostVat,
        totalShipmentCostNoVat: invoiceData.totalShipmentCostNoVat,
        vat: invoiceData.vat,
        rows: invoiceData.rows,
    };
}

export default {
    parse,
};
