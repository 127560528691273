const countries = {
    en: require("localized-countries")(require("localized-countries/data/en")),
    sv: require("localized-countries")(require("localized-countries/data/sv")),
};

// Add Kosovo in the special country lists as well
const specialCountries = {
    en: {
        "GB-EN": {
            label: "England (Great Britain)",
        },
        "GB-WA": {
            label: "Wales (Great Britain)",
        },
        "GB-NI": {
            label: "Northern Ireland (Great Britain)",
        },
        "GB-SC": {
            label: "Scottland (Great Britain)",
        },
    },
    sv: {
        "GB-EN": {
            label: "England (Storbrittanien)",
        },
        "GB-WA": {
            label: "Wales (Storbrittanien)",
        },
        "GB-NI": {
            label: "Nordirland (Storbrittanien)",
        },
        "GB-SC": {
            label: "Skottland (Storbrittanien)",
        },
    },
};
exports.getCountryName = function (countryCode, language = "sv") {
    if (specialCountries[language] && specialCountries[language][countryCode]) {
        return specialCountries[language][countryCode].label;
    }
    return countries[language].get(countryCode.substr(0, 2));
};

exports.getCountryCode = function (countryName) {
    const countryCode = countries.en.array().find((country) => {
        return countryName === country.label;
    }).code;

    if (countryCode) {
        return countryCode;
    } else {
        let countryCode = null;
        Object.keys(specialCountries.en).find((key) => {
            countryCode = key;
            return specialCountries.en[key].label === countryName;
        });
        return countryCode;
    }
};

exports.countryPrefixLookup = {
    AF: "+93",
    AL: "+355",
    DZ: "+213",
    AS: "+1-684",
    AD: "+376",
    AO: "+244",
    AI: "+1-264",
    AQ: "+672",
    AG: "+1-268",
    AR: "+54",
    AM: "+374",
    AW: "+297",
    AU: "+61",
    AT: "+43",
    AZ: "+994",
    BS: "+1-242",
    BH: "+973",
    BD: "+880",
    BB: "+1-246",
    BY: "+375",
    BE: "+32",
    BZ: "+501",
    BJ: "+229",
    BM: "+1-441",
    BT: "+975",
    BO: "+591",
    BA: "+387",
    BW: "+267",
    BR: "+55",
    BN: "+673",
    BG: "+359",
    BF: "+226",
    BI: "+257",
    KH: "+855",
    CM: "+237",
    CA: "+1",
    CV: "+238",
    KY: "+1-345",
    CF: "+236",
    TD: "+235",
    CL: "+56",
    CN: "+86",
    CX: "+53",
    CC: "+61",
    CO: "+57",
    KM: "+269",
    CD: "+243",
    CG: "+242",
    CK: "+682",
    CR: "+506",
    CI: "+225",
    HR: "+385",
    CU: "+53",
    CY: "+357",
    CZ: "+420",
    DK: "+45",
    DJ: "+253",
    DM: "+1-767",
    DO: "+1-809 and +1-829  ",
    TP: "+670",
    EC: "+593 ",
    EG: "+20",
    SV: "+503",
    GQ: "+240",
    ER: "+291",
    EE: "+372",
    ET: "+251",
    FK: "+500",
    FO: "+298",
    FJ: "+679",
    FI: "+358",
    FR: "+33",
    GF: "+594",
    PF: "+689",
    GA: "+241",
    GM: "+220",
    GE: "+995",
    DE: "+49",
    GH: "+233",
    GI: "+350",
    GR: "+30",
    GL: "+299",
    GD: "+1-473",
    GP: "+590",
    GU: "+1-671",
    GT: "+502",
    GN: "+224",
    GW: "+245",
    GY: "+592",
    HT: "+509",
    HN: "+504",
    HK: "+852",
    HU: "+36",
    IS: "+354",
    IN: "+91",
    ID: "+62",
    IR: "+98",
    IQ: "+964",
    IE: "+353",
    IL: "+972",
    IT: "+39",
    JM: "+1-876",
    JP: "+81",
    JO: "+962",
    KZ: "+7",
    KE: "+254",
    KI: "+686",
    KP: "+850",
    KR: "+82",
    KW: "+965",
    KG: "+996",
    LA: "+856",
    LV: "+371",
    LB: "+961",
    LS: "+266",
    LR: "+231",
    LY: "+218",
    LI: "+423",
    LT: "+370",
    LU: "+352",
    MO: "+853",
    MK: "+389",
    MG: "+261",
    MW: "+265",
    MY: "+60",
    MV: "+960",
    ML: "+223",
    MT: "+356",
    MH: "+692",
    MQ: "+596",
    MR: "+222",
    MU: "+230",
    YT: "+269",
    MX: "+52",
    FM: "+691",
    MD: "+373",
    MC: "+377",
    MN: "+976",
    MS: "+1-664",
    MA: "+212",
    MZ: "+258",
    MM: "+95",
    NA: "+264",
    NR: "+674",
    NP: "+977",
    NL: "+31",
    AN: "+599",
    NC: "+687",
    NZ: "+64",
    NI: "+505",
    NE: "+227",
    NG: "+234",
    NU: "+683",
    NF: "+672",
    MP: "+1-670",
    NO: "+47",
    OM: "+968",
    PK: "+92",
    PW: "+680",
    PS: "+970",
    PA: "+507",
    PG: "+675",
    PY: "+595",
    PE: "+51",
    PH: "+63",
    PL: "+48",
    PT: "+351",
    PR: "+1-787 or +1-939",
    QA: "+974 ",
    RE: "+262",
    RO: "+40",
    RU: "+7",
    RW: "+250",
    SH: "+290",
    KN: "+1-869",
    LC: "+1-758",
    PM: "+508",
    VC: "+1-784",
    WS: "+685",
    SM: "+378",
    ST: "+239",
    SA: "+966",
    SN: "+221",
    SC: "+248",
    SL: "+232",
    SG: "+65",
    SK: "+421",
    SI: "+386",
    SB: "+677",
    SO: "+252",
    ZA: "+27",
    ES: "+34",
    LK: "+94",
    SD: "+249",
    SR: "+597",
    SZ: "+268",
    SE: "+46",
    CH: "+41",
    SY: "+963",
    TW: "+886",
    TJ: "+992",
    TZ: "+255",
    TH: "+66",
    TK: "+690",
    TO: "+676",
    TT: "+1-868",
    TN: "+216",
    TR: "+90",
    TM: "+993",
    TC: "+1-649",
    TV: "+688",
    UG: "+256",
    UA: "+380",
    AE: "+971",
    GB: "+44",
    US: "+1",
    UY: "+598",
    UZ: "+998",
    VU: "+678",
    VA: "+418",
    VE: "+58",
    VN: "+84",
    VI: "+1-284",
    VQ: "+1-340",
    WF: "+681",
    YE: "+967",
    ZM: "+260",
    ZW: "+263",
};

exports.euMemberList = [
    "SE",
    "BE",
    "BG",
    "CY",
    "DK",
    "EE",
    "FI",
    "FR",
    "GR",
    "IE",
    "IT",
    "HR",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "CZ",
    "DE",
    "HU",
    "AT",
];
exports.europeMemberList = [
    "SE",
    "BY",
    "BE",
    "BA",
    "BG",
    "CY",
    "DK",
    "EE",
    "FI",
    "FR",
    "FO",
    "GR",
    "IE",
    "IS",
    "IM",
    "IT",
    "HR",
    "LV",
    "LI",
    "LT",
    "LU",
    "MK",
    "MT",
    "ME",
    "NL",
    "MK",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SM",
    "CH",
    "RS",
    "XK",
    "SK",
    "SI",
    "ES",
    "GB",
    "SJ",
    "SE",
    "CZ",
    "DE",
    "UA",
    "HU",
    "VA",
    "BY",
    "AX",
    "AT",
];
exports.westernEuropeMemberList = [
    "AD",
    "BE",
    "FR",
    "IE",
    "IT",
    "LU",
    "NL",
    "PT",
    "CH",
    "ES",
    "GB",
];
exports.centralEuropeMemberList = [
    "BY",
    "EE",
    "HR",
    "LV",
    "LT",
    "PL",
    "SK",
    "CZ",
    "DE",
    "HU",
    "AT",
];
exports.nordicsMemberList = ["SE", "DK", "FI", "IS", "NO", "AX"];
exports.asiaMemberList = [
    "AF",
    "AM",
    "AZ",
    "BH",
    "BD",
    "BT",
    "BN",
    "KH",
    "CN",
    "CX",
    "CC",
    "IO",
    "GE",
    "HK",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IL",
    "JP",
    "JO",
    "KZ",
    "KW",
    "KG",
    "LA",
    "LB",
    "MO",
    "MY",
    "MV",
    "MN",
    "MM",
    "NP",
    "KP",
    "OM",
    "PK",
    "PS",
    "PH",
    "QA",
    "SA",
    "SG",
    "KR",
    "LK",
    "SY",
    "TW",
    "TJ",
    "TH",
    "TR",
    "TM",
    "AE",
    "UZ",
    "VN",
    "YE",
];
exports.northAmericaMemberList = [
    "AI",
    "AG",
    "AW",
    "BS",
    "BB",
    "BZ",
    "BM",
    "BQ",
    "VG",
    "CA",
    "KY",
    "CR",
    "CU",
    "CW",
    "DM",
    "DO",
    "SV",
    "GL",
    "GD",
    "GP",
    "GT",
    "HT",
    "HN",
    "JM",
    "MQ",
    "MX",
    "PM",
    "MS",
    "CW",
    "KN",
    "NI",
    "PA",
    "PR",
    "BQ",
    "BQ",
    "SX",
    "KN",
    "LC",
    "PM",
    "VC",
    "TT",
    "TC",
    "US",
    "VI",
];
exports.southAmericaMemberList = [
    "AR",
    "BO",
    "BR",
    "CL",
    "CO",
    "EC",
    "FK",
    "GF",
    "GY",
    "GY",
    "PY",
    "PE",
    "SR",
    "UY",
    "VE",
];
exports.africaMemberList = [
    "DZ",
    "AO",
    "SH",
    "BJ",
    "BW",
    "BF",
    "BI",
    "CM",
    "CV",
    "CF",
    "TD",
    "KM",
    "CG",
    "CD",
    "DJ",
    "EG",
    "GQ",
    "ER",
    "SZ",
    "ET",
    "GA",
    "GM",
    "GH",
    "GN",
    "GW",
    "CI",
    "KE",
    "LS",
    "LR",
    "LY",
    "MG",
    "MW",
    "ML",
    "MR",
    "MU",
    "YT",
    "MA",
    "MZ",
    "NA",
    "NE",
    "NG",
    "ST",
    "RE",
    "RW",
    "ST",
    "SN",
    "SC",
    "SL",
    "SO",
    "ZA",
    "SS",
    "SH",
    "SD",
    "SZ",
    "TZ",
    "TG",
    "TN",
    "UG",
    "CD",
    "ZM",
    "TZ",
    "ZW",
];

exports.isMemberOfEU = (countryCode) => {
    return (
        exports.euMemberList.indexOf(countryCode.substr(0, 2).toUpperCase()) >=
        0
    );
};
exports.isMemberOfEurope = (countryCode) => {
    return (
        exports.europeMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    );
};
exports.isMemberOfWesternEurope = (countryCode) => {
    return (
        exports.westernEuropeMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    );
};
exports.isMemberOfCentralEurope = (countryCode) => {
    return (
        exports.centralEuropeMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    );
};
exports.isMemberOfNordics = (countryCode) => {
    return (
        exports.nordicsMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    );
};
exports.getContinent = (countryCode) => {
    if (countryCode.substr(0, 2).toUpperCase() === "AU") {
        return "Australia";
    } else if (
        exports.europeMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    ) {
        return "Europe";
    } else if (
        exports.asiaMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    ) {
        return "Asia";
    } else if (
        exports.northAmericaMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    ) {
        return "North America";
    } else if (
        exports.southAmericaMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    ) {
        return "South America";
    } else if (
        exports.africaMemberList.indexOf(
            countryCode.substr(0, 2).toUpperCase(),
        ) >= 0
    ) {
        return "Africa";
    } else {
        return "Unknown";
    }
};

exports.isAlandPostCode = (postCode, countryCode) => {
    const alandPostalCodes = [
        "22920",
        "22270",
        "22830",
        "22220",
        "22930",
        "22710",
        "22340",
        "22410",
        "22411",
        "22130",
        "22240",
        "22150",
        "22950",
        "22520",
        "22820",
        "22730",
        "22840",
        "22610",
        "22630",
        "22100",
        "22101",
        "22110",
        "22111",
        "22120",
        "22140",
        "22160",
        "22310",
        "22430",
        "22810",
        "22720",
        "22530",
        "22330",
        "22910",
        "22550",
        "22940",
        "22320",
    ];

    if (countryCode === "FI" && alandPostalCodes.includes(postCode)) {
        return true;
    }
    return false;
};
