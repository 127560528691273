import history from "components/Application/History";
import Loader from "components/Loader/Loader";
import SupportWidget from "components/SupportWidget";
import Context from "context/Global";
import AdministrationLayout from "layouts/Administration";
import ConnectLayout from "layouts/Connect";
import LoggedInLayout from "layouts/LoggedIn";
import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import Api from "services/Api/Api";
import { io } from "socket.io-client";
import ViewAccount from "views/Account/View";
import AddonsCreateZendr from "views/Extensions/Create/Zendr";
import ExtensionLandingPage from "views/Extensions/ExtensionLandingPage";
import CreateExtensionPage from "../../views/Extensions/CreateExtensionPage";
import ExtensionDetails from "../../views/Extensions/ExtensionDetails";
import CreateFortnoxExtension from "views/Extensions/Create/Fortnox";
import AddonsCreateBusinessCentral from "views/Extensions/Create/BusinessCentral";
import AddonsCreateLogTrade from "views/Extensions/Create/LogTrade";
import CreateWoocommerceExtension from "views/Extensions/Create/WooCommerce";
import AddonsCreateMagento from "views/Extensions/Create/Magento";
import CreateShopifyExtension from "views/Extensions/Create/Shopify";
import AddonsCreateVismaAdministration from "views/Extensions/Create/VismaAdministration";
import AddonsCreateJoomlaVirtueMart from "views/Extensions/Create/JoomlaVirtueMart";
import AddonsCreateCustom from "views/Extensions/Create/Custom";
import AddonsCreatePyramid from "views/Extensions/Create/Pyramid";
import AddonsCreateMonitorG5 from "views/Extensions/Create/MonitorG5";
import AdminAddons from "views/Admin/Addons";
import AdminAddonCreatePlan from "views/Admin/Addons/CreatePlan";
import AdminViewBrand from "views/Admin/Brand";
import AdminBrands from "views/Admin/Brands";
import AdminCustomerAddUser from "views/Admin/Customers/AddUser";
import AdminEditCustomerContacts from "views/Admin/Customers/Contacts";
import CreateCustomer from "views/Admin/Customers/Create";
import AdminEditCustomer from "views/Admin/Customers/Edit";
import CustomerList from "views/Admin/Customers/List";
import AdminViewCustomer from "views/Admin/Customers/View";
import AdminDashboard from "views/Admin/Dashboard";
import SalesDashboard from "views/Admin/SalesDashboard";
import AdminCreateInvoices from "views/Admin/Invoices/Create";
import AdminListInvoices from "views/Admin/Invoices/List";
import AdminListRemainingInvoices from "views/Admin/Invoices/Remaining";
import AdminCreateInvoices2 from "views/Admin/Invoices2/Create";
import AdminMessages from "views/Admin/Messages";
import AdminMessagesEdit from "views/Admin/Messages/Edit";
import AdminMessagesTemplates from "views/Admin/Messages/Templates";
import AdminOrders from "views/Admin/Orders";
import AdminSales from "views/Admin/Sales";
import AdminSalesTurnover from "views/Admin/Sales/Turnover";
import AdminStatistics from "views/Admin/Statistics";
import AdminSystem from "views/Admin/System";
import AdminSystemProducts from "views/Admin/System/Products";
import AdminUserProfile from "views/Admin/Users/Profile";
import AdminReceiverPays from "views/Admin/ReceiverPays";
import ConnectFortnox from "views/Connect/Fortnox";
import ConnectBusinessCentral from "views/Connect/BusinessCentral";
import Contacts from "views/Contacts";
import CustomerAddUser from "views/Customers/AddUser";
import EditCustomer from "views/Customers/Edit";
import CustomerInvoices from "views/Customers/Invoices";
import CreateQuotation from "views/Drafts/Create";
import Drafts from "views/Drafts/List";
import ViewInvoice from "views/Invoices/View";
import Login from "views/Login/Login";
import Logout from "views/Logout/Logout";
import CreateOrder from "views/Orders/Create";
import OrderCustoms from "views/Orders/Customs";
import CreateCustoms from "views/Orders/Customs/Create";
import CreateCustomsDeclaration from "views/Orders/Customs/Declaration/Create";
import ImportedOrders from "views/Orders/Imported";
import MyOrders from "views/Orders/List";
import OrderPickup from "views/Orders/Pickup";
import ViewOrder from "views/Orders/View";
import CreatePickup from "views/Pickups/Create";
import CreateDHLPickup from "views/Pickups/Create/DHL";
import CreateFedexPickup from "views/Pickups/Create/Fedex";
import CreateTNTPickup from "views/Pickups/Create/TNT";
import CreateUPSPickup from "views/Pickups/Create/UPS";
import CreatePostNordPickup from "views/Pickups/Create/PostNord";
import CustomerPickups from "views/Pickups/List";
import Prospect from "views/Prospect";
import AdminForwardings from "views/Spedition/Admin/List";
import CreateSpedition from "views/Spedition/Create";
import EditSpedition from "views/Spedition/Edit";
import ViewSpedition from "views/Spedition/View";
import Support from "views/Support";
import Tickets from "views/Tickets";
import CreateTicket from "views/Tickets/Create";
import ViewTicket from "views/Tickets/View";
import BatchPage from "views/Batch";
import CreateQuickButikExtension from "views/Extensions/Create/QuickButik";
import CreateStarwebExtension from "views/Extensions/Create/Starweb";
import ForgotPassword from "views/Login/forgotPassword";
import ResetPassword from "views/Login/resetPassword";
import TrackingView from "views/Tracking/TrackingView";
import SalesCustomers from "views/Admin/Customers/SalesCustomers";
import MissedPickups from "views/Admin/MissedPickups/MissedPickups";
import CreateBringPickup from "views/Pickups/Create/Bring/CreateBringPickup";
import CreateOnGoingWmsExtension from "views/Extensions/Create/OngoingWms";

require("dotenv").config();
// moment.updateLocale('sv', {
// 	lastDay : '[Yesterday]',
// 	sameDay : '[Today]',
// 	nextDay : '[Tomorrow]',
// 	lastWeek : '[last] dddd',
// 	nextWeek : '[På] dddd',
// 	sameElse : 'L'
// })

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };

        this.versionUpdateLoopRef = React.createRef();
    }

    componentDidMount() {
        Api.authenticate()
            .then(async (user) => {
                if (!user) {
                    return;
                }
                this.context.setUser(user);

                if (process.env.REACT_APP_CHAT_SOCKET_URL) {
                    const socket = io(process.env.REACT_APP_CHAT_SOCKET_URL, {
                        auth: {
                            token: Api.getAuthenticationToken(),
                        },
                    });

                    this.context.setSocket(socket);

                    socket.on("customerNotificationCount", (count) => {
                        this.context.setTicketNotificationCount(count);
                    });
                    if (
                        this.context.user.brand &&
                        this.context.user.role === "SUPPORT"
                    ) {
                        socket.on("refreshSupportNotificationCount", () => {
                            socket.emit(
                                "getSupportNotificationCount",
                                (count) => {
                                    this.context.setSupportNotificationCount(
                                        count,
                                    );
                                },
                            );
                        });
                        socket.emit("getSupportNotificationCount", (count) => {
                            this.context.setSupportNotificationCount(count);
                        });
                    }

                    return () => {
                        socket.close();
                        this.context.setSocket(null);
                    };
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        if (this.state.loading) {
            return <Loader />;
        }
        if (this.context.user?.id) {
            return (
                <Router history={history}>
                    <Route
                        render={({ history }) => {
                            let Layout;
                            let startUrl;
                            if (
                                history.location.pathname.indexOf("/admin/") ===
                                    0 &&
                                (this.context.user.administrator ||
                                    (this.context.user.brand &&
                                        this.context.user.brand.id))
                            ) {
                                Layout = AdministrationLayout;

                                if (
                                    this.context.user.role === "CLIENT_MANAGER"
                                ) {
                                    startUrl = "/admin/sales";
                                } else if (
                                    this.context.user.role === "SUPPORT"
                                ) {
                                    startUrl = "/admin/support";
                                } else {
                                    startUrl = "/admin/dashboard";
                                }
                            } else if (
                                history.location.pathname.indexOf(
                                    "/connect",
                                ) === 0
                            ) {
                                Layout = ConnectLayout;
                                startUrl = "/";
                            } else {
                                Layout = LoggedInLayout;
                                startUrl = "/create";
                            }
                            return (
                                <Layout history={history}>
                                    {this.context.user.role === "SUPPORT" && (
                                        <SupportWidget />
                                    )}
                                    <Switch>
                                        <Route
                                            exact
                                            path="/create"
                                            component={CreateQuotation}
                                        />
                                        <Route
                                            exact
                                            path="/batch"
                                            component={BatchPage}
                                        />
                                        <Route
                                            exact
                                            path="/create/:draftId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <CreateQuotation
                                                        history={history}
                                                        draftId={
                                                            view.match.params
                                                                .draftId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/create/imported/:importedOrderId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <CreateQuotation
                                                        history={history}
                                                        importedOrderId={
                                                            view.match.params
                                                                .importedOrderId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/create/order/:batchOrderId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <CreateQuotation
                                                        history={history}
                                                        orderId={
                                                            view.match.params
                                                                .batchOrderId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/drafts"
                                            component={Drafts}
                                        />
                                        <Route
                                            exact
                                            path="/orders/create"
                                            component={CreateOrder}
                                        />
                                        <Route
                                            exact
                                            path="/orders/imported"
                                            component={ImportedOrders}
                                        />
                                        <Route
                                            exact
                                            path="/orders/customs"
                                            component={OrderCustoms}
                                        />
                                        <Route
                                            exact
                                            path="/orders/customs/create"
                                            component={CreateCustoms}
                                        />
                                        <Route
                                            exact
                                            path="/orders/customs/declaration/create"
                                            component={CreateCustomsDeclaration}
                                        />
                                        <Route
                                            exact
                                            path="/orders/:orderId(\d+)"
                                            component={ViewOrder}
                                        />
                                        <Route
                                            exact
                                            path="/orders/:orderId(\d+)/pickup"
                                            component={OrderPickup}
                                        />
                                        <Route
                                            exact
                                            path="/orders"
                                            component={MyOrders}
                                        />
                                        <Route
                                            exact
                                            path="/orders/details/:orderId(\d+)"
                                            component={MyOrders}
                                        />
                                        <Route
                                            exact
                                            path="/spedition"
                                            component={CreateSpedition}
                                        />
                                        <Route
                                            exact
                                            path="/spedition/:forwardingId"
                                            render={(view) => {
                                                return (
                                                    <ViewSpedition
                                                        history={history}
                                                        forwardingId={
                                                            view.match.params
                                                                .forwardingId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/spedition/:forwardingId"
                                            render={(view) => {
                                                return (
                                                    <ViewSpedition
                                                        history={history}
                                                        forwardingId={
                                                            view.match.params
                                                                .forwardingId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/spedition/:forwardingId/edit"
                                            render={(view) => {
                                                return (
                                                    <EditSpedition
                                                        history={history}
                                                        forwardingId={
                                                            view.match.params
                                                                .forwardingId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/customers/:customerId(\d+)/adduser"
                                            component={CustomerAddUser}
                                        />
                                        <Route
                                            exact
                                            path="/account/users/add"
                                            component={CustomerAddUser}
                                        />
                                        <Route
                                            exact
                                            path="/pickup"
                                            component={CreatePickup}
                                        />
                                        <Route
                                            exact
                                            path="/pickup/dhl"
                                            component={CreateDHLPickup}
                                        />
                                        <Route
                                            exact
                                            path="/pickup/fedex"
                                            component={CreateFedexPickup}
                                        />
                                        <Route
                                            exact
                                            path="/pickup/tnt"
                                            component={CreateTNTPickup}
                                        />
                                        <Route
                                            exact
                                            path="/pickup/ups"
                                            component={CreateUPSPickup}
                                        />
                                        <Route
                                            exact
                                            path="/pickup/postnord"
                                            component={CreatePostNordPickup}
                                        />
                                        <Route
                                            exact
                                            path="/pickup/bring"
                                            component={CreateBringPickup}
                                        />
                                        <Route
                                            exact
                                            path="/pickups"
                                            component={CustomerPickups}
                                        />
                                        <Route
                                            path="/contacts"
                                            component={Contacts}
                                        />
                                        <Route
                                            path="/account"
                                            render={(view) => {
                                                return (
                                                    <ViewAccount
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/invoices/:invoiceId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <ViewInvoice
                                                        history={history}
                                                        invoiceId={
                                                            view.match.params
                                                                .invoiceId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/customers/:customerId(\d+)/edit"
                                            render={(view) => {
                                                return (
                                                    <EditCustomer
                                                        customerId={
                                                            view.match.params
                                                                .customerId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/customers/:customerId(\d+)/adduser"
                                            component={AdminCustomerAddUser}
                                        />
                                        <Route
                                            exact
                                            path="/admin/sales-dashboard"
                                            component={SalesDashboard}
                                        />
                                        <Route
                                            exact
                                            path="/admin/dashboard"
                                            component={AdminDashboard}
                                        />
                                        <Route
                                            exact
                                            path="/admin/missed-pickups"
                                            component={MissedPickups}
                                        />
                                        <Route
                                            exact
                                            path="/admin/sales-customers"
                                            component={SalesCustomers}
                                        />
                                        <Route
                                            exact
                                            path="/admin/messages"
                                            component={AdminMessages}
                                        />
                                        <Route
                                            exact
                                            path="/admin/messages/create"
                                            render={(view) => {
                                                return (
                                                    <AdminMessagesEdit
                                                        messageId={null}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/messages/:messageId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <AdminMessagesEdit
                                                        messageId={parseInt(
                                                            view.match.params
                                                                .messageId,
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/messages/templates"
                                            component={AdminMessagesTemplates}
                                        />
                                        <Route
                                            exact
                                            path="/admin/spedition"
                                            component={AdminForwardings}
                                        />
                                        <Route
                                            exact
                                            path="/admin/sales"
                                            component={AdminSales}
                                        />
                                        <Route
                                            exact
                                            path="/admin/sales/turnover"
                                            component={AdminSalesTurnover}
                                        />
                                        <Route
                                            exact
                                            path="/admin/support"
                                            render={(view) => {
                                                return (
                                                    <Support
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/customers"
                                            component={CustomerList}
                                        />
                                        <Route
                                            exact
                                            path="/admin/receiver-pays"
                                            component={AdminReceiverPays}
                                        />
                                        <Route
                                            exact
                                            path="/admin/customers/:customerId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <AdminViewCustomer
                                                        history={history}
                                                        customerId={
                                                            view.match.params
                                                                .customerId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/customers/:customerId(\d+)/edit"
                                            render={(view) => {
                                                return (
                                                    <AdminEditCustomer
                                                        customerId={
                                                            view.match.params
                                                                .customerId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/customers/:customerId(\d+)/contacts"
                                            render={(view) => {
                                                return (
                                                    <AdminEditCustomerContacts
                                                        customerId={
                                                            view.match.params
                                                                .customerId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/customers/create"
                                            component={CreateCustomer}
                                        />
                                        <Route
                                            exact
                                            path="/admin/orders"
                                            component={AdminOrders}
                                        />
                                        <Route
                                            exact
                                            path="/admin/orders/:orderId(\d+)"
                                            component={ViewOrder}
                                        />
                                        <Route
                                            exact
                                            path="/admin/brands"
                                            component={AdminBrands}
                                        />
                                        <Route
                                            exact
                                            path="/admin/brands/:brandId(\d+)"
                                            component={AdminViewBrand}
                                        />
                                        <Route
                                            exact
                                            path="/admin/users/:userId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <AdminUserProfile
                                                        userId={
                                                            view.match.params
                                                                .userId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/customers/:customerId(\d+)/invoices"
                                            render={(view) => {
                                                return (
                                                    <CustomerInvoices
                                                        history={history}
                                                        customerId={
                                                            view.match.params
                                                                .customerId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/invoices/list"
                                            render={(view) => {
                                                return (
                                                    <AdminListInvoices
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/invoices/remaining"
                                            render={(view) => {
                                                return (
                                                    <AdminListRemainingInvoices
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/tickets"
                                            render={(view) => {
                                                return (
                                                    <Tickets
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/tickets/create"
                                            render={(view) => {
                                                return (
                                                    <CreateTicket
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/tickets/create"
                                            render={(view) => {
                                                return (
                                                    <CreateTicket
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/tickets/:ticketId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <ViewTicket
                                                        history={history}
                                                        ticketId={
                                                            view.match.params
                                                                .ticketId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/tickets/:ticketId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <ViewTicket
                                                        history={history}
                                                        ticketId={
                                                            view.match.params
                                                                .ticketId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/addons"
                                            render={(view) => {
                                                return (
                                                    <Addons history={history} />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions"
                                            render={(view) => {
                                                return (
                                                    <ExtensionLandingPage
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create"
                                            render={(view) => {
                                                return (
                                                    <CreateExtensionPage
                                                        history={history}
                                                        extensionId={
                                                            view.match.params
                                                                .extensionId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/:extensionId(\d+)"
                                            render={(view) => {
                                                return (
                                                    <ExtensionDetails
                                                        history={history}
                                                        extensionId={
                                                            view.match.params
                                                                .extensionId
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/fortnox"
                                            render={(view) => {
                                                return (
                                                    <CreateFortnoxExtension
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/businesscentral"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateBusinessCentral
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/quickbutik"
                                            render={(view) => {
                                                return (
                                                    <CreateQuickButikExtension
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/joomlavirtuemart"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateJoomlaVirtueMart
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path="/extensions/create/starweb"
                                            render={(view) => {
                                                return (
                                                    <CreateStarwebExtension
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path="/extensions/create/ongoingwms"
                                            render={(view) => {
                                                return (
                                                    <CreateOnGoingWmsExtension
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/logtrade"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateLogTrade
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/woocommerce"
                                            render={(view) => {
                                                return (
                                                    <CreateWoocommerceExtension
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/magento"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateMagento
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/shopify"
                                            render={(view) => {
                                                return (
                                                    <CreateShopifyExtension
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/vismaadministration"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateVismaAdministration
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/custom"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateCustom
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/zendr"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateZendr
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/monitorg5"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreateMonitorG5
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/extensions/create/pyramid"
                                            render={(view) => {
                                                return (
                                                    <AddonsCreatePyramid
                                                        history={history}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/invoices/create"
                                            render={(view) => {
                                                return <AdminCreateInvoices />;
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/invoices/create2"
                                            render={(view) => {
                                                return <AdminCreateInvoices2 />;
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/admin/system"
                                            component={AdminSystem}
                                        />
                                        <Route
                                            exact
                                            path="/admin/addons"
                                            component={AdminAddons}
                                        />
                                        <Route
                                            exact
                                            path="/admin/statistics"
                                            component={AdminStatistics}
                                        />
                                        <Route
                                            exact
                                            path="/admin/addons/:integrationId/createplan"
                                            component={AdminAddonCreatePlan}
                                        />
                                        <Route
                                            exact
                                            path="/admin/system/products"
                                            component={AdminSystemProducts}
                                        />
                                        <Route
                                            exact
                                            path="/logout"
                                            component={Logout}
                                        />
                                        <Route
                                            exact
                                            path="/connect/fortnox"
                                            component={ConnectFortnox}
                                        />
                                        <Route
                                            exact
                                            path="/login/businesscentral"
                                            component={ConnectBusinessCentral}
                                        />
                                        <Route
                                            exact
                                            path="/tracking/:trackingNo"
                                            component={TrackingView}
                                        />
                                        <Route
                                            render={() => {
                                                return (
                                                    <Redirect to={startUrl} />
                                                );
                                            }}
                                        />
                                    </Switch>
                                </Layout>
                            );
                        }}
                    />
                </Router>
            );

            // return (
            // 	<Router>
            // 		<Route render={({history}) => {
            // 			return <LoggedInLayout history={history}>
            // 				<Switch>
            // 					<Route exact path="/quotations/route" component={QuotationRoute} />
            // 					<Route exact path="/quotations/package" component={QuotationPackage} />
            // 					<Route exact path="/quotations/search" component={QuotationSearch} />
            // 					<Route exact path="/quotations/create" component={CreateQuotation} />
            // 					<Route exact path="/orders/create" component={CreateOrder} />
            // 					<Route exact path="/orders/:orderId(\d+)" component={ViewOrder} />
            // 					<Route exact path="/orders" component={MyOrders} />
            // 					<Route exact path="/orders/details/:orderId(\d+)" component={MyOrders} />
            // 					<Route exact path="/customers/:customerId(\d+)" component={ViewCustomer} />
            // 					<Route exact path="/customers/:customerId(\d+)/adduser" component={CustomerAddUser} />
            // 					<Route exact path="/admin/dashboard" component={Admin} />
            // 					<Route exact path="/admin/customers" component={CustomerList} />
            // 					<Route exact path="/admin/customers/:customerId(\d+)" component={AdminViewCustomer} />
            // 					<Route exact path="/admin/customers/create" component={CreateCustomer} />
            // 					<Route exact path="/admin/orders" component={AdminOrders} />
            // 					<Route exact path="/admin/orders/:orderId(\d+)" component={ViewOrder} />
            // 					<Route exact path="/admin/brands" component={AdminBrands} />
            // 					<Route exact path="/admin/brands/:brandId(\d+)" component={AdminViewBrand} />
            // 					<Route exact path="/admin/orders/details/:orderId(\d+)" component={AdminOrders} />
            // 					<Route exact path="/account" component={ViewAccount} />
            // 					<Route exact path="/logout" component={Logout} />
            // 					<Route render={() => {
            // 						return <Redirect to="/quotations/create" />
            // 					}} />
            // 				</Switch>
            // 			</LoggedInLayout>
            // 		}} />
            // 	</Router>
            // );
        } else {
            return (
                <Router history={history}>
                    <Switch>
                        <Route
                            exact
                            path="/tracking/:trackingNo"
                            component={TrackingView}
                        />
                        <Route
                            exact
                            path="/prospekt"
                            render={() => {
                                return <Prospect />;
                            }}
                        />
                        <Route
                            exact
                            path="/logout"
                            render={() => {
                                return <Redirect to="/" />;
                            }}
                        />
                        <Route
                            exact
                            render={() => {
                                return <ForgotPassword />;
                            }}
                            path="/forgot"
                        />
                        <Route
                            exact
                            render={() => {
                                return <ResetPassword />;
                            }}
                            path="/reset"
                        />
                        <Route
                            exact
                            render={() => {
                                return <ResetPassword admin={true} />;
                            }}
                            path="/reset/admin"
                        />
                        <Route component={Login} />
                    </Switch>
                </Router>
            );
        }
    }
}

App.contextType = Context;

export default App;
