function BusinessCentralStepFive({ configuration }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 5 -{" "}
                <span className="text-lg font-medium text-accent">
                    Bekräfta och Slutför
                </span>
            </h3>
            <div className="flex flex-row justify-between items-start space-x-8">
                <div className="w-3/5 flex flex-col space-y-8">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Granska dina inställningar nedan. Om du är nöjd med
                            dina val, klicka på "Slutför" för att bekräfta och
                            spara integrationen.
                        </li>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <label className="block font-medium">
                                Klient-ID
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.clientId}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">Miljö</label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.environment}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Valt Företag
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.companyName}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Status för hämtning
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.fetchStatus === "Draft"
                                    ? "Öppen"
                                    : "Släppt"}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Startdatum för hämtning
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.initialFetchDate}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Leveranskoder
                            </label>
                            <div className="p-2 rounded-md bg-gray-100 space-y-1">
                                {configuration.deliveryCodes.map(
                                    (deliveryCode, index) => (
                                        <p key={index} className="h-8">
                                            {deliveryCode}
                                        </p>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessCentralStepFive;
