import { match } from "helpers/Match";

export const getTransporterLogo = (name) => {
    switch (name?.toLowerCase()) {
        case "tnt leman":
            return "tnt.svg";
        case "begoma":
            return "begoma.png";
        case "dhl express":
            return "dhl-express.png";
        case "zendr":
            return "zendr.png";
        default:
            return `${name?.toLowerCase()}.svg`;
    }
};

export const getIntegrationImage = (integrationType) => {
    // Normalize the input, insert underscore between camelCase words and convert to uppercase
    const normalizedType = integrationType
        .replace(/([a-z])([A-Z])/g, "$1_$2")
        .toUpperCase();

    return match(normalizedType, {
        ZENDR: () => "../logos/zendr-logo-hallon.svg",
        FORTNOX: () => "fortnox.svg",
        LOG_TRADE: () => "logtrade.png",
        SHOPIFY: () => "shopify.svg",
        WOO_COMMERCE: () => "woocommerce.svg",
        MAGENTO: () => "magento.svg",
        CUSTOM: () => "zendr.png",
        VISMA_ADMINISTRATION: () => "visma-administration.svg",
        BUSINESS_CENTRAL: () => "business-central.png",
        QUICK_BUTIK: () => "quickbutik.jpg",
        STARWEB: () => "starweb.png",
        ONGOING_WMS: () => "ongoing.svg",
        // TODO this will be removed when virtuemart create steps is added
        JOOMLA_VIRTUEMART: () => "virtuemart.svg",
        JOOMLA_VIRTUE_MART: () => "virtuemart.svg",
        MONITOR_G5: () => "monitor.png",
        PYRAMID: () => "pyramid.png",
    });
};
