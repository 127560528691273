function StepNavigation({
    currentStep,
    totalSteps,
    onStepChanged,
    loading,
    isCurrentStepValid,
    onSubmit,
}) {
    const firstStep = 1;
    const lastStep = totalSteps;

    const handleNextClick = () => {
        if (currentStep === lastStep) {
            onSubmit();
        } else {
            onStepChanged(currentStep + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentStep > firstStep) {
            onStepChanged(currentStep - 1);
        }
    };

    return (
        <div className="flex items-center gap-6 pt-20">
            <button
                className="c-button c-button--ghost w-36 h-10"
                onClick={handlePrevClick}
                disabled={currentStep === firstStep || loading}
            >
                Föregående
            </button>

            <button
                className="c-button c-button--raised w-36 h-10"
                onClick={handleNextClick}
                disabled={!isCurrentStepValid || loading}
            >
                {currentStep === lastStep ? "Slutför" : "Nästa"}
            </button>

            <span className="text-accent text-sm">
                (Steg {currentStep} av {totalSteps})
            </span>
        </div>
    );
}

export default StepNavigation;
