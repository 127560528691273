import Context from "context/Global";
import React, { Component } from "react";
import styled from "styled-components/macro";

let Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    > .dimension {
        background: rgba(0, 0, 0, 0.03);
        border-radius: 500px;
        padding: 24px;
        padding-top: 16px;
        display: flex;
        position: relative;
        align-items: center;

        > svg {
            margin-right: 8px;
        }

        > .number {
            font-size: 24px;
            line-height: 24px;
            height: 24px;

            > .unit {
                font-size: 12px;
            }
        }

        > .description {
            position: absolute;
            bottom: 8px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 10px;
        }
    }

    @media (max-width: 700px) {
        > .dimension {
            min-width: 80px;
            padding: 6px 8px 16px 8px;
            justify-content: center;

            > svg {
                width: 12px;
                height: 12px;
            }

            > .number {
                white-space: nowrap;

                font-size: 14px;

                > .unit {
                    font-size: 10px;
                }
            }

            > .description {
                bottom: 4px;
                white-space: nowrap;
            }
        }
    }
`;

class PackageAggregate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: 0,
            volume: 0,
            weight: 0,
            chargeableWeight: 0,
            loadingMeters: 0,
        };
    }

    static getDerivedStateFromProps(props, state) {
        let amount = 0;
        let volume = 0;
        let weight = 0;
        let chargeableWeight = 0;
        let loadingMeters = 0;
        if (props.packages) {
            props.packages.forEach((item) => {
                amount += item.count;
                weight += item.weight * item.count;
                if (item.volume) {
                    volume += item.volume * item.count;
                } else {
                    volume +=
                        ((item.height * item.length * item.width) / 1000000) *
                        item.count;
                }
                chargeableWeight = props.chargeableWeight;
                loadingMeters = props.loadingMeters;
            });
        } else {
            amount = props.amount;
            volume = props.volume;
            weight = props.weight;
            chargeableWeight = props.chargeableWeight;
            loadingMeters = props.loadingMeters;
        }

        return {
            amount: amount,
            volume: volume,
            weight: weight,
            chargeableWeight: chargeableWeight,
            loadingMeters: loadingMeters,
        };
    }

    render() {
        const parseStringToFloat = (string) => {
            const numWeight = Number(string);

            if (isNaN(numWeight)) {
                return "0.00";
            }

            return numWeight.toFixed(2);
        };
        return (
            <Container {...this.props}>
                <div className="dimension">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M22.548 9l.452-2h-5.364l1.364-6h-2l-1.364 6h-5l1.364-6h-2l-1.364 6h-6.184l-.452 2h6.182l-1.364 6h-5.36l-.458 2h5.364l-1.364 6h2l1.364-6h5l-1.364 6h2l1.364-6h6.185l.451-2h-6.182l1.364-6h5.366zm-8.73 6h-5l1.364-6h5l-1.364 6z" />
                    </svg>
                    {parseInt(this.state.amount) || 0}
                    <div className="description">Antal kolli</div>
                </div>
                <div className="dimension">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M12 0l-11 6v12.131l11 5.869 11-5.869v-12.066l-11-6.065zm7.91 6.646l-7.905 4.218-7.872-4.294 7.862-4.289 7.915 4.365zm-16.91 1.584l8 4.363v8.607l-8-4.268v-8.702zm10 12.97v-8.6l8-4.269v8.6l-8 4.269zm6.678-5.315c.007.332-.256.605-.588.612-.332.007-.604-.256-.611-.588-.006-.331.256-.605.588-.612.331-.007.605.256.611.588zm-2.71-1.677c-.332.006-.595.28-.588.611.006.332.279.595.611.588s.594-.28.588-.612c-.007-.331-.279-.594-.611-.587zm-2.132-1.095c-.332.007-.595.281-.588.612.006.332.279.594.611.588.332-.007.594-.28.588-.612-.007-.331-.279-.594-.611-.588zm-9.902 2.183c.332.007.594.281.588.612-.007.332-.279.595-.611.588-.332-.006-.595-.28-.588-.612.005-.331.279-.594.611-.588zm1.487-.5c-.006.332.256.605.588.612s.605-.257.611-.588c.007-.332-.256-.605-.588-.611-.332-.008-.604.255-.611.587zm2.132-1.094c-.006.332.256.605.588.612.332.006.605-.256.611-.588.007-.332-.256-.605-.588-.612-.332-.007-.604.256-.611.588zm3.447-5.749c-.331 0-.6.269-.6.6s.269.6.6.6.6-.269.6-.6-.269-.6-.6-.6zm0-2.225c-.331 0-.6.269-.6.6s.269.6.6.6.6-.269.6-.6-.269-.6-.6-.6zm0-2.031c-.331 0-.6.269-.6.6s.269.6.6.6.6-.269.6-.6-.269-.6-.6-.6z" />
                    </svg>
                    <div className="number">
                        {parseStringToFloat(this.state.volume)}{" "}
                        <span className="unit">
                            m<sup>3</sup>
                        </span>
                    </div>
                    <div className="description">Total volym</div>
                </div>
                {this.props.chargeableWeight && (
                    <div className="dimension">
                        <img
                            src="/images/icons/chargeable-weight-icon.svg"
                            alt="chargeable weight"
                            className="pr-2 pb-1"
                        />
                        <div className="number">
                            {parseStringToFloat(this.state.chargeableWeight)}{" "}
                            <span className="unit">kg</span>
                        </div>
                        <div className="description">
                            Total Fraktdragande vikt
                        </div>
                    </div>
                )}
                {this.props.loadingMeters && (
                    <div className="dimension">
                        <img
                            src="/images/icons/loading-meters-icon.svg"
                            alt="loading meters"
                            className="pr-2 pb-1"
                        />
                        <div className="number">
                            {parseStringToFloat(this.state.loadingMeters)}{" "}
                            <span className="unit">m</span>
                        </div>
                        <div className="description">Total Flakmeter</div>
                    </div>
                )}
                <div className="dimension">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M12 21l-8-9h6v-12h4v12h6l-8 9zm9-1v2h-18v-2h-2v4h22v-4h-2z" />
                    </svg>
                    <div className="number">
                        {parseStringToFloat(this.state.weight)}{" "}
                        <span className="unit">kg</span>
                    </div>
                    <div className="description">Total vikt</div>
                </div>
            </Container>
        );
    }
}

PackageAggregate.contextType = Context;

export default PackageAggregate;
