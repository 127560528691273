import ExtensionImage from "./ExtensionImage";

const ExtensionCard = ({ extension, children }) => {
    return (
        <div className="flex flex-row w-full h-36 items-center mb-4">
            <ExtensionImage type={extension.type} />
            <div className="flex flex-col ml-8">{children}</div>
        </div>
    );
};

export default ExtensionCard;
