import Input from "components/OldInput";
import { getCustomsTermsOptions } from "helpers/CustomsTerms";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Context from "context/Global";
import { useContext } from "react";
import { incoTerms } from "constants/IncoTerms";
import { currencyList } from "constants/currency";
import Api from "services/Api/Api";
import toast from "react-hot-toast";
import { THIRD_PARTY_CARRIERS } from "views/Contacts/constants";
import InformationHover from "components/InformationHover";

const CustomsTerms = () => {
    let Title = styled.div`
        font-size: 24px;
        color: rgba(0, 0, 0, 0.6);
        padding: 16px 0px;
    `;

    const context = useContext(Context);
    var hasFetchedFromAPI = false;

    const carrier = context?.quotation?.service?.name?.toUpperCase();
    const useThirdPartyTemplate = THIRD_PARTY_CARRIERS.includes(carrier);
    const [isThirdPartyPaying, setThirdPartyPaying] = useState(false);
    const [thirdPartyOptions, setThirdPartyOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const customsTermsOptions = getCustomsTermsOptions({
        carrier,
        fromCountryCode: context?.order?.sender?.countryCode,
        toCountryCode: context?.order?.receiver?.countryCode,
    });

    const [customsInformation, setCustomsInformation] = useState(() => {
        const defaultCurrency = "SEK";
        const defaultCustomsValue = "0";

        const availableCurrencies = currencyList.map(
            (currency) => currency.value,
        );
        const availableCustomsTerms = customsTermsOptions.map(
            (term) => term.value,
        );

        const orderCurrency = context.order?.customsInvoice?.currency;
        const orderCustomsValue =
            context.order?.customsInvoice?.customsValue?.toString();
        const orderCustomsTerms = context.order?.customsInvoice?.incoTerms;

        const isValidCurrency = availableCurrencies.includes(orderCurrency);
        const isValidCustomsTerms =
            availableCustomsTerms.includes(orderCustomsTerms);

        return {
            currency: isValidCurrency ? orderCurrency : defaultCurrency,
            customsValue:
                isValidCurrency && orderCustomsValue
                    ? orderCustomsValue
                    : defaultCustomsValue,
            customsTerms: isValidCustomsTerms
                ? orderCustomsTerms
                : customsTermsOptions[0].value,
            thirdPartyContactId: null,
        };
    });

    const fetchThirdPartyContacts = async () => {
        setIsLoading(true);
        try {
            const data = await Api.getThirdPartyContacts({
                countryCode: context.order.receiver.countryCode,
                carrier,
            });
            hasFetchedFromAPI = true;

            const options = data.map((contact) => ({
                value: contact.id,
                title: contact.company,
            }));

            setThirdPartyOptions(options);

            if (options.length > 0) {
                setCustomsInformation((prev) => ({
                    ...prev,
                    thirdPartyContactId: options[0].value.toString(),
                }));
            }
        } catch (error) {
            toast.error(
                "Något gick fel när vi hämtade tredje parts kontakter. Försök igen.",
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (
            useThirdPartyTemplate &&
            !hasFetchedFromAPI &&
            context?.order?.receiver?.countryCode
        ) {
            fetchThirdPartyContacts();
        }
    }, []);

    useEffect(() => {
        if (!isLoading) {
            context.updateOrder({
                information: {
                    ...context.order.information,
                    customsValue: customsInformation?.customsValue.replace(
                        ",",
                        ".",
                    ),
                    customsTerms: customsInformation?.customsTerms,
                    currency: customsInformation?.currency,
                    thirdPartyContactId: isThirdPartyPaying
                        ? customsInformation?.thirdPartyContactId
                        : null,
                },
            });
        }
    }, [
        customsInformation?.customsValue,
        customsInformation?.customsTerms,
        customsInformation?.currency,
        customsInformation?.thirdPartyContactId,
        isThirdPartyPaying,
        isLoading,
    ]);

    const handleCustomsInformationChange = (updatedInfo) => {
        setCustomsInformation((prevInfo) => ({
            ...prevInfo,
            ...updatedInfo,
        }));
    };

    return (
        <div>
            <Title>Tull-villkor</Title>
            <form className="mb-2">
                <Input
                    onChange={handleCustomsInformationChange}
                    value={customsInformation}
                    type="list"
                    object={{
                        customsTerms: {
                            type: "dropdown",
                            title: "Tull-villkor",
                            required: true,
                            options: customsTermsOptions,
                        },
                    }}
                />
            </form>
            {useThirdPartyTemplate && (
                <div className="pl-1 mb-2">
                    <div className="flex flex-row items-center gap-2">
                        <p
                            style={{
                                fontSize: "0.8rem",
                                fontWeight: "bold",
                            }}
                        >
                            Är betalare av tull och moms någon annan än
                            avsändare eller mottagare?
                        </p>
                        <InformationHover>
                            För att använda denna funktion, krävs det att det
                            finns en registrerad tredjepart i adressboken i
                            samma land som mottagaren.
                        </InformationHover>
                    </div>
                    <div className="flex flex-col">
                        <label>
                            <input
                                type="radio"
                                name="customsOptions"
                                className="mr-2"
                                checked={!isThirdPartyPaying}
                                onChange={() => setThirdPartyPaying(false)}
                            />
                            Nej
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="customsOptions"
                                className="mr-2"
                                checked={isThirdPartyPaying}
                                onChange={() => setThirdPartyPaying(true)}
                                disabled={thirdPartyOptions.length === 0}
                            />
                            Ja
                        </label>
                    </div>
                </div>
            )}
            <form>
                <Input
                    onChange={handleCustomsInformationChange}
                    value={customsInformation}
                    type="list"
                    object={{
                        thirdPartyContactId: {
                            type: "dropdown",
                            title: "Betalare av tull och moms",
                            required: true,
                            options: thirdPartyOptions,
                            hidden: !isThirdPartyPaying,
                            disabled: thirdPartyOptions.length === 0,
                        },
                        currency: {
                            type: "dropdown",
                            title: "Valuta för tullvärde",
                            required: true,
                            options: currencyList,
                        },
                        customsValue: {
                            type: "text",
                            title: "Tullvärde",
                            required: true,
                            placeholder: "",
                            maxLength: 35,
                            unit: customsInformation?.currency || "SEK",
                        },
                    }}
                />
            </form>
            {customsInformation?.customsTerms === incoTerms.ddp.code && (
                <p className="text-sm text-left mr-36 pt-3">
                    Tull och moms-kostnader faktureras till avsändaren. OBS! Med
                    DHL Freight går alltid tull och moms till mottagaren. Hos
                    UPS kostar det 197 SEK extra pga administrativ avgift.
                </p>
            )}
        </div>
    );
};

export default CustomsTerms;
