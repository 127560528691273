export const passwordValidation = (password, isAdmin) => {
    let passwordRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})",
    );
    if (isAdmin) {
        passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?!.*\s).{12,}$/;
    }

    return passwordRegex.test(password);
};

export const displayWarningMessage = (admin) => {
    return admin
        ? "Lösenordet måste vara minst 12 tecken långt och innehålla minst en stor bokstav, en liten bokstav, ett nummer och en specialtecken. Lösenordet kan inte innehålla ditt namn."
        : "Lösenordet måste vara minst 10 tecken långt och innehålla minst en stor bokstav, en liten bokstav och ett nummer.";
};
