function MonitorG5StepFour({ configuration }) {
    // Utility function to get the title/label from the value
    const getTitleFromValue = (options, value) => {
        const option = options.find((opt) => opt.value === value);
        return option ? option.title || option.label : value;
    };

    // Use the same options defined in Step Three
    const fetchPeriods = [
        { value: "1day", title: "1 dag" },
        { value: "1week", title: "1 vecka" },
        { value: "2weeks", title: "2 veckor" },
        { value: "3weeks", title: "3 veckor" },
        { value: "1month", title: "1 månad" },
    ];

    const triggerStatus = [
        { value: "1", title: "1. Registrerad" },
        { value: "2", title: "2. Utskriven" },
        { value: "3", title: "3. Leveranstid kan ej bekräftas" },
        { value: "4", title: "4. Pågående plockning" },
        { value: "5", title: "5. Dellevererad" },
        { value: "9", title: "9. Slutlevererad" },
    ];

    const referenceOptions = [
        { value: "orderNumber", label: "Ordernummer" },
        { value: "customerOrderNumber", label: "Kundens Ordernummer" },
        { value: "goodsReferenceName", label: "Godsmott. ref." },
    ];

    const goodsDescriptionOptions = [
        { value: "orderType", label: "Ordertyp" },
        { value: "goodsLabel", label: "Godsmärke" },
        { value: "customText", label: "Egen Godsbeskrivning" },
    ];

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 5 -{" "}
                <span className="text-lg font-medium text-accent">
                    Bekräfta och Slutför
                </span>
            </h3>

            <div className="flex flex-row justify-between items-start space-x-8">
                <div className="w-3/5 flex flex-col space-y-8">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Granska dina inställningar nedan. Om du är nöjd med
                            dina val, klicka på "Slutför" för att bekräfta och
                            spara integrationen.
                        </li>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <label className="block font-medium">
                                API Användarnamn
                            </label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {configuration.username}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">Bas URL</label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {configuration.hostname}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">Port</label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {configuration.port}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Språkkod
                            </label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {configuration.languageCode}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Företagsnummer
                            </label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {configuration.companyNumber}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Status för överföring
                            </label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {getTitleFromValue(
                                    triggerStatus,
                                    configuration.transferTriggerStatus,
                                )}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Period för initial hämtning
                            </label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {getTitleFromValue(
                                    fetchPeriods,
                                    configuration.initialFetchPeriod,
                                )}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Referensalternativ
                            </label>
                            <div className="p-2 rounded-md bg-gray-100">
                                {configuration.reference.map((ref, index) => (
                                    <p key={index}>
                                        {getTitleFromValue(
                                            referenceOptions,
                                            ref,
                                        )}
                                    </p>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Godsbeskrivningsalternativ
                            </label>
                            <div className="p-2 rounded-md bg-gray-100">
                                {configuration.goodsDescription.map(
                                    (desc, index) => (
                                        <p key={index}>
                                            {getTitleFromValue(
                                                goodsDescriptionOptions,
                                                desc,
                                            )}
                                        </p>
                                    ),
                                )}
                            </div>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Anpassad godsbeskrivning
                            </label>
                            <p className="p-2 rounded-md bg-gray-100">
                                {configuration.customGoodsDescription ||
                                    "Ej angiven"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MonitorG5StepFour;
