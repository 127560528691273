import Input from "components/OldInput";
import InputDropdown from "components/Input/Dropdown";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import moment from "moment";
import "moment/locale/sv";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { updateCustomerIntegrationsList } from "../../Extensions/utils/authUtils";

let Container = styled.div`
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
    padding: 32px;
    border-radius: 8px;
    width: 80vw;
    max-width: 600px;

    > .logo {
        width: 100%;
        margin-bottom: 20px;

        > .c-logo {
            width: 100%;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    > .title {
        font-size: 1.5rem;
    }

    > .text {
    }

    > .buttons {
        margin-top: 1rem;
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [data, setData] = useState({
        integrationId: 0,
    });
    const [plans, setPlans] = useState([]);
    const [integrations, setIntegrations] = useState([]);
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [disableActive, setDisableActive] = useState(false);

    useEffect(() => {
        const { error, state } = queryString.parse(props.location.search);
        if (error) {
            switch (error) {
                case "access_denied": {
                    setErrorCode("ACCESS_DENIED");
                    return;
                }
            }
        } else if (state === "createExtension") {
            createIntegration();
        } else {
            loadIntegrations();
        }
    }, []);

    useEffect(() => {
        if (plans?.length) {
            setData({
                type: "Fortnox",
                planId: plans[0].id,
            });
        }
    }, [plans]);

    async function loadIntegrations() {
        setIntegrations(
            (
                await Api.getCustomerIntegrations({
                    customerId: context.user.customer.id,
                })
            ).filter((integration) => {
                return integration.type === "FORTNOX";
            }),
        );

        setPlans(
            await Api.getIntegrationPlans({
                integrationId: 1,
            }),
        );
    }

    async function createIntegration() {
        try {
            setLoading(true);
            const { code } = queryString.parse(props.location.search);

            window.opener.postMessage(
                {
                    type: "FORTNOX_AUTH_CODE",
                    authorizationCode: code,
                },
                window.location.origin,
            );
            window.close();
        } catch (error) {
            setErrorMessage(
                "Vi kunde inte aktivera integrationen med din aktiveringskod. Verifiera att du gjort rätt och testa igen.",
            );
            setLoading(false);
        }
    }

    async function updateIntegration() {
        try {
            setLoading(true);
            const { code, state } = queryString.parse(props.location.search);

            await Api.updateIntegrationAuth({
                integrationId: data.integrationId,
                customerId: context.user.customer.id,
                data: {
                    authorizationCode: code,
                },
            });

            window.close();
            setDone(true);

            updateCustomerIntegrationsList(context, data.integrationId);
        } catch (error) {
            setErrorMessage(
                "Vi kunde inte aktivera integrationen med din aktiveringskod. Verifiera att du gjort rätt och testa igen.",
            );
            setLoading(false);
        }
    }

    if (done) {
        return <Container>Var god stäng fönstret.</Container>;
    }

    if (errorCode) {
        switch (errorCode) {
            case "ACCESS_DENIED": {
                return (
                    <Container>
                        <div className="logo">
                            <div className="c-logo"></div>
                        </div>
                        <div className="title">Nekad åtkomst</div>
                        <div className="text">
                            För att kunna aktivera tillägget måste vi få er
                            tillåtelse att komma åt ordrar och artiklar på ert
                            Fortnox-konto. Stäng detta fönster och aktivera
                            tillägget en gång till. Har ni frågor är det bara
                            att kontakta oss på{" "}
                            <a href={`tel:${context.user.brand.supportEmail}`}>
                                {context.user.brand.supportEmail}
                            </a>{" "}
                            eller{" "}
                            <a
                                href={`tel:${context.user.brand.supportPhoneNumber}`}
                            >
                                {context.user.brand.supportPhoneNumber}
                            </a>
                        </div>
                        <div className="buttons">
                            <button className="c-button c-button--raised">
                                Stäng
                            </button>
                        </div>
                    </Container>
                );
            }
        }
    }

    if (!plans?.length) {
        return <Loader />;
    }

    return (
        <Container>
            <div className="logo">
                <div className="c-logo"></div>
            </div>
            <div className="title">Härligt!</div>
            <div className="text">
                När ni aktiverat tillägget kommer ni kunna hämta
                orderinformation från Fortnox.
            </div>
            <Input
                type="list"
                onChange={setData}
                object={{
                    type: {
                        type: "text",
                        hidden: true,
                        value: "Fortnox",
                    },
                    planId: {
                        type: "text",
                        hidden: true,
                        title: "Prenumerationstyp",
                        value: plans[0].id,
                    },
                    integrationId: {
                        type: InputDropdown,
                        hidden: integrations.length === 0,
                        title: "Integration",
                        value: !!integrations.length
                            ? integrations[0].id
                            : null,
                        options: [
                            {
                                title: "Ny integration",
                                value: null,
                            },
                        ].concat(
                            integrations.map((integration) => {
                                return {
                                    title:
                                        "Integration som skapades " +
                                        moment(integration.created).calendar(),
                                    value: integration.id,
                                };
                            }),
                        ),
                    },
                    // planId: {
                    // 	type: MultiToggle,
                    // 	title: 'Prenumerationstyp',
                    // 	options: plans.map((plan) => {
                    // 		return {
                    // 			title: `${plan.price} SEK / ${plan.paymentInterval}`,
                    // 			value: plan.id
                    // 		}
                    // 	})
                    // }
                }}
            />
            <div className="buttons">
                <button
                    disabled={disableActive}
                    className="c-button c-button--raised"
                    onClick={() => {
                        if (data.integrationId) {
                            setDisableActive(true);
                            updateIntegration().then(() => {
                                setDisableActive(false);
                            });
                        } else {
                            window.close();
                        }
                    }}
                >
                    Återaktivera
                </button>
            </div>
        </Container>
    );
}

export default Component;
