import Input from "components/OldInput";
import InputPostalCode from "components/Input/PostalCode";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { CheckboxInput } from "views/Batch/CheckboxInput";
import toast from "react-hot-toast";
import {
    displayWarningMessage,
    passwordValidation,
} from "utils/passwordValidation";

let Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0 1rem;

    > .wrapper {
        width: 50%;

        > .title {
            font-weight: bold;
            font-size: 1.2rem;
        }
    }
`;

let Form = styled.form`
    > .buttons {
        padding: 16px 0;
        display: flex;
        align-items: center;
        gap: 1rem;

        > .passwordInfo {
            font-size: 0.8rem;
        }
    }
`;

let cutoffTimeOptions = [];

for (var i = 8; i <= 18; i++) {
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":00",
        value: ("0" + i).slice(-2) + ":00",
    });
    cutoffTimeOptions.push({
        title: ("0" + i).slice(-2) + ":30",
        value: ("0" + i).slice(-2) + ":30",
    });
}

function Component(props) {
    const context = useContext(Context);
    const [user, setUser] = useState(null);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [updatingPassword, setUpdatingPassword] = useState(false);
    const [passwordsValid, setPasswordsValid] = useState(false);
    const [passwordInfo, setPasswordInfo] = useState(null);
    const userInputRef = useRef();
    const passwordInputRef = useRef();

    const [customer, setCustomer] = useState(null);
    const [updating, setUpdating] = useState(false);
    const customerInputRef = useRef();
    const settingsInputRef = useRef();

    const [batchNotificationSender, setBatchNotificationSender] = useState(
        context.user.customer.settings.batchNotificationSender ?? true,
    );
    const [batchNotificationReceiver, setBatchNotificationReceiver] = useState(
        context.user.customer.settings.batchNotificationReceiver ?? true,
    );

    useEffect(() => {
        loadUser();
        loadCustomer();
    }, []);

    async function loadCustomer() {
        const customer = await Api.getCustomer({
            customerId: context.user.customer.id,
        });

        setCustomer(customer);
        setBatchNotificationSender(
            customer.settings.batchNotificationSender ?? true,
        );
        setBatchNotificationReceiver(
            customer.settings.batchNotificationReceiver ?? true,
        );
    }

    async function loadUser() {
        setUser(await Api.getCurrentUser());
    }

    async function updateCustomer(ev) {
        ev.preventDefault();
        setUpdating(true);
        try {
            await Api.updateCustomer({
                customerId: context.user.customer.id,
                customer: customerInputRef.current.value(),
                settings: {
                    ...settingsInputRef.current.value(),
                    batchNotificationSender,
                    batchNotificationReceiver,
                },
            });

            context.setUser(await Api.getCurrentUser());
            toast.success("Uppgifterna har sparats");
        } catch (error) {
            toast.success(
                "Kunde inte uppdatera uppfigterna. Försök igen senare.",
            );
        } finally {
            setUpdating(false);
        }
    }

    async function updateUser(ev) {
        ev.preventDefault();
        setUpdating(true);
        await Api.updateUser({
            userId: user.id,
            user: userInputRef.current.value(),
        });
        setUpdating(false);
    }

    async function changePassword() {
        setShowPasswordForm(true);
    }

    async function updatePassword() {
        setUpdatingPassword(true);
        let data = passwordInputRef.current.value();
        try {
            const response = await Api.updatePassword({
                userId: user.id,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword,
            });
            setTimeout(() => {
                toast.success("Lösenordet har uppdaterats");
                setUpdatingPassword(false);
                setShowPasswordForm(false);
                setPasswordInfo(null);
            }, 500);
        } catch (error) {
            if (error?.response?.data?.code === "INVALID_PASSWORD") {
                setPasswordInfo(
                    "Du har angett fel nuvarande lösenord. Försök igen.",
                );
            } else if (error?.response?.data?.message.includes("Password")) {
                setPasswordInfo(
                    displayWarningMessage(context.user.administrator),
                );
            }
            setTimeout(() => {
                setUpdatingPassword(false);
            }, 500);
        }
    }

    function verifyPasswordForm(data) {
        if (
            !data.currentPassword ||
            !data.newPassword ||
            !data.repeatPassword
        ) {
            setPasswordsValid(false);
            setPasswordInfo(null);
            return;
        }

        if (data.newPassword !== data.repeatPassword) {
            setPasswordsValid(false);
            setPasswordInfo(
                data.newPassword.length === data.repeatPassword.length
                    ? "Det repeterade lösenordet är inte samma som det ni skrev i fältet innan."
                    : "Lösenorden matchar inte",
            );
            return;
        }

        if (!passwordValidation(data.newPassword, context.user.administrator)) {
            setPasswordInfo(displayWarningMessage(context.user.administrator));
            return;
        }

        setPasswordsValid(true);
        setPasswordInfo(null);
    }

    return (
        <Container>
            <div className="wrapper">
                <div className="title">Mitt företag</div>
                {customer && (
                    <Form
                        onSubmit={updateCustomer}
                        className={updating ? "is-disabled" : ""}
                    >
                        <Input
                            ref={customerInputRef}
                            value={customer}
                            type="list"
                            object={{
                                name: {
                                    title: "Företagsnamn",
                                    type: "text",
                                    maxLength: 35,
                                    autoComplete: "chrome-off",
                                    required: true,
                                },
                                countryCode: {
                                    title: "Land",
                                    type: "dropdown",
                                    autoComplete: "chrome-off",
                                    disabled: true,
                                    value: "SE",
                                    options: [
                                        {
                                            title: "Sverige",
                                            value: "SE",
                                        },
                                    ],
                                },
                                postalCode: {
                                    type: InputPostalCode,
                                    title: "Postnummer",
                                    autoComplete: "chrome-off",
                                    required: true,
                                    countryCode: "SE",
                                    onPostalTownSelected: (item) => {
                                        customerInputRef.current.set({
                                            postalTown: item.value.postalTown,
                                        });
                                    },
                                },
                                postalTown: {
                                    title: "Postort",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    placeholder: "",
                                    required: true,
                                },
                                addressLine1: {
                                    title: "Adress",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    placeholder: "",
                                    maxLength: 30,
                                    required: true,
                                },
                                addressLine2: {
                                    title: "",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    placeholder: "",
                                    maxLength: 30,
                                    required: false,
                                },
                                addressLine3: {
                                    title: "",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    placeholder: "",
                                    maxLength: 30,
                                    required: false,
                                },
                                dhlPalletExchangeNumber: {
                                    title: "DHL PÖS-nummer",
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    maxLength: 6,
                                    hidden: !context.user.customer.settings
                                        .dhlPalletExchange,
                                },
                                vatNumber: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Tull-id",
                                    required: true,
                                    helpText:
                                        "Momsnummer, VAT number, Tax number, etc.",
                                },
                                contactName: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Kontaktperson",
                                    required: true,
                                },
                                contactEmail: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Kontakt-mail",
                                    required: true,
                                },
                                contactPhoneNumber: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Kontakt-telefonnummer",
                                    required: true,
                                },
                                invoiceEmail: {
                                    type: "text",
                                    autoComplete: "chrome-off",
                                    title: "Faktura-mail",
                                    required: true,
                                },
                            }}
                        />
                        <Input
                            ref={settingsInputRef}
                            value={customer.settings}
                            type="list"
                            object={{
                                dhlCutoffTime: {
                                    title: "Stopptid DHL",
                                    type: "dropdown",
                                    options: cutoffTimeOptions,
                                    autoComplete: "chrome-off",
                                },
                            }}
                        />
                        <div className="flex my-4 items-center">
                            <label
                                htmlFor="batchNotificationSender"
                                className="mr-2"
                            >
                                Bokningsbekräftelse avsändare i batch
                            </label>
                            <CheckboxInput
                                value={batchNotificationSender}
                                setValue={setBatchNotificationSender}
                                name="batchNotificationSender"
                            />
                        </div>

                        <div className="flex my-4 items-center">
                            <label
                                htmlFor="batchNotificationReceiver"
                                className="mr-2"
                            >
                                Bokningsbekräftelse mottagare i batch
                            </label>
                            <CheckboxInput
                                value={batchNotificationReceiver}
                                setValue={setBatchNotificationReceiver}
                                name="batchNotificationReceiver"
                            />
                        </div>
                        <div className="buttons">
                            <button
                                className="button c-button c-button--raised"
                                disabled={!!updating}
                            >
                                Spara
                            </button>
                        </div>
                    </Form>
                )}
            </div>
            <div className="wrapper">
                <div className="title">Min användare</div>
                {user && (
                    <>
                        <Form
                            onSubmit={updateUser}
                            className={updating ? "is-disabled" : ""}
                        >
                            <Input
                                ref={userInputRef}
                                value={user}
                                type="list"
                                object={{
                                    name: {
                                        title: "Namn",
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        required: true,
                                    },
                                    email: {
                                        title: "E-post",
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        required: true,
                                    },
                                    phoneNumber: {
                                        title: "Telefonnummer",
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        required: true,
                                    },
                                }}
                            />
                            <div className="buttons">
                                <button
                                    className="button c-button c-button--raised"
                                    disabled={!!updating}
                                >
                                    Spara
                                </button>
                                {!showPasswordForm && (
                                    <button
                                        className="button c-button c-button--text"
                                        onClick={changePassword}
                                    >
                                        Byt lösenord
                                    </button>
                                )}
                            </div>
                        </Form>
                        <>
                            {showPasswordForm && (
                                <Form>
                                    <Input
                                        ref={passwordInputRef}
                                        value={user}
                                        onChange={verifyPasswordForm}
                                        type="list"
                                        object={{
                                            currentPassword: {
                                                title: "Ditt nuvarande lösenord",
                                                type: "password",
                                                autoComplete: "chrome-off",
                                                required: true,
                                            },
                                            newPassword: {
                                                title: "Ditt nya lösenord",
                                                type: "password",
                                                autoComplete: "chrome-off",
                                                required: true,
                                            },
                                            repeatPassword: {
                                                title: "Repetera ditt nya lösenord",
                                                type: "password",
                                                autoComplete: "chrome-off",
                                                required: true,
                                            },
                                        }}
                                    />
                                    <div className="buttons">
                                        <button
                                            className="button c-button c-button--raised"
                                            disabled={
                                                !!updatingPassword ||
                                                !passwordsValid
                                            }
                                            onClick={updatePassword}
                                        >
                                            Uppdatera lösenord
                                        </button>
                                        {passwordInfo && (
                                            <div className="passwordInfo">
                                                {passwordInfo}
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </>
                    </>
                )}
            </div>
        </Container>
    );
}

export default Component;
