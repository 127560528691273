import HelpTextIcon from "../../../Components/InformationIcon";

function OnGoingWmsStepThree() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 3 -{" "}
                <span className="text-lg font-medium text-accent">
                    Skapa Webhook
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Tryck på "Administration" i den övre huvudmenyn och
                            välj "Webhooks".
                        </li>
                        <div className="flex items-start">
                            <li>Klicka på "Skapa ny webhook".</li>
                            <HelpTextIcon
                                infoText={
                                    '"Vanlig webhook" triggar en order för den specifika godsägaren som API-användaren är skapad för.<br/>Automation-webhook kan triggas för alla godsägare som är kopplade till API-användaren.'
                                }
                            />
                        </div>
                        <li>
                            Välj vilken typ av webhook du vill skapa, t.ex. när
                            "Orderstatus ändrats".
                        </li>
                        <li>
                            I nästa steg i OnGoing väljer du vilken
                            statusförändring order överföringen ska reagera på
                            och konfigurerar klart din webhook.
                        </li>
                    </ul>
                </div>

                <div className="flex flex-row space-x-4 ml-10 mr-10">
                    <img
                        src={`/images/extensions/ongoingwms/webhooks.png`}
                        alt="locate webhook"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                    <img
                        src={`/images/extensions/ongoingwms/webhookoptions.png`}
                        alt="webhook options"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default OnGoingWmsStepThree;
