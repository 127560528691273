import React, { useState } from "react";
import Integrations from "../../../../constants/integrations";
import ExtensionCard from "../../Components/ExtensionCard";
import StepNavigation from "../../Components/StepNavigation";
import toast from "react-hot-toast";
import BusinessCentralStepOne from "./Steps/BusinessCentralStepOne";
import BusinessCentralStepTwo from "./Steps/BusinessCentralStepTwo";
import BusinessCentralStepThree from "./Steps/BusinessCentralStepThree";

function CreateBusinessCentralExtension() {
    const [currentStep, setCurrentStep] = useState(1);

    const [configuration, setConfiguration] = useState({
        type: "OngoingWMS",
        clientId: "",
        environment: "production",
        fetchStatus: "Draft",
        initialFetchDate: new Date().toISOString().split("T")[0],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguration((prevConf) => ({
            ...prevConf,
            [name]: value,
        }));
    };

    const handleLogin = async () => {
        try {
            localStorage.setItem(
                "businessCentral",
                JSON.stringify(configuration),
            );

            const storedData = JSON.parse(
                localStorage.getItem("businessCentral"),
            );

            if (storedData && storedData.clientId === configuration.clientId) {
                window.location.replace("/login/businesscentral");
            } else {
                throw new Error("Failed to store clientId in localStorage.");
            }
        } catch (error) {
            toast.error("Misslyckades med att lagra klient-ID, försök igen.");
        }
    };

    const steps = [
        {
            component: <BusinessCentralStepOne />,
            isValid: () => true,
        },
        {
            component: <BusinessCentralStepTwo />,
            isValid: () => true,
        },
        {
            component: (
                <BusinessCentralStepThree
                    configuration={configuration}
                    handleChange={handleChange}
                    handleLogin={handleLogin}
                />
            ),
            // Redirects to callback URL: "portal/ui/src/views/Connect/BusinessCentral/index.js"
            isValid: () => false,
        },
    ];

    const currentStepComponent = steps[currentStep - 1].component;

    return (
        <div className="min-h-full flex flex-col justify-between ml-20 pt-10">
            <div>
                <ExtensionCard
                    extension={{ type: Integrations.BUSINESS_CENTRAL }}
                >
                    <h1 className="text-2xl font-bold">
                        Lägg till Business Central
                    </h1>
                    <p className="text-base font-medium mt-2">
                        För att kunna aktivera denna integration behöver du
                        följa stegen som beskrivs nedan.
                    </p>
                </ExtensionCard>
            </div>

            <div className="flex-grow flex">
                <div className="max-w w-full pt-10">{currentStepComponent}</div>
            </div>

            <div className="pb-12">
                <StepNavigation
                    currentStep={currentStep}
                    // +2 to display last 2 steps used in callback URL
                    totalSteps={steps.length + 2}
                    onStepChanged={setCurrentStep}
                    isCurrentStepValid={steps[currentStep - 1].isValid()}
                    onSubmit={handleLogin}
                />
            </div>
        </div>
    );
}

export default CreateBusinessCentralExtension;
