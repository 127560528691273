import HelpTextIcon from "../../../Components/InformationIcon";
import { FortnoxTriggerStatuses } from "../index";

function FortnoxStepTwo({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    När ska ordrarna föras över?
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-6 w-1/2">
                    <p>
                        I detta steg väljer du när och hur ordern ska föras över
                        från Fortnox.
                    </p>

                    <div>
                        <div className="flex items-start">
                            <label
                                htmlFor="transferTriggerStatus"
                                className="block font-medium"
                            >
                                Status för överföring
                            </label>
                            <HelpTextIcon infoText="När en orderstatus ändras i Fortnox till den valda statusen nedan så kommer den föras över till Zendr's portal" />
                        </div>
                        <select
                            name="transferTriggerStatus"
                            value={configuration.transferTriggerStatus}
                            onChange={handleChange}
                            className="w-2/3 p-2 h-10 border border-gray-300 rounded-md mt-2"
                            defaultValue=""
                            required
                        >
                            <option value="" disabled>
                                Välj ett alternativ
                            </option>
                            {FortnoxTriggerStatuses.map((status) => (
                                <option key={status.value} value={status.value}>
                                    {status.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <div className="flex items-start">
                            <label
                                htmlFor="requireOrderValue"
                                className="block font-medium"
                            >
                                Kräv ordervärde för att trigga
                            </label>
                            <HelpTextIcon infoText="Om detta är aktiverat kommer endast ordrar med ett ordervärde större än 0 att föras över." />
                        </div>
                        <select
                            name="requireOrderValue"
                            value={configuration.requireOrderValue}
                            onChange={handleChange}
                            className="w-2/3 p-2 h-10 border border-gray-300 rounded-md mt-2"
                            required
                        >
                            <option value="" disabled>
                                Välj ett alternativ
                            </option>
                            <option value="true">Ja</option>
                            <option value="false">Nej</option>
                        </select>
                    </div>

                    <div>
                        <div className="flex items-start">
                            <label
                                htmlFor="includeInvoicedOrders"
                                className="block font-medium"
                            >
                                Inkludera fakturerade ordrar
                            </label>
                            <HelpTextIcon infoText="Om detta är aktiverat inkluderas även fakturerade ordrar." />
                        </div>
                        <select
                            name="includeInvoicedOrders"
                            value={configuration.includeInvoicedOrders}
                            onChange={handleChange}
                            className="w-2/3 p-2 h-10 border border-gray-300 rounded-md mt-2"
                            required
                        >
                            <option value="" disabled>
                                Välj ett alternativ
                            </option>
                            <option value="true">Ja</option>
                            <option value="false">Nej</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FortnoxStepTwo;
