function WoocommerceStepOne() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Inställningar
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>Logga in på ditt WooCommerce-konto.</li>
                        <li>
                            Gå till "Settings" under WooCommerce i sidomenyn.
                        </li>
                        <li>Välj "Advanced" i menyn högst upp.</li>
                        <li>Klicka på fliken "Webhooks".</li>
                        <li>
                            Tryck på "Add webhook" eller "Create a new webhook".
                        </li>
                        <li>
                            När detta är klart, gå vidare till nästa steg nedan.
                        </li>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/woocommerce/settings.png`}
                        alt="Webhooks inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-3xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default WoocommerceStepOne;
