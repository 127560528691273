const HeaderSection = ({ title, description }) => {
    return (
        <div>
            <h1 className="text-4xl font-bold">{title}</h1>
            {description && (
                <p className="font-medium text-base mt-2">{description}</p>
            )}
        </div>
    );
};

export default HeaderSection;
