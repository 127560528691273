import React, { useContext, useEffect, useState } from "react";
import Context from "../../../context/Global";
import { useBusinessCentralAuthorization } from "../../Extensions/hooks/useBusinessCentralAuthorization";
import toast from "react-hot-toast";
import Api from "../../../services/Api/Api";
import IntegrationsTokenLifespans from "../../../constants/integrations/IntegrationsTokenLifespans";
import { clearLoginStorage } from "../../Extensions/Create/BusinessCentral/authConfig";
import BusinessCentralStepOne from "../../Extensions/Create/BusinessCentral/Steps/BusinessCentralStepOne";
import BusinessCentralStepTwo from "../../Extensions/Create/BusinessCentral/Steps/BusinessCentralStepTwo";
import BusinessCentralStepThree from "../../Extensions/Create/BusinessCentral/Steps/BusinessCentralStepThree";
import BusinessCentralStepFour from "../../Extensions/Create/BusinessCentral/Steps/BusinessCentralStepFour";
import BusinessCentralStepFive from "../../Extensions/Create/BusinessCentral/Steps/BusinessCentralStepFive";
import ExtensionCard from "../../Extensions/Components/ExtensionCard";
import Integrations from "../../../constants/integrations";
import StepNavigation from "../../Extensions/Components/StepNavigation";

function ConnectBusinessCentralExtension({ history }) {
    const context = useContext(Context);
    const { handleLoginMicrosoft } = useBusinessCentralAuthorization();
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(4);
    const [configuration, setConfiguration] = useState({
        type: "BusinessCentral",
        environment: "",
        clientId: "",
        accessToken: "",
        companyId: "",
        companyName: "",
        deliveryCodes: [],
        fetchStatus: "",
        initialFetchDate: "",
    });

    useEffect(() => {
        const handleLoginRequest = async () => {
            try {
                const storedBusinessCentral =
                    localStorage.getItem("businessCentral");
                const parsedBusinessCentral = JSON.parse(storedBusinessCentral);
                if (parsedBusinessCentral.clientId) {
                    const login = await handleLoginMicrosoft();

                    if (login.accessToken) {
                        setConfiguration((prev) => ({
                            ...prev,
                            clientId: parsedBusinessCentral.clientId,
                            environment: parsedBusinessCentral.environment,
                            initialFetchDate:
                                parsedBusinessCentral.initialFetchDate,
                            fetchStatus: parsedBusinessCentral.fetchStatus,
                            accessToken: login.accessToken,
                            refreshTokenExpiration:
                                IntegrationsTokenLifespans.BUSINESS_CENTRAL_REFRESH_TOKEN_LIFESPAN,
                        }));
                    }
                } else {
                    toast.error(
                        "Misslyckades med att logga in till Microsoft. Testa att rensa webbläsarens cookies och försök igen. Om problemet kvarstår, vänligen kontakta kundsupport.",
                        { duration: 10000 },
                    );
                    history.push("/extensions/create");
                }
            } catch (error) {
                clearLoginStorage();
                toast.error("Misslyckades med att logga in till Microsoft.");
                history.push("/extensions/create");
            }
        };

        handleLoginRequest();
    }, []);

    const handleChange = (name, value) => {
        setConfiguration((prevConf) => ({
            ...prevConf,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const res = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: configuration,
            });
            window.location.replace(`/extensions/${res.id}`);
        } catch (error) {
            clearLoginStorage();
            toast.error(
                "Misslyckades med att skapa integrationen, försök igen.",
            );
        }
    };

    const steps = [
        {
            component: <BusinessCentralStepOne />,
            isValid: () => true,
        },
        {
            component: <BusinessCentralStepTwo />,
            isValid: () => true,
        },
        {
            component: (
                <BusinessCentralStepThree
                    configuration={configuration}
                    handleChange={handleChange}
                />
            ),
            isValid: () =>
                configuration.clientId.trim() !== "" &&
                configuration.environment.trim() !== "",
        },
        {
            component: (
                <BusinessCentralStepFour
                    configuration={configuration}
                    setConfiguration={setConfiguration}
                    loading={loading}
                    setLoading={setLoading}
                    history={history}
                />
            ),
            isValid: () =>
                configuration.companyId.trim() !== "" &&
                configuration.companyName.trim() !== "",
        },
        {
            component: (
                <BusinessCentralStepFive configuration={configuration} />
            ),
            isValid: () => true,
        },
    ];

    const currentStepComponent = steps[currentStep - 1].component;

    return (
        <div className="min-h-full flex flex-col justify-between ml-20 pt-10">
            <ExtensionCard extension={{ type: Integrations.BUSINESS_CENTRAL }}>
                <h1 className="text-2xl font-bold">
                    Lägg till Business Central
                </h1>
                <p className="text-base font-medium mt-2">
                    För att kunna aktivera denna integration behöver du följa
                    stegen som beskrivs nedan.
                </p>
            </ExtensionCard>
            <div className="flex-grow flex">
                <div className="max-w w-full pt-10">{currentStepComponent}</div>
            </div>
            <div className="pb-12">
                <StepNavigation
                    currentStep={currentStep}
                    totalSteps={steps.length}
                    onStepChanged={setCurrentStep}
                    loading={loading}
                    isCurrentStepValid={steps[currentStep - 1].isValid()}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default ConnectBusinessCentralExtension;
