import React, { useContext, useState } from "react";
import Api from "../../../../services/Api/Api";
import Context from "../../../../context/Global";
import toast from "react-hot-toast";
import { handleDownloadS3 } from "../../../../utils/s3";
import InputCheckbox from "../../../../components/Input/Checkbox";

const IntegrationForm = (props) => {
    const context = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [checkedApprovedPrice, setCheckedApprovedPrice] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const integration = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: {
                    type: "Pyramid",
                },
            });

            props.history.push(`/extensions/${integration.id}`);
        } catch (error) {
            toast.error("Det gick inte att skapa integrationen.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="my-10 ml-10">
            <h2 className="text-2xl font-semibold mb-4">
                Integration med Pyramid
            </h2>
            <div className="text-base mb-5">
                <h3 className="text-xl font-semibold">
                    Funktioner och Fördelar
                </h3>
                <p>
                    Genom att integrera Pyramid mot Zendr Portalen skapas ett
                    smidigare arbetsflöde för att överföra och hantera ordrar.
                    <br />
                    Klicka på "Skicka till Zendr" för att flytta en order till
                    Zendr Portalen.
                    <br />
                    Ordern visas sedan i "Batch"-vyn där transportbokning för en
                    eller flera ordrar kan utföras.
                </p>
                <p className="mt-4">
                    Efter framgångsrik transportbokning returneras data till
                    Pyramid.
                    <br />
                    Information så som boknings-ID, transportör, pris och
                    aktuell transportstatus blir tillgänglig i Pyramid.
                    <br />
                    Statusen uppdateras fortlöpande för att ge dig
                    realtidsinsikt över transportens förlopp.
                </p>
                <p className="mt-4">
                    Nyckelinformationen som du kan dra nytta av i Pyramid:
                </p>
                <ul className="list-inside list-disc mb-6 ml-2">
                    <li>Boknings-ID för att spåra varje transport.</li>
                    <li>
                        Transportören som ger insyn i vem som levererar din
                        order.
                    </li>
                    <li>Priset så att du kan hålla koll på kostnaderna.</li>
                    <li>
                        Transportstatusen som håller dig uppdaterad om
                        leveransens framsteg.
                    </li>
                </ul>
                <h3 className="text-xl font-semibold">Grundkrav Pyramid:</h3>
                <ul className="list-inside list-disc mb-6 ml-2">
                    <li>
                        Integrationen är anpassad för version 4.16 eller senare
                    </li>
                    <li>Användarstudion</li>
                    <li>Konsultstudion</li>
                    <li>Order/Lager/Inköp (rutin 8013,410)</li>
                </ul>
                <h3 className="text-xl font-semibold">
                    För att komma igång med integrationen:
                </h3>
                <ol className="list-decimal list-inside mb-2 space-y-1 ml-2">
                    <li>Ladda ner Pyramid modulen nedanför.</li>
                    <a
                        className="underline cursor-pointer text-blue-600 hover:text-blue-800 visited:text-purple-600 ml-5"
                        onClick={() =>
                            handleDownloadS3({
                                url: `${
                                    process.env.REACT_APP_API_URL
                                }/api/s3/integration?objectKey=${encodeURIComponent(
                                    "pyramid/zendr-pyramid-extension.zip",
                                )}`,
                                desiredFileName: "zendr-pyramid-extension.zip",
                            })
                        }
                    >
                        Ladda ner Pyramid modul
                    </a>
                    <li>
                        Kontakta din Pyramid-partner för hjälp med
                        installationen av tillägget.
                    </li>
                    <li>
                        Aktivera integrationen genom att gå till
                        Zendr-inställningarna i Pyramid och klistra
                        <br className="ml-10" /> in den api nyckeln som
                        genereras när du aktiverar denna integrationen.
                    </li>
                </ol>
            </div>

            <h3 className="text-xl font-semibold">Kostnad</h3>
            <p>
                Integrationen med Pyramid är kostnadsfri de första 30 dagarna,
                <br /> därefter tillkommer en kostnad på 299 kr per månad.
            </p>
            <p className="m-4"></p>
            <div className="w-full flex justify-start items-center mb-4">
                <InputCheckbox onChange={setCheckedApprovedPrice} />
                <label className="mr-2 ml-2 font-bold">
                    Jag godkänner månadsavgiften
                </label>
            </div>

            <button
                className="c-button c-button--raised w-1/5 text-white p-1 rounded"
                onClick={handleSubmit}
                disabled={!checkedApprovedPrice}
            >
                {isLoading ? "Aktiverar..." : "Aktivera"}
            </button>
        </div>
    );
};

export default IntegrationForm;
