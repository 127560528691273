import Input from "components/OldInput";
import axios from "axios";
import Context from "context/Global";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Confirmation from "../../Confirmation";
import { incoTerms } from "../../../../../constants/IncoTerms";
import toast from "react-hot-toast";

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let Card = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    background: #fff;
    padding: 1rem;

    .subtitle {
        font-size: 1rem;
        font-weight: bold;
    }

    .textarea {
        max-width: 400px;
    }

    &.pickup {
        display: flex;
        flex-direction: column;

        > .pickupInfoContainer {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;

            > img {
                height: 30px;
            }
        }

        > .datetime {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 0 1rem;
            width: fit-content;
            padding-bottom: 1rem;
        }
    }

    &.confirm {
        > .summary {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-style: italic;
        }
    }

    > .options {
        > .option {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            > .checkbox {
                padding: 0 2rem 0 1rem;
            }

            > .text {
                > .title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
            }
            > .price {
                margin-left: auto;
                white-space: nowrap;
                padding-left: 2rem;
                text-align: right;
                font-size: 1.6rem;
                font-weight: bold;
            }
        }
    }

    > .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        > .verification {
            display: flex;
            align-items: center;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 16px;
            margin-top: 32px;

            > .service {
                max-height: 40px;
                max-width: 100px;
            }

            > .price {
                font-size: 24px;
                padding-left: 32px;
                font-weight: bold;

                > .loader {
                    height: 20px;
                    width: 20px;
                }

                > .transport {
                    .unit {
                        font-size: 0.6em;
                    }
                }

                > .insurance {
                    font-size: 0.6em;

                    .unit {
                        font-size: 0.6em;
                    }
                }
            }

            > .u-push-left {
                margin-left: auto;
            }
        }

        > .intervalMessage {
            font-size: 0.8em;
            padding: 1em 0;
            color: rgba(250, 140, 0, 1);
            font-weight: bold;
        }

        .pickupDate {
            display: flex;
            align-items: center;

            .suggestion {
                padding-left: 1em;
                color: rgba(220, 140, 0, 1);
            }
        }

        > .message {
            > .text {
                font-weight: bold;
                font-size: 14px;
                color: #900;
            }

            > .details {
                font-size: 12px;
                color: #900;
            }
        }
    }
`;

function Component({ order, service, onChange, bookOrder, receiverPays }) {
    const context = useContext(Context);
    const [price, setPrice] = useState(null);
    const [basePrice, setBasePrice] = useState(null);
    const [pickupDate, setPickupDate] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [notificationSettings, setNotificationSettings] = useState(null);
    const [includePickup, setIncludePickup] = useState(true);
    const [validPickupDate, setValidPickupDate] = useState(false);
    const [availablePickupDates, setAvailablePickupDates] = useState([]);
    const [desiredPickupDate, setDesiredPickupDate] = useState();
    const [loadingNewQuote, setLoadingNewQuote] = useState(false);
    const [servicePoints, setServicePoints] = useState([]);
    const [selectedServicePoint, setSelectedServicePoint] = useState();
    const cancelSearchTokenRef = useRef();
    const cancelSearchRef = useRef();
    const servicePointAvaliableProducts = ["Bring Pickup Parcel"];
    const productIsEligibleForServicePoint =
        servicePointAvaliableProducts.includes(service.productCode) &&
        validatePickupPointCountries(order.sender, order.receiver);
    const [includeServicePoint, setIncludeServicePoint] = useState(
        productIsEligibleForServicePoint,
    );
    const [additionalOptions, setAdditionalOptions] = useState([]);

    const availableAdditionalOptions = context.quotation.additionalServices;

    const toggleAddition = (additionalOption) => {
        setAdditionalOptions((prevAdditionalOptions) => {
            const optionExists = prevAdditionalOptions.some(
                (option) => option.id === additionalOption.id,
            );

            return optionExists
                ? prevAdditionalOptions.filter(
                      (option) => option.id !== additionalOption.id,
                  )
                : [...prevAdditionalOptions, additionalOption];
        });
    };

    useEffect(() => {
        if (basePrice) {
            setPrice({
                ...price,
                total:
                    basePrice.total +
                    additionalOptions.reduce(
                        (acc, option) => acc + option.price,
                        0,
                    ),
                transportCost:
                    basePrice.transportCost +
                    additionalOptions.reduce(
                        (acc, option) => acc + option.price,
                        0,
                    ),
            });
        }
    }, [additionalOptions]);

    useEffect(() => {
        context.setQuotation({
            ...context.quotation,
            price: price,
        });
    }, [price]);

    useEffect(() => {
        if (!servicePoints?.length && productIsEligibleForServicePoint) {
            const order = context.getApiOrderObject();

            Api.getServicePoints({
                destination: order.route.to,
                service: context.quotation.service,
            })
                .then((response) => {
                    if (response) {
                        setServicePoints(response);
                        setSelectedServicePoint(response[0]?.id);
                        setIncludeServicePoint(true);
                    } else {
                        setIncludeServicePoint(false);
                    }
                })
                .catch(() => {
                    toast.error("Kunde inte hämta utlämningsställen.");
                });
        }
    }, []);

    useEffect(() => {
        setAvailablePickupDates(
            [0, 1, 2, 3].map((i) => {
                return {
                    value: moment().add(i, "days").format("YYYY-MM-DD"),
                };
            }),
        );
    }, []);

    useEffect(() => {
        setDesiredPickupDate(
            context.quotation.pickupDate
                ? moment(context.quotation.pickupDate).format("YYYY-MM-DD")
                : availablePickupDates[0].value,
        );
    }, [availablePickupDates]);

    useEffect(() => {
        setValidPickupDate(pickupDate && desiredPickupDate === pickupDate);
    }, [pickupDate, desiredPickupDate]);

    useEffect(() => {
        if (!desiredPickupDate) {
            return;
        }

        let startTime = "08:00";
        if (
            desiredPickupDate === moment().format("YYYY-MM-DD") &&
            startTime < moment().format("HH:mm")
        ) {
            let startTimeObject = moment().add(30, "minutes");
            if (startTimeObject.minute() > 30) {
                startTimeObject.minute(30);
            } else {
                startTimeObject.minute(0);
            }
            startTime = startTimeObject.format("HH:mm");
        }

        setPrice(null);

        let query = context.getApiOrderObject();
        query.service = context.quotation.service;
        query.pickupDate = desiredPickupDate;

        setPrice(null);
        setPickupDate(null);
        setLoadingNewQuote(true);

        if (cancelSearchRef.current) {
            clearTimeout(cancelSearchRef.current);
            cancelSearchRef.current = null;
        }

        cancelSearchRef.current = setTimeout(() => {
            if (cancelSearchTokenRef.current) {
                cancelSearchTokenRef.current.cancel();
            }
            cancelSearchTokenRef.current = axios.CancelToken.source();

            Api.quoteOrder({
                cancelToken: cancelSearchTokenRef.current?.token,
                quote: query,
            })
                .then((response) => {
                    if (desiredPickupDate === response.data.pickupDate) {
                        if (!receiverPays) {
                            setPrice(response.data.price);
                            setBasePrice(response.data.price);
                        }
                        setPickupDate(response.data.pickupDate);
                        setDeliveryDate(response.data.deliveryDate);
                    }
                    setLoadingNewQuote(false);
                })
                .catch((error) => {
                    toast.error("Kunde inte hämta priset.");
                });
        }, 300);
    }, [desiredPickupDate]);

    useEffect(() => {
        const notificationSettings = JSON.parse(
            localStorage.getItem("notificationSettings"),
        );

        if (order) {
            setNotificationSettings([
                {
                    name: order.sender.contact.name,
                    email: order.sender.contact.email,
                    onTender:
                        notificationSettings?.[0]?.onTender === false
                            ? false
                            : true,
                },
                {
                    name: order.receiver.contact.name,
                    email: order.receiver.contact.email,
                    onTender:
                        notificationSettings?.[1]?.onTender === false
                            ? false
                            : true,
                },
            ]);
        }
    }, [order]);

    useEffect(() => {
        localStorage.setItem(
            "notificationSettings",
            JSON.stringify(notificationSettings),
        );
    }, [notificationSettings]);

    const bringServiceExtendedInfo = {
        CashOnDelivery: {
            title: "Postförskott",
            description: "Mottagaren betalar för varan vid leverans.",
        },
        EAdvising: {
            title: "E-avisering",
            description: "Mottagaren får ett SMS när paketet är på väg.",
        },
        FlexDelivery: {
            title: "Flexibel leverans",
            description: "Mottagaren kan välja leveranstid och plats.",
        },
        Insurance: {
            title: "Försäkring",
            description:
                "Försäkring för varor med ett värde upp till 100 000 kr.",
        },
        SaturdayDelivery: {
            title: "Lördagsleverans",
            description: "Leverans på lördag för snabbare service.",
        },
        EveningDelivery: {
            title: "Kvällsleverans",
            description: "Leverans på kvällstid för ökad bekvämlighet.",
        },
        NotificationByLetter: {
            title: "Avisering via brev",
            description: "Mottagaren får ett brev med leveransinformation.",
        },
        FrostFree: {
            title: "Frostfri",
            description: "Särskild hantering för att skydda mot frost.",
        },
        PhoneNotification: {
            title: "Telefonavisering",
            description: "Mottagaren blir uppringd före leverans.",
        },
        DangerousGoods: {
            title: "Farligt gods",
            description: "Specialhantering för farliga ämnen.",
        },
        OxExPress: {
            title: "OxExPress",
            description: "Snabb leverans för brådskande försändelser.",
        },
        DeliveryIndoor: {
            title: "Leverans inomhus",
            description: "Paketet levereras innanför dörren.",
        },
        TempratureControlled: {
            title: "Temperaturkontrollerad",
            description:
                "Leverans med kontrollerad temperatur för känsliga varor.",
        },
        LabelFree: {
            title: "Etikettfri",
            description: "Leverans utan extern fraktsedel.",
        },
        BagOnDoor: {
            title: "Påse på dörren",
            description: "Leverans i en säker påse på dörrhandtaget.",
        },
        Pickup: {
            title: "Upphämtning",
            description: "Kunden hämtar paketet på ett utlämningsställe.",
        },
        SocialControl: {
            title: "Social kontroll",
            description: "Extra kontroll vid leverans för ökad säkerhet.",
        },
        SignatureRequired: {
            title: "Signatur krävs",
            description: "Mottagaren måste signera vid leverans.",
        },
        IdVerification: {
            title: "ID-verifiering",
            description: "Mottagarens identitet verifieras vid leverans.",
        },
    };

    function validatePickupPointCountries(from, to) {
        // Pickup Point is only allowed if the sender is not Norway and the receiver is Sweden or Denmark.
        return (
            from.countryCode !== "NO" &&
            (to.countryCode === "SE" || to.countryCode === "DK")
        );
    }

    function getMessage() {
        if (!validPickupDate && !loadingNewQuote) {
            return "Upphämtningen kan inte ske denna dag. Försök med ett annat datum.";
        }
    }

    function getTimeList(start, end) {
        let [startHour, startMinute] = start.split(":");
        let [endHour, endMinute] = end.split(":");
        let availableTimes = [];
        for (let i = parseInt(startHour); i <= parseInt(endHour); i++) {
            if (i !== parseInt(startHour) || parseInt(startMinute) === 0) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":00",
                });
            }
            if (i !== parseInt(endHour) || parseInt(endMinute) === 30) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":30",
                });
            }
        }
        return availableTimes;
    }

    function getPriceNote() {
        if (order?.customs?.information?.terms === incoTerms.ddp.code) {
            return "Tull och moms-kostnader faktureras till avsändaren.";
        } else {
            return null;
        }
    }

    return (
        <>
            <Card className="pickup">
                <div className="flex flex-row mb-2">
                    <div className="label">Boka upphämtning</div>
                    <Input
                        className="ml-2 mt-1"
                        type="checkbox"
                        value={includePickup}
                        onChange={setIncludePickup}
                    />
                </div>

                {includePickup && (
                    <>
                        <div className="font-bold mb-2">
                            Observera att upphämtning med Bring inte kan
                            avbokas.
                        </div>
                        <div className="datetime">
                            <label>Datum</label>
                            {desiredPickupDate && (
                                <Input
                                    onChange={setDesiredPickupDate}
                                    type="dropdown"
                                    options={availablePickupDates}
                                    value={desiredPickupDate}
                                />
                            )}
                        </div>
                    </>
                )}
            </Card>
            {productIsEligibleForServicePoint && !!servicePoints.length && (
                <Card>
                    <div className={`option servicePoint is-selected`}>
                        <div className="text">
                            <div className="flex flex-row mb-2">
                                <div>Utlämningsställe</div>
                                <Input
                                    className="ml-2 mt-1"
                                    type="checkbox"
                                    value={includeServicePoint}
                                    onChange={setIncludeServicePoint}
                                />
                            </div>

                            {includeServicePoint && (
                                <>
                                    <div className="pb-2 font-bold">
                                        Paketet levereras till ett
                                        utlämningsställe där mottagaren hämtar
                                        ut godset när den vill.
                                    </div>

                                    <Input
                                        type="dropdown"
                                        onChange={setSelectedServicePoint}
                                        options={servicePoints.map(
                                            (servicePoint) => {
                                                return {
                                                    value: servicePoint.id,
                                                    title: servicePoint.title,
                                                };
                                            },
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </Card>
            )}

            {availableAdditionalOptions?.length > 0 && (
                <>
                    <Title>Tillägg</Title>
                    <Card>
                        <div className="options">
                            {availableAdditionalOptions.map((option) => {
                                const additionalServiceDetails =
                                    bringServiceExtendedInfo[
                                        option.displayName
                                    ];

                                return (
                                    <div className="option" key={option.name}>
                                        <div className="checkbox">
                                            <Input
                                                type="checkbox"
                                                onChange={() =>
                                                    toggleAddition(option)
                                                }
                                            />
                                        </div>

                                        <div className="text">
                                            <div className="title">
                                                {additionalServiceDetails.title}
                                            </div>
                                            <div className="description">
                                                {
                                                    additionalServiceDetails.description
                                                }
                                            </div>
                                        </div>
                                        <div className="price">
                                            + {option.price} kr
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Card>
                </>
            )}

            <Title>Bekräfta & beställ</Title>
            <Card className="confirm">
                {validPickupDate && (
                    <div className="summary">
                        Försändelsen hämtas {pickupDate}
                        {deliveryDate && (
                            <>
                                {" "}
                                och estimerad leverans är{" "}
                                {moment(deliveryDate).format("YYYY-MM-DD")}.
                            </>
                        )}
                    </div>
                )}
                <Title>Notiser</Title>
                <Card>
                    <div className="description">
                        Välj vid vilka tillfällen och till vilka e-postadresser
                        ni vill att notiser ska skickas.
                    </div>
                    <div className="notifications">
                        {notificationSettings && (
                            <Input
                                type="table"
                                onChange={setNotificationSettings}
                                value={notificationSettings}
                                object={{
                                    name: {
                                        type: "text",
                                        title: "Namn",
                                    },
                                    email: {
                                        type: "text",
                                        title: "Email",
                                    },
                                    onTender: {
                                        type: "checkbox",
                                        title: "Bokningsbekräftelse",
                                    },
                                }}
                            />
                        )}
                    </div>
                </Card>
                <Confirmation
                    message={getMessage()}
                    price={price}
                    disabled={!validPickupDate}
                    service={context.quotation.service}
                    receiverPays={receiverPays}
                    note={getPriceNote()}
                    bookOrder={() => {
                        bookOrder({
                            pickup: {
                                skipPickupOrder: !includePickup,
                                date: `${moment(pickupDate).format(
                                    "YYYY-MM-DD",
                                )}`,
                                timeInterval: {
                                    // Bring pickup does not have a time interval, but the pickupFlow in the portal requires a value.
                                    start: "00:00",
                                },
                            },
                            receiverPays: receiverPays || null,
                            additionalOptions: additionalOptions?.map(
                                (option) => ({
                                    id: option.id,
                                }),
                            ),
                            notifications: notificationSettings,
                            delivery: {
                                ...(productIsEligibleForServicePoint &&
                                    includeServicePoint && {
                                        servicePoint: selectedServicePoint,
                                    }),
                                date: moment(deliveryDate).format("YYYY-MM-DD"),
                            },
                        });
                    }}
                    proceedWithoutPrice={undefined}
                />
            </Card>
        </>
    );
}

export default Component;
