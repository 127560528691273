import { incoTerms } from "constants/IncoTerms";
import Context from "context/Global";
import { getBegomaDisplayData } from "helpers/Begoma";
import { getDHLDisplayData } from "helpers/DHL";
import { getDHLExpressDisplayData } from "helpers/DHLExpress";
import { getFedexDisplayData } from "helpers/Fedex";
import { getTNTLemanDisplayData } from "helpers/Leman";
import { getTransporterLogo } from "helpers/Logos";
import { getTNTDisplayData } from "helpers/TNT";
import { getUPSDisplayData } from "helpers/UPS";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";

let Container = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    align-items: center;
    background-color: #fff;

    > .service {
        padding: 8px 16px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        text-align: center;
        width: 120px;
        min-width: 120px;

        display: flex;
        flex-direction: column;
        align-items: center;

        > .icon {
            width: 80px;
            min-width: 80px;
            max-height: 40px;
            object-fit: contain;
        }

        > .type {
            text-transform: capitalize;
            font-size: 12px;
        }
    }

    > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        > .details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            white-space: nowrap;

            > .info {
                padding: 0 16px;

                > .price {
                    font-size: 24px;
                    display: inline-block;

                    > .unit {
                        font-size: 12px;
                    }
                }
            }

            > .notes {
                display: flex;
                flex-direction: column;

                > .type {
                    padding: 0 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-style: italic;
                    font-size: 1.2rem;
                    color: #333;
                    word-break: break-all;
                    white-space: break-spaces;
                }

                > .note {
                    padding: 0 16px;
                    font-style: italic;
                    font-size: 0.9rem;
                    color: #333;
                }
            }

            > .helpText {
                padding-left: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                > .text {
                    position: absolute;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    background: #fff;
                    padding: 0 0.4em;
                    border-radius: 0.4em;
                    box-shadow: 0px 1px 8px 5px rgba(0, 0, 0, 0.1);
                    color: #555;
                    font-weight: normal;
                    transition: opacity 0.3s ease;
                    white-space: nowrap;
                    bottom: 100%;
                    pointer-events: none;

                    > hr {
                        margin-top: 2px;
                        margin-bottom: 2px;
                        border: 0;
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        background: #fff;
                        width: 100%;
                    }

                    > table th {
                        font-weight: normal;
                        padding-right: 1em;
                    }

                    > table td {
                        text-align: right;
                    }
                }

                > .icon {
                    width: 12px;
                    height: 12px;
                    padding: 8px 4px;
                    box-sizing: content-box;
                }

                &:hover {
                    > .text {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }

            > .timestamps {
                color: rgba(0, 0, 0, 0.4);
                padding: 0 16px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-left: auto;

                > .timestamp {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 186px;

                    > svg {
                        margin-right: 8px;
                    }
                }

                > .emission {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 128px;
                    color: #0cc90c;
                    font-weight: bold;

                    > svg {
                        margin-right: 8px;
                    }
                }
            }
        }

        > .messages {
            width: 100%;

            > .errors {
                padding: 8px 16px;
                font-size: 12px;
                color: rgba(180, 0, 0, 1);
            }

            > .warnings {
                padding: 8px 16px;
                font-size: 12px;
                color: rgba(250, 130, 0, 1);
            }

            > .information {
                padding: 8px 16px;
                font-size: 12px;
                color: rgba(250, 130, 0, 1);
            }
        }
    }

    > .noQuote {
        font-size: 24px;
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > .title {
            font-size: 12px;
            font-weight: bold;
            color: #900;
        }

        > .message {
            font-size: 12px;
            color: #900;
        }
    }

    > .proceed {
        padding: 8px 16px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: auto;
        display: flex;
        align-items: center;
    }
`;

function Quote({ onSetDap = null, ...props }) {
    let context = useContext(Context);
    const [quote, setQuote] = useState(null);
    const [showStackableMessage, setShowStackableMessage] = useState(false);

    useEffect(() => {
        if (
            quote &&
            isStackablePallets() &&
            ((quote.transporterName.toLowerCase() === "dhl" &&
                quote.originalProductName === "DHL Stycke") ||
                quote.transporterName.toLowerCase() === "tnt")
        ) {
            // If DHL and max pallet height > 125 - do not show message.
            setShowStackableMessage(true);
        } else {
            setShowStackableMessage(false);
        }
    }, [quote]);

    function parseProps(props) {
        let quote = {
            price: props.price ? props.price.total : null,
            transportCost: props.price ? props.price.transportCost : null,
            partnerFee: props.price ? props.price.partnerFee : null,
            currency: props.price ? props.price.unit : null,
            transporterName: props.service.name,
            partner: props.service.partner,
            isHalfPallet: props.quotation.isHalfPallet,
            isQuarterPallet: props.quotation.isQuarterPallet,
            priceWillChange: false,
            productName: null,
            originalProductName: null,
            pickupDate: props.pickupDate,
            deliveryDate: props.deliveryDate,
            proceedWithoutPrice: props.quotation.proceedWithoutPrice,
            environment: props.quotation.environment,
            isExpress: false,
            receiverPays: props.receiverPays,
            forwarding: !!props.forwarding,
        };

        switch (props.service.name.toUpperCase()) {
            case "UPS": {
                let displayData = getUPSDisplayData(props.service.productCode);
                quote.originalProductName = displayData.originalName;
                quote.type = props.service.type;
                if (
                    props.route.from.countryCode === "SE" &&
                    props.route.to.countryCode === "SE"
                ) {
                    quote.isExpress = false;
                    quote.productName = displayData.name.replace(
                        "UPS Express",
                        "UPS Inrikes",
                    );
                } else {
                    quote.isExpress = displayData.isExpress;
                    quote.productName = displayData.name;
                }
                quote.isDeliveryPoint = displayData.isDeliveryPoint;
                break;
            }
            case "TNT": {
                let displayData = getTNTDisplayData(
                    props.service.productType,
                    props.route.from.countryCode,
                    props.route.to.countryCode,
                );
                quote.productName = displayData.name;
                quote.originalProductName = displayData.originalName;
                quote.isExpress = displayData.isExpress;
                quote.isDeliveryPoint = displayData.isDeliveryPoint;
                break;
            }
            case "TNT LEMAN": {
                let displayData = getTNTLemanDisplayData({
                    service: props.service,
                    route: props.route,
                });
                quote.productName = displayData.name;
                quote.originalProductName = displayData.originalName;
                quote.isExpress = displayData.isExpress;
                quote.isDeliveryPoint = displayData.isDeliveryPoint;
                break;
            }
            case "BEGOMA": {
                let displayData = getBegomaDisplayData();
                quote.productName = displayData.name;
                quote.originalProductName = displayData.originalName;
                quote.isExpress = displayData.isExpress;
                quote.isDeliveryPoint = displayData.isDeliveryPoint;
                break;
            }
            case "FEDEX": {
                let displayData = getFedexDisplayData(props.service.type);
                quote.priceWillChange =
                    context.order.information?.dangerousGoods?.active;
                quote.productName = displayData.name;
                quote.originalProductName = displayData.originalName;
                quote.isExpress = displayData.isExpress;
                quote.isDeliveryPoint = displayData.isDeliveryPoint;
                break;
            }
            case "DHL": {
                let displayData = getDHLDisplayData(
                    props.service.productCode,
                    props.service.guarantee,
                );
                quote.productName = displayData.name;
                quote.originalProductName = displayData.originalName;
                quote.isExpress = displayData.isExpress;
                quote.isDeliveryPoint = displayData.isDeliveryPoint;
                break;
            }
            case "DHL EXPRESS": {
                let displayData = getDHLExpressDisplayData(
                    props.service.productCode,
                );
                quote.productName = displayData.name;
                quote.originalProductName = displayData.originalName;
                quote.isExpress = true;
                quote.isDeliveryPoint = displayData.isDeliveryPoint;
                break;
            }
            case "DSV": {
                // let displayData = getDHLDisplayData(props.service.productCode, props.service.guarantee);
                quote.productName = props.service.productCode;
                quote.originalProductName = props.service.productCode;
                quote.isExpress = false;
                quote.isDeliveryPoint = false;
                break;
            }
            case "POSTNORD": {
                quote.productName = props.service.productCode;
                quote.originalProductName = props.service.productCode;
                quote.isExpress = false;
                quote.isDeliveryPoint =
                    props.service.productCode === "PostNord MyPack Collect"
                        ? true
                        : false;
                break;
            }
            case "BRING": {
                quote.productName = props.service.productCode;
                quote.originalProductName = props.service.productCode;
                quote.isExpress = false;
                quote.isDeliveryPoint = false;
                break;
            }
            case "ZENDR": {
                quote.productName = "Zendr Spedition";
                quote.originalProductName = "Zendr Spedition";
                quote.isExpress = false;
                quote.isDeliveryPoint = false;
                break;
            }
            default: {
                break;
            }
        }

        return quote;
    }

    useEffect(() => {
        setQuote(parseProps(props));
    }, []);

    function sendIcon() {
        return (
            <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
            >
                <path
                    fill="currentColor"
                    d="M20.012 18v2h-20v-2h20zm3.973-13.118c.154 1.349-.884 2.615-1.927 3.491-.877.735-9.051 6.099-9.44 6.307-1.756.936-3.332 1.306-4.646 1.32-1.36.014-2.439-.354-3.144-.872l-4.784-3.977 3.742-2.373 4.203 1.445.984-.578-4.973-3.645 3.678-2.124 7.992 1.545c.744-.445 1.482-.9 2.225-1.348 1.049-.623 2.056-1.055 3.387-1.055 1.321 0 2.552.52 2.703 1.864zm-4.341.512c-.419.192-3.179 1.882-3.615 2.144l-8.01-1.55-.418.241 5.288 3.307-4.683 2.876-4.214-1.448-.69.395c.917.729 1.787 1.522 2.751 2.186 1.472.962 4.344.22 5.685-.663.9-.592 7.551-4.961 8.436-5.582.605-.431 1.797-1.414 1.824-2.152l.001-.004c-.644-.287-1.716-.041-2.355.25z"
                />
            </svg>
        );
    }

    function deliveryIcon() {
        return (
            <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
            >
                <path
                    fill="currentColor"
                    d="M24.012 20h-20v-2h20v2zm-2.347-5.217c-.819 1.083-2.444 1.284-3.803 1.2-1.142-.072-10.761-1.822-11.186-1.939-1.917-.533-3.314-1.351-4.276-2.248-.994-.927-1.557-1.902-1.676-2.798l-.724-4.998 3.952.782 2.048 2.763 1.886.386-1.344-4.931 4.667 1.095 4.44 5.393 2.162.51c1.189.272 2.216.653 3.181 1.571.957.911 1.49 2.136.673 3.214zm-3.498-2.622c-.436-.15-3.221-.781-3.717-.892l-4.45-5.409-.682-.164 1.481 4.856-5.756-1.193-2.054-2.773-.772-.19.486 2.299c.403 1.712 2.995 3.155 4.575 3.439 1.06.192 8.89 1.612 9.959 1.773.735.105 2.277.214 2.805-.302l.003-.002c-.268-.652-1.214-1.213-1.878-1.442z"
                />
            </svg>
        );
    }

    function renderPickupDate(date) {
        if (!date) {
            return <div className="timestamp"></div>;
        }
        switch (date) {
            default: {
                return (
                    <div className="timestamp">
                        {sendIcon()}
                        {moment(date).calendar(null, {
                            sameDay: "[Idag]",
                            nextDay: "[Imorgon]",
                            nextWeek: "[På] dddd",
                            sameElse: "LL",
                        })}
                    </div>
                );
            }
        }
    }

    function renderDeliveryDate(date) {
        if (!date) {
            return <div className="timestamp"></div>;
        }

        switch (date) {
            case "INTERVAL_1_3": {
                return (
                    <div className="timestamp">{deliveryIcon()}1-3 dagar</div>
                );
            }
            case "INTERVAL_2_5": {
                return (
                    <div className="timestamp">{deliveryIcon()}2-5 dagar</div>
                );
            }
            case "INTERVAL_3_6": {
                return (
                    <div className="timestamp">{deliveryIcon()}3-6 dagar</div>
                );
            }
            case "INTERVAL_2_7": {
                return (
                    <div className="timestamp">{deliveryIcon()}2-7 dagar</div>
                );
            }

            default: {
                return (
                    <div className="timestamp">
                        {deliveryIcon()}
                        {moment(date).calendar(null, {
                            sameDay: "[Idag]",
                            nextDay: "[Imorgon]",
                            nextWeek: "[På] dddd",
                            sameElse: "LL",
                        })}
                    </div>
                );
            }
        }
    }

    function getPrettyTransporterName(originalProductName) {
        switch (originalProductName) {
            case "LemanTNT Economy Import":
            case "LemanTNT Economy Export":
                return "TNT Economy";
            case "LemanTNT Express Import":
            case "LemanTNT Express Export":
                return "TNT Express";
        }
        return originalProductName;
    }

    function getHelpText() {
        let content = [];

        content.push(
            <div key={0} className="product">
                Försändelsen skickas med{" "}
                {getPrettyTransporterName(quote.originalProductName)}
            </div>,
        );
        if (context.user.brand && context.user.brand.id && quote.price) {
            content.push(<hr key={1} />);
            content.push(
                <table key={2} className="admin">
                    <tbody>
                        <tr>
                            <th>Transportkostnad:</th>
                            <td>
                                {quote.transportCost} {quote.currency}
                            </td>
                        </tr>
                        {!!quote.partnerFee && (
                            <>
                                <tr>
                                    <th>Partnerkostnad:</th>
                                    <td>
                                        {quote.partnerFee} {quote.currency}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Total kostnad:</th>
                                    <td>
                                        {quote.transportCost + quote.partnerFee}{" "}
                                        {quote.currency}
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <th>Transportör:</th>
                            <td>{quote.transporterName}</td>
                        </tr>
                        {quote.partner && (
                            <tr>
                                <th>Partner:</th>
                                <td>{quote.partner}</td>
                            </tr>
                        )}
                    </tbody>
                </table>,
            );
        }

        return content;
    }

    function getNoPriceHelpText() {
        let content = [];

        content.push(
            <div key={0} className="product">
                Försändelsen skickas med {quote.originalProductName}
            </div>,
        );
        content.push(
            <div key={0} className="product">
                Just nu får vi inget pris från transportören.
                <br />
                Ni kan fortsätta bokningen utan pris så får ni reda på priset
                när ni får fakturan.
            </div>,
        );

        return content;
    }

    function getTestClass() {
        return [
            props.service.name,
            props.service.productCode,
            props.service.productName,
            props.service.guarantee,
            props.service.type,
        ]
            .filter((v) => v)
            .join("-")
            .toLowerCase()
            .replace(/[ :]/g, "-");
    }

    if (!quote) {
        return null;
    }

    function isStackablePallets() {
        let response = true;
        context.order?.packages?.forEach((item) => {
            if (!(item.type === "pallet" && item.stackable)) {
                response = false;
            }
        });
        return response;
    }

    const handleOnSelectQuote = () => {
        if (quote.receiverPays?.carrier && quote.receiverPays?.accountNumber) {
            props.quotation.receiverPays = quote.receiverPays;
        }
        context.setQuotation(props.quotation);
        props.history.push(`/orders/customs`);
    };

    return (
        <Container className={getTestClass()}>
            <div className="service">
                <img
                    className="icon"
                    src={`/images/icons/${getTransporterLogo(
                        quote.transporterName,
                    )}`}
                />
                <div className="type">{quote.productName}</div>
            </div>
            <div className="content">
                <div className="details">
                    {quote.forwarding && (
                        <div className="info">
                            <div className="price">
                                Zendr Spedition - Flyg/Sjö/Väg
                            </div>
                            <div className="notes">
                                Zendr Spedition - Flyg/Sjö/Väg
                            </div>
                        </div>
                    )}
                    {!!quote.price ? (
                        <>
                            <div className="info">
                                <div className="price">
                                    {quote.price}{" "}
                                    <span className="unit">
                                        {quote.currency}
                                    </span>
                                </div>
                            </div>
                            <div className="helpText">
                                <div className="text">{getHelpText()}</div>
                                <svg
                                    className="icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                                </svg>
                            </div>
                            <div className="notes">
                                {quote.isExpress && (
                                    <div className="type">Express</div>
                                )}
                                {quote.isDeliveryPoint && (
                                    <div className="type">Utlämningsställe</div>
                                )}
                                {quote.isHalfPallet && (
                                    <div className="type">Halvpall</div>
                                )}
                                {quote.isQuarterPallet && (
                                    <div className="type">Kvartspall</div>
                                )}
                                {quote.productName.includes("Varubrev") && (
                                    <div className="type flex flex-col">
                                        <span>VARUBREV</span>{" "}
                                        <span>(EJ UPPHÄMTNING)</span>
                                    </div>
                                )}

                                {quote.transporterName.toLowerCase() ===
                                    "dhl" &&
                                    context.order?.information?.customsTerms ===
                                        incoTerms.ddp.code && (
                                        <div className="type">
                                            OBS! DDP ej tillgängligt
                                        </div>
                                    )}
                                {showStackableMessage && (
                                    <div className="note">
                                        (baserat på att pallen är staplingsbar)
                                    </div>
                                )}
                                {quote.priceWillChange && (
                                    <div className="note">
                                        Priset kommer justeras i nästa steg vid
                                        val av farligt gods.
                                    </div>
                                )}
                            </div>
                        </>
                    ) : quote.proceedWithoutPrice ? (
                        <>
                            <div className="info">
                                <div className="price">Fortsätt utan pris</div>
                            </div>
                            <div className="helpText">
                                <div className="text">
                                    {getNoPriceHelpText()}
                                </div>
                                <svg
                                    className="icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                                </svg>
                            </div>
                        </>
                    ) : (
                        quote.receiverPays &&
                        props.errorMessages?.length === 0 && (
                            <>
                                <div className="info">
                                    <div className="information">
                                        Frakten betalas av mottagaren
                                    </div>
                                </div>
                            </>
                        )
                    )}
                    <div className="timestamps">
                        {context.user.customer.settings.searchEmissions &&
                            quote.environment && (
                                <div className="emission">
                                    {Math.round(
                                        quote.environment?.emissions?.co2
                                            ?.total * 100,
                                    ) / 100 || "<0.01"}{" "}
                                    {quote.environment?.emissions?.co2?.unit} co
                                    <sup>2</sup>
                                </div>
                            )}
                        {renderPickupDate(quote.pickupDate)}
                        {renderDeliveryDate(quote.deliveryDate)}
                    </div>
                </div>
                <div className="messages">
                    {!quote.proceedWithoutPrice &&
                        props.errorMessages &&
                        props.errorMessages.length > 0 && (
                            <div className="errors">
                                {props.errorMessages.map((message, index) => {
                                    return (
                                        <div key={index} className="message">
                                            {message}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    {!quote.proceedWithoutPrice &&
                        props.warningMessages &&
                        props.warningMessages.length > 0 && (
                            <div className="warnings">
                                {props.warningMessages.map((message, index) => {
                                    return (
                                        <div key={index} className="message">
                                            {message}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    {!quote.proceedWithoutPrice &&
                        props.informationMessages &&
                        props.informationMessages.length > 0 && (
                            <div className="information">
                                {props.informationMessages.map(
                                    (message, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="message"
                                            >
                                                {message}
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        )}
                </div>
            </div>
            <div className="proceed">
                {!!quote.forwarding ? (
                    <button
                        className="c-button c-button--raised"
                        onClick={() => {
                            props.history.push(`/spedition?quote=1`);
                        }}
                    >
                        Begär offert
                    </button>
                ) : (
                    <button
                        className="c-button c-button--raised"
                        disabled={
                            !quote.proceedWithoutPrice &&
                            (!quote.price ||
                                quote.transporterName.toLowerCase() ===
                                    "begoma") &&
                            props.errorMessages?.length > 0
                        }
                        onClick={handleOnSelectQuote}
                    >
                        Välj
                    </button>
                )}
            </div>
        </Container>
    );
}

export default withRouter(Quote);
