import { FortnoxTriggerStatuses } from "../index";

function FortnoxStepFour({ configuration }) {
    const getTitleFromValue = (options, value) => {
        const option = options.find((opt) => opt.value === value);
        return option ? option.title || option.label : value;
    };

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 4 -{" "}
                <span className="text-lg font-medium text-accent">
                    Bekräfta och Slutför
                </span>
            </h3>

            <div className="flex flex-row justify-between items-start space-x-8">
                <div className="w-3/5 flex flex-col space-y-8">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Granska dina inställningar nedan. Om du är nöjd med
                            dina val, klicka på "Slutför" för att aktivera
                            integrationen.
                        </li>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <label className="block font-medium">
                                Leveransadresskälla
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.deliveryOptions ===
                                "CustomerDetails"
                                    ? "Kunduppgifter"
                                    : "Leveransuppgifter"}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Godsbeskrivning
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.packageDescription ===
                                "ArticleNumber"
                                    ? "Artikelnummer"
                                    : "Benämning"}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Referens
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.reference === "YourOrderNumber"
                                    ? "Ert ordernummer"
                                    : configuration.reference ===
                                      "YourReference"
                                    ? "Er referens"
                                    : "Ingen referens"}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Status för överföring
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {getTitleFromValue(
                                    FortnoxTriggerStatuses,
                                    configuration.transferTriggerStatus,
                                )}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Kräv ordervärde
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.requireOrderValue ? "Ja" : "Nej"}
                            </p>
                        </div>
                        <div>
                            <label className="block font-medium">
                                Inkludera fakturerade ordrar
                            </label>
                            <p className="p-2 h-10 rounded-md bg-gray-100">
                                {configuration.includeInvoicedOrders
                                    ? "Ja"
                                    : "Nej"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FortnoxStepFour;
