import React, { useEffect, useState } from "react";
import Input from "../../../../../../components/OldInput";
import InputNumber from "../../../../../../components/Input/Number";
import {
    calculateDsvInsurancePrice,
    determineInsuranceZone,
    calculateMaxInsuranceValue,
    mapCategoryNumberToText,
    DSV_INSURANCE_MULTIPLIER,
    DSV_INSURANCE_CATEGORIES,
} from "helpers/DSV/insurance";
import { convertToSEK } from "constants/currency";

export function InsuranceInput({
    onInsuranceChange,
    basePrice,
    onTermsAccepted,
    termsAccepted,
    setTotalInsuranceCost,
    setInsuranceValid,
    totalInsuranceCost,
    order,
}) {
    const [insuranceValue, setInsuranceValue] = useState(0);
    const [addInsurance, setAddInsurance] = useState(false);
    const [category, setCategory] = useState("1");
    const [insuranceMaxValue, setInsuranceMaxValue] = useState(0);
    const [insuranceValueInSek, setInsuranceValueInSek] = useState(0);

    useEffect(() => {
        setInsuranceValueInSek(
            convertToSEK(insuranceValue, order.information.currency),
        );
    }, [insuranceValue]);

    const insuranceZone = determineInsuranceZone(
        order.sender.countryCode,
        order.receiver.countryCode,
    );
    const insuranceCategories = DSV_INSURANCE_CATEGORIES.map((c) => ({
        title: c.title,
        value: c.categoryNumber,
    }));

    useEffect(() => {
        if (
            addInsurance &&
            insuranceValue <= insuranceMaxValue &&
            termsAccepted
        ) {
            setInsuranceValid(true);
        } else if (!addInsurance) {
            setInsuranceValid(true);
        } else {
            setInsuranceValid(false);
        }
    }, [addInsurance, insuranceValue, insuranceMaxValue, termsAccepted]);

    useEffect(() => {
        if (!addInsurance) {
            setInsuranceValue(0);
            setTotalInsuranceCost(0);
            onInsuranceChange(0);
            onTermsAccepted(false);
        }
    }, [addInsurance]);

    useEffect(() => {
        // This is needed cause our Input component is stupid & cant handle ints
        const categoryAsInt = parseInt(category);

        setInsuranceMaxValue(
            calculateMaxInsuranceValue(
                categoryAsInt,
                insuranceZone,
                basePrice.transportCost,
            ),
        );

        handleInsuranceValueChange(insuranceValue);
    }, [category, insuranceZone, insuranceValue]);

    const handleInsuranceValueChange = (value) => {
        if (!addInsurance) {
            return;
        }
        const insuranceValueInSek = convertToSEK(
            value,
            order.information.currency,
        );

        setInsuranceValue(value);

        // This is needed cause our Input component is stupid & cant handle ints
        const categoryAsInt = parseInt(category);

        const calculatedInsuranceCost = calculateDsvInsurancePrice(
            insuranceValueInSek,
            basePrice.transportCost,
            categoryAsInt,
            insuranceZone,
        );

        const insuranceCostInSekWithProfit = Math.round(
            calculatedInsuranceCost * DSV_INSURANCE_MULTIPLIER,
        );

        setTotalInsuranceCost(insuranceCostInSekWithProfit);
        onInsuranceChange({
            value: insuranceValueInSek,
            currency: "SEK",
            category: mapCategoryNumberToText(categoryAsInt),
            insuranceCost: insuranceCostInSekWithProfit,
            ourInsuranceSurcharge: calculatedInsuranceCost,
            basePrice: basePrice,
        });
    };

    return (
        <div className="w-full flex flex-col align-center">
            <div className="flex items-center gap-3">
                <Input
                    type="checkbox"
                    checked={addInsurance}
                    onChange={setAddInsurance}
                />
                <div className="text-xl font-medium">
                    Jag vill försäkra min transport
                </div>
            </div>
            {addInsurance && (
                <div className="max-w-lg flex flex-col gap-3">
                    <h2 className="text-xl font-bold">Försäkringsvärde</h2>
                    <InputNumber
                        placeholder="0"
                        onChange={handleInsuranceValueChange}
                        min={0}
                        value={insuranceValue}
                        unit={order.information?.currency || "SEK"}
                        autoValidate
                    />
                    {insuranceMaxValue < insuranceValueInSek && (
                        <div className="text-red-500">
                            Försäkringsvärdet får ej överstiga{" "}
                            {insuranceMaxValue} SEK
                        </div>
                    )}

                    <label className="font-bold text-lg">Kategori</label>
                    <Input
                        type="dropdown"
                        onChange={(newData) => setCategory(newData)}
                        label="Kategori"
                        value={category}
                        options={insuranceCategories}
                    />

                    {insuranceValue > 0 &&
                        totalInsuranceCost &&
                        totalInsuranceCost < insuranceMaxValue && (
                            <>
                                <div className="mb-8">
                                    Med ett försäkringsvärde på{" "}
                                    {insuranceValueInSek} SEK kostar
                                    försäkringen
                                    <b> {totalInsuranceCost} SEK</b>.
                                </div>

                                <div className="flex items-center">
                                    <Input
                                        type="checkbox"
                                        checked={termsAccepted}
                                        onChange={(accepted) => {
                                            onTermsAccepted(accepted);
                                        }}
                                    />

                                    <div className="ml-4">
                                        Jag förstår och accepterar{" "}
                                        <a
                                            href="https://www.dsv.com/sv-se/vara-losningar/transportsatt/mervardestjanster/forsakring/varuforsakring"
                                            target="_blank"
                                            className="underline text-blue-500"
                                        >
                                            DSV's villkor vid försäkring
                                        </a>{" "}
                                        samt att försäkringsvärdet inte kan
                                        överstiga tull-värdet.
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            )}
        </div>
    );
}

export default InsuranceInput;
