import HelpTextIcon from "../../../Components/InformationIcon";

function BusinessCentralStepTwo() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Konfiguration Appregistrering
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>Navigera till "API-behörigheter" i sidomenyn.</li>
                        <li>Klicka på "Lägg till en behörighet".</li>
                        <li>
                            Under "Begär API-behörigheter", välj "Dynamics 365
                            Business Central".
                        </li>
                        <div className="flex items-start">
                            <li>
                                Markera alla kryssrutor för både "Delegerade
                                behörigheter" och "Programbehörigheter".
                            </li>
                            <HelpTextIcon
                                infoText={
                                    "'Delegerade behörigheter' krävs för att den inloggade användaren måste godkänna åtkomst.<br/>'Programbehörigheter' krävs för att kontinuerligt hämta orderdata från Business Central."
                                }
                            />
                        </div>
                        <li>
                            Klicka på "Lägg till behörigheter" för båda
                            flikarna.
                        </li>
                        <li>
                            Klicka på "Bevilja administratörsgodkännande" och
                            godkänn behörigheterna.
                        </li>
                        <li>Gå sedan vidare till nästa steg nedan</li>
                    </ul>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={`/images/extensions/businesscentral/permissions.png`}
                        alt="Step one"
                        className="object-contain w-[32rem] h-[32rem] max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default BusinessCentralStepTwo;
