import React, { useContext, useEffect, useState } from "react";
import Integrations from "../../../../constants/integrations";
import ExtensionCard from "../../Components/ExtensionCard";
import OnGoingWmsStepOne from "./Steps/OnGoingWmsStepOne";
import OnGoingWmsStepTwo from "./Steps/OnGoingWmsStepTwo";
import StepNavigation from "../../Components/StepNavigation";
import Api from "../../../../services/Api/Api";
import OnGoingWmsStepThree from "./Steps/OnGoingWmsStepThree";
import OnGoingWmsStepFour from "./Steps/OnGoingWmsStepFour";
import OnGoingWmsStepFive from "./Steps/OnGoingWmsStepFive";
import toast from "react-hot-toast";
import Context from "../../../../context/Global";

function CreateOnGoingWmsExtension(props) {
    const context = useContext(Context);
    const [currentStep, setCurrentStep] = useState(1);

    const [configuration, setConfiguration] = useState({
        type: "OngoingWMS",
        extensionId: "",
        username: "",
        password: "",
        selectedApi: "",
        identifier: "",
        accessToken: "",
        pickupAddress: "",
        includeOrderIdInReference: "",
        selectGoodsSource: "",
    });

    const activateExtension = async () => {
        try {
            const response = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: configuration,
            });

            // In this extension we have saved the identifier as accessToken inside data object, instead of the column "identifier" used for other extensions.
            setConfiguration((prevConf) => ({
                ...prevConf,
                extensionId: response.id,
                accessToken: response.data.accessToken,
            }));

            toast.success("Integrationen aktiverad och API-nyckel genererad!");
        } catch (error) {
            toast.error("Misslyckades med att aktivera integrationen.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguration((prevConf) => ({
            ...prevConf,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            props.history.push(`/extensions/${configuration.extensionId}`);
        } catch (error) {
            toast.error(
                "Misslyckades med att visa den nya integrationen, gå tillbaka till Tillägg fliken och försök igen!",
            );
        }
    };

    const steps = [
        {
            component: (
                <OnGoingWmsStepOne
                    configuration={configuration}
                    handleChange={handleChange}
                />
            ),
            isValid: () =>
                configuration.username.trim() !== "" &&
                configuration.password.trim() !== "" &&
                configuration.identifier.trim() !== "" &&
                configuration.selectedApi.trim() !== "",
        },
        {
            component: (
                <OnGoingWmsStepTwo
                    configuration={configuration}
                    handleChange={handleChange}
                />
            ),
            isValid: () =>
                configuration.pickupAddress.trim() !== "" &&
                configuration.includeOrderIdInReference.trim() !== "" &&
                configuration.selectGoodsSource.trim() !== "",
        },
        {
            component: <OnGoingWmsStepThree />,
            isValid: () => true,
        },
        {
            component: (
                <OnGoingWmsStepFour
                    configuration={configuration}
                    activateExtension={activateExtension}
                />
            ),
            isValid: () => configuration.accessToken.trim() !== "",
        },
        {
            component: <OnGoingWmsStepFive configuration={configuration} />,
            isValid: () =>
                configuration.username.trim() !== "" &&
                configuration.password.trim() !== "" &&
                configuration.selectedApi.trim() !== "" &&
                configuration.pickupAddress.trim() !== "" &&
                configuration.includeOrderIdInReference.trim() !== "" &&
                configuration.selectGoodsSource.trim() !== "",
        },
    ];

    const currentStepComponent = steps[currentStep - 1].component;

    return (
        <div className="min-h-full flex flex-col justify-between ml-20 pt-10">
            <div>
                <ExtensionCard extension={{ type: Integrations.ONGOING_WMS }}>
                    <h1 className="text-2xl font-bold">
                        Lägg till Ongoing WMS
                    </h1>
                    <p className="text-base font-medium mt-2">
                        För att kunna aktivera denna integration behöver du
                        följa stegen som beskrivs nedan.
                    </p>
                </ExtensionCard>
            </div>

            <div className="flex-grow flex">
                <div className="max-w w-full pt-10">{currentStepComponent}</div>
            </div>

            <div className="pb-12">
                <StepNavigation
                    currentStep={currentStep}
                    totalSteps={steps.length}
                    onStepChanged={setCurrentStep}
                    isCurrentStepValid={steps[currentStep - 1].isValid()}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default CreateOnGoingWmsExtension;
