import HelpTextIcon from "../../../Components/InformationIcon";

function QuickButikStepOne({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Inställningar
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>Logga in på ditt Quickbutik-konto.</li>
                        <li>Gå till "Inställningar" i sidomenyn.</li>
                        <li>
                            Scrolla ner till korten för "Webhooks" och "API" och
                            aktivera båda.
                        </li>
                        <li>Öppna dessa i varsin ny flik.</li>
                        <li>
                            När detta är gjort, gå vidare till nästa steg nedan.
                        </li>
                    </ul>
                </div>

                <div className="ml-10 mr-10 space-y-6">
                    <img
                        src={`/images/extensions/quickbutik/settings.png`}
                        alt="Webhooks inställning"
                        className="object-contain w-[32rem] h-[32rem] max-w-3xl"
                    />
                </div>
            </div>
        </div>
    );
}
export default QuickButikStepOne;
