import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { RECIPIENT_TYPE, THIRD_PARTY_TYPE } from "./constants";
import { ContactsView } from "./views/ContactsView";

function Component({ history }) {
    return (
        <div className="flex flex-col">
            <div className="flex flex-row gap-4 px-3 pt-4">
                <Link
                    to="/contacts"
                    className={`item text-2xl ${
                        history.location.pathname === "/contacts"
                            ? "underline"
                            : "text-accent"
                    }`}
                    style={{ textUnderlineOffset: "0.5rem" }}
                >
                    Kontakter
                </Link>
                <Link
                    to="/contacts/third-party"
                    className={`item text-2xl ${
                        history.location.pathname === "/contacts/third-party"
                            ? "underline"
                            : "text-accent"
                    }`}
                    style={{ textUnderlineOffset: "0.5rem" }}
                >
                    Tredje Part
                </Link>
            </div>
            <Switch>
                <Route
                    exact
                    path="/contacts"
                    render={() => {
                        return (
                            <ContactsView
                                key={`contacts_table_${RECIPIENT_TYPE}`}
                                contactType={RECIPIENT_TYPE}
                                contactTitle={"Kontakter"}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path="/contacts/third-party"
                    render={() => {
                        return (
                            <ContactsView
                                key={`contacts_table_${THIRD_PARTY_TYPE}`}
                                contactType={THIRD_PARTY_TYPE}
                                contactTitle={"Tredje Part"}
                            />
                        );
                    }}
                />
            </Switch>
        </div>
    );
}

export default Component;
