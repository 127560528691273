import React, { useEffect, useState } from "react";
import Api from "services/Api/Api";
import Loader from "components/Loader/Loader";
import InputField from "components/OldInput/Text";
import toast from "react-hot-toast";
import Pagination from "components/Pagination";
import { EditContactModal } from "../modals/EditContactModal";
import ConfirmModal from "components/ConfirmModal";
import InputDropdown from "components/OldInput/Dropdown/InputDropdown";
import { LIMIT_OPTIONS, THIRD_PARTY_TYPE } from "../constants";
import { ContactsTable } from "../components/ContactsTable";

export function ContactsView({ contactType, contactTitle }) {
    const [limit, setLimit] = useState(20);
    const [contacts, setContacts] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1);
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
        useState(false);

    const searchIcon = "/images/icons/search.svg";

    const loadContactsList = async (searchstring, type) => {
        setPage(1);
        try {
            const data = await Api.searchContacts({
                searchstring,
                type,
            });
            setContacts(data);
        } catch (error) {
            toast.error(
                "Något gick fel när vi hämtade kontakter. Försök igen.",
            );
        }
    };

    const currentPage = (contacts ?? [])?.slice(
        (page - 1) * limit,
        page * limit,
    );

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        setPage(1);
    }, [limit]);

    const navigateToContact = (contactId) => {
        setSelectedContactId(contactId);
        setOpenCreateDialog(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await Api.deleteSelectedContacts(selectedContactIds);

            setContacts((prevContacts) =>
                prevContacts.filter(
                    (contact) => !selectedContactIds.includes(contact.id),
                ),
            );

            setSelectedContactIds([]);

            toast.success("Kontakterna har tagits bort");
        } catch (error) {
            toast.error("Det gick inte att ta bort kontakterna.");
        } finally {
            setIsConfirmDeleteModalOpen(false);
        }
    };

    const search = async () => {
        setIsSearching(true);
        await loadContactsList(searchText, contactType);
        setIsSearching(false);
    };

    const handleCheckboxChange = (contactId) => {
        setSelectedContactIds((prevSelectedIds) => {
            const isSelected = prevSelectedIds.includes(contactId);
            if (isSelected) {
                return prevSelectedIds.filter((id) => id !== contactId);
            } else {
                return [...prevSelectedIds, contactId];
            }
        });
    };

    const handleSelectAllChange = (isChecked) => {
        if (isChecked) {
            const selectedUniqueIds = [
                ...new Set(
                    selectedContactIds.concat(
                        currentPage.map((contact) => contact.id),
                    ),
                ),
            ];
            setSelectedContactIds(selectedUniqueIds);
        } else {
            const filteredContactIds = selectedContactIds.filter(
                (id) => !currentPage.map((page) => page.id).includes(id),
            );
            setSelectedContactIds(filteredContactIds);
        }
    };

    return (
        <div className="w-full flex justify-start flex-col box-border">
            <div className="flex items-center gap-10 p-3">
                <div className="flex gap-4 h-full items-end self-end w-full">
                    <h1 className="text-3xl font-bold w-44">{contactTitle}</h1>
                    <InputField
                        placeholder="Sök kontakt"
                        maxLength={50}
                        onChange={setSearchText}
                        onClickSearch={search}
                        icon={searchIcon}
                    />
                </div>
                <div className="flex items-center justify-end w-full">
                    <div className="text-neutral-800 text-sm font-extralight mr-3">
                        {selectedContactIds?.length > 0
                            ? `Påverkar ${
                                  selectedContactIds?.length ?? 0
                              } valda kontakter`
                            : ""}
                    </div>
                    <button
                        onClick={() => setIsConfirmDeleteModalOpen(true)}
                        className="c-button c-button--ghost"
                        style={
                            selectedContactIds.length > 0
                                ? {
                                      color: "#BA000D",
                                      borderColor: "#BA000D",
                                  }
                                : {}
                        }
                        disabled={selectedContactIds.length === 0}
                    >
                        {contactType === THIRD_PARTY_TYPE
                            ? "Radera tredje part"
                            : "Radera kontakter"}
                    </button>
                    <button
                        onClick={() => {
                            setSelectedContactId(null);
                            setOpenCreateDialog(true);
                        }}
                        className="c-button--raised c-button"
                        disabled={!!isSearching}
                    >
                        {contactType === THIRD_PARTY_TYPE
                            ? "Skapa ny tredje part"
                            : "Skapa ny kontakt"}
                    </button>
                </div>
            </div>

            <div className="flex items-center justify-start mx-3 mb-3">
                <div className="w-full">
                    {contactType === THIRD_PARTY_TYPE && (
                        <>
                            <p className="text-sm">
                                En tredje part kan exempelvis vara ett
                                dotterbolag eller en återförsäljare.
                            </p>
                            <p className="text-sm">
                                Den tredje parten kan användas vid val av vem
                                som ska betala tullfakturor och moms, där den
                                tredje parten blir köpare, men ej mottagare.
                            </p>
                        </>
                    )}
                </div>
                <div
                    className="flex items-center justify-end"
                    style={{ minWidth: "200px" }}
                >
                    <div className="text-neutral-800 text-sm font-extralight mr-2">
                        Visa antal per sida:
                    </div>
                    <div style={{ maxWidth: "120px" }}>
                        <InputDropdown
                            options={LIMIT_OPTIONS}
                            default={limit}
                            value={limit}
                            onChange={setLimit}
                        />
                    </div>
                </div>
            </div>
            {isSearching ? (
                <Loader />
            ) : (
                <>
                    <ContactsTable
                        currentPage={currentPage}
                        selectedContactIds={selectedContactIds}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSelectAllChange={handleSelectAllChange}
                        navigateToContact={navigateToContact}
                    />
                    {!currentPage?.length && !isSearching && (
                        <div className="flex justify-center items-center h-32">
                            <p className="text-xl">Inga kontakter hittades</p>
                        </div>
                    )}

                    <Pagination
                        setPage={setPage}
                        page={page}
                        maxPage={Math.ceil((contacts?.length ?? 1) / limit)}
                        useUrlParam={false}
                    />
                </>
            )}
            <EditContactModal
                isOpen={openCreateDialog}
                setIsOpen={setOpenCreateDialog}
                contactId={selectedContactId}
                onContactUpdated={search}
                type={contactType}
            />
            <ConfirmModal
                isOpen={isConfirmDeleteModalOpen}
                setIsOpen={setIsConfirmDeleteModalOpen}
                onConfirm={handleDeleteConfirm}
                title={"Bekräfta borttagning"}
                description={`Är du säker på att du vill ta bort valda kontakter? (${selectedContactIds.length} st)`}
            />
        </div>
    );
}
