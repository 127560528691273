import HelpTextIcon from "../../../Components/InformationIcon";

function VismaAdminStepTwo() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Visma Inställningar
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col text-md font-medium space-y-8 w-1/2">
                    <p>
                        I detta steg ska du fylla i dina inloggningsuppgifter
                        till ditt Visma-konto och konfigurera nödvändiga
                        sökvägar för företaget och dess gemensamma filer.
                    </p>
                    <ul className="list-disc list-outside pl-6 space-y-4">
                        <li>
                            Ange din signatur och lösenord för att autentisera
                            anslutningen till Visma.
                        </li>
                        <div className="flex items-start">
                            <li>
                                Lägg till sökvägen till det företaget ni önskar
                                integrera med.
                            </li>
                            <HelpTextIcon
                                infoText={
                                    'Exempelvis: "C:\\ProgramData\\SPCS\\SPCS Administration\\Företag\\Företagsnamn"<br/>"ProgramData" kan vara en dold mapp på windows, så ni kan behöva aktivera "Dolda Objekt" i filutforskaren för att se mappen.'
                                }
                            />
                        </div>

                        <div className="flex items-start">
                            <li>
                                Lägg till gemensamma filer för det företaget ni
                                önskar integrera med.
                            </li>
                            <HelpTextIcon
                                infoText={
                                    'Exempelvis: "C:\\ProgramData\\SPCS\\SPCS Administration\\Gemensamma Filer"<br/>"ProgramData" kan vara en dold mapp på windows, så ni kan behöva aktivera "Dolda Objekt" i filutforskaren för att se mappen.'
                                }
                            />
                        </div>
                    </ul>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={"/images/extensions/visma/login.png"}
                        alt="Visma Inställningar"
                        className="w-[32rem] h-[32rem] object-contain max-w-2xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default VismaAdminStepTwo;
