import DisplayField from "../../../Components/DisplayField";

function OnGoingWmsStepFour({ configuration, activateExtension }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 4 -{" "}
                <span className="text-lg font-medium text-accent">
                    {!configuration.accessToken
                        ? "Aktivera integration"
                        : "Konfigurera webhook"}
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        {!configuration.accessToken ? (
                            <>
                                <li>
                                    För att få tillgång till din "Token" och
                                    slutföra skapandet av integrationen krävs
                                    det att du aktiverar den nedan.
                                </li>
                                <button
                                    className="c-button c-button--raised mt-4 w-60"
                                    onClick={activateExtension}
                                >
                                    Aktivera Integration
                                </button>
                            </>
                        ) : (
                            <>
                                <div className="flex flex-col">
                                    <li>
                                        Under "Autentisering" välj
                                        "Fixed-X-Auth-Token" och klistra in
                                        API-nyckeln nedan:
                                    </li>
                                    <DisplayField
                                        value={configuration.accessToken}
                                        copyToClipboard={true}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <li>
                                        Under "Inställningar" klistra in
                                        följande URL:
                                    </li>
                                    <DisplayField
                                        value={`${process.env.REACT_APP_API_URL}/api/wh/fRf4sWMwVJDd1ckRaRVf7s4XdPXT05OVzhtPmr79KH3M8sbiXMFr3PUmC32mXPDT`}
                                        copyToClipboard={true}
                                    />
                                </div>
                                <div className="flex flex-col mt-6">
                                    <li>
                                        Markera rutan "Aktivera" för att
                                        aktivera webhooken innan du skapar den.
                                    </li>
                                </div>
                                <div className="flex flex-col mt-6">
                                    <li>
                                        Klicka på "Skapa" för att skapa
                                        webhooken.
                                    </li>
                                </div>
                            </>
                        )}
                    </ul>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={`/images/extensions/ongoingwms/webhookconfiguration.png`}
                        alt="webhook configuration"
                        className="w-[32rem] h-[32rem] object-contain max-w-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default OnGoingWmsStepFour;
