import { handleDownloadS3 } from "../../../../../utils/s3";

function VismaAdminStepOne() {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Installation
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Klicka på länken nedan för att ladda ner
                            installationsfilen för Zendr Visma Extension.
                        </li>
                        <a
                            onClick={() =>
                                handleDownloadS3({
                                    url: `${
                                        process.env.REACT_APP_API_URL
                                    }/api/s3/integration?objectKey=${encodeURIComponent(
                                        `visma/Zendr_Visma_Extension_Installer_${process.env.REACT_APP_VISMA_LATEST_VERSION}.exe`,
                                    )}`,
                                    desiredFileName: `Zendr_Visma_Extension_Installer_${process.env.REACT_APP_VISMA_LATEST_VERSION}.exe`,
                                })
                            }
                        >
                            <p className="text-blue-700 cursor-pointer underline">
                                Zendr_Visma_Extension_Installer.exe
                            </p>
                        </a>
                        <li>
                            Följ anvisningarna i installationsguiden tills
                            installationen är klar.
                        </li>

                        <li>
                            När installationen är slutförd, återgå till den här
                            sidan och klicka på knappen "Nästa" för att
                            fortsätta.
                        </li>
                    </ul>
                </div>

                <div className="ml-10 mr-10">
                    <img
                        src={"/images/extensions/visma/installation.png"}
                        alt="Step one"
                        className="w-[48rem] h-[48rem] object-contain max-w-3xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default VismaAdminStepOne;
