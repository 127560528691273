import Input from "components/OldInput";
import Context from "context/Global";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";

let Container = styled.div`
    > .title {
        font-size: 2rem;
        padding: 1rem;
    }
    > .description {
        padding: 0 1rem;
    }

    > .form {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    > .buttons {
        padding: 1rem;
    }

    ul {
        padding: 1rem 0;
        > li {
            list-style-type: disc;
            list-style-position: inside;
            margin-left: 2rem;
        }
    }

    a {
        color: #0b0bc2;

        &:hover {
            text-decoration: underline;
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const [data, setData] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [validDomain, setValidDomain] = useState(false);

    useEffect(() => {
        setValidDomain(isValidDomain(data.identifier));
    }, [data]);

    function isValidDomain(domain) {
        const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return domainPattern.test(domain);
    }

    async function addIntegration() {
        try {
            if (isValidDomain(data.identifier)) {
                setLoading(true);

                const fromValues = {
                    ...data,
                };

                Api.createCustomerIntegration({
                    customerId: context.user.customer.id,
                    data: fromValues,
                })
                    .then((res) => {
                        props.history.push(`/extensions/${res.id}`);
                    })
                    .catch((error) => console.error(error));
            } else {
                setErrorMessage(
                    "Ogiltigt domännamn. Ange ett giltigt domännamn, t.ex. 'webshop.mitt-foretag.se'.",
                );
                setLoading(false);
            }
        } catch (error) {
            setErrorMessage(
                "Ett oväntat fel uppstod när vi försökte aktivera din integration. Vänligen försök igen senare.",
            );
            setLoading(false);
        }
    }

    return (
        <Container>
            <div className="title">Lägg till joomla - VirtueMart</div>
            <div className="description">
                <p className="mb-2">
                    Ange ditt domännamn i inmatningsfältet för att aktivera
                    Joomla - VirtueMart Integration.
                    <code className="ml-1">webshop.mitt-foretag.se</code>
                </p>
                <p className="mb-2">
                    Användarnamn och Lösenord endast nödvändigt om er
                    Joomla-server är skyddad med sådan authentisering.
                </p>
            </div>
            <div className="form">
                <Input
                    type="list"
                    onChange={setData}
                    object={{
                        type: {
                            type: "text",
                            hidden: true,
                            value: "JoomlaVirtueMart",
                        },
                        identifier: {
                            title: "Din webshops domän",
                            placeholder: "webshop.mitt-foretag.se",
                            type: "text",
                            required: true,
                        },
                        username: {
                            title: "Användarnamn",
                            placeholder: "admin",
                            type: "text",
                        },
                        password: {
                            title: "Lösenord",
                            placeholder: "********",
                            type: "password",
                        },
                    }}
                />
            </div>
            <div className="buttons">
                <button
                    className="c-button c-button--raised"
                    disabled={!validDomain || loading}
                    onClick={addIntegration}
                >
                    Aktivera
                </button>
            </div>
            {errorMessage && <div className="error">{errorMessage}</div>}
        </Container>
    );
}

export default Component;
