import HelpTextIcon from "../../../Components/InformationIcon";

function OnGoingWmsStepTwo({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 2 -{" "}
                <span className="text-lg font-medium text-accent">
                    Konfigurera Integrationen
                </span>
            </h3>

            <div className="flex flex-row items-start space-x-8">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Fyll i uppgifterna nedan såsom ni önskar att
                            integrationen ska fungera.
                        </li>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="pickupAddress"
                                    className="block font-medium"
                                >
                                    Välj upphämtningsadress
                                </label>{" "}
                                <HelpTextIcon
                                    infoText={
                                        'Här får du välja vilken upphämtningsadress ordern ska använda:<br/>"Godsägarens Ongoing" hämtar uppgifterna från "Kontaktinformation" i Godsägare kortet.<br/>"Kontohavaren Zendr" hämtar uppgifterna från "Mitt Konto" i Zendr portalen.'
                                    }
                                />
                            </div>
                            <select
                                name="pickupAddress"
                                value={configuration.pickupAddress}
                                onChange={handleChange}
                                className="w-full p-2 h-10 border border-gray-300 rounded-md"
                                required
                            >
                                <option value="" disabled hidden>
                                    Välj ett alternativ
                                </option>
                                <option value="Godsägare">
                                    Godsägarens Ongoing
                                </option>
                                <option value="Kontohavare">
                                    Kontohavaren Zendr
                                </option>
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="includeOrderIdInReference"
                                className="block font-medium"
                            >
                                Inkludera Order-ID i Referens Fältet
                            </label>
                            <select
                                name="includeOrderIdInReference"
                                value={configuration.includeOrderIdInReference}
                                onChange={handleChange}
                                className="w-full p-2 h-10 border border-gray-300 rounded-md"
                                required
                            >
                                <option value="" disabled hidden>
                                    Välj ett alternativ
                                </option>
                                <option value="Ja">Ja</option>
                                <option value="Nej">Nej</option>
                            </select>
                        </div>
                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="selectGoodsSource"
                                    className="block font-medium"
                                >
                                    Vart ska kolli dimensionerna hämtas ifrån?
                                </label>
                                <HelpTextIcon
                                    infoText={
                                        'Här får du välja vart ifrån ordern kolli information ska hämtas:<br/>"Fraktsedelserader" hämtar dimensionerna från "Fraktsedelsraderna" på ordern.<br/>"Orderrader" hämtar dimensionerna från "Orderrader" på ordern.'
                                    }
                                />
                            </div>

                            <select
                                name="selectGoodsSource"
                                value={configuration.selectGoodsSource}
                                onChange={handleChange}
                                className="w-full p-2 h-10 border border-gray-300 rounded-md"
                                required
                            >
                                <option value="" disabled hidden>
                                    Välj ett alternativ
                                </option>
                                <option value="Fraktsedelsrader">
                                    Fraktsedelsrader
                                </option>
                                <option value="Orderrader">Orderrader</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnGoingWmsStepTwo;
