import { getIntegrationImage } from "../../../helpers/Logos";

function ExtensionImage({ type }) {
    return (
        <div className="w-48 h-32 flex items-center justify-center border border-gray-300 rounded-md">
            <img
                src={`/images/icons/${getIntegrationImage(type)}`}
                alt={`logo`}
                className="w-32 h-16 object-contain"
            />
        </div>
    );
}

export default ExtensionImage;
