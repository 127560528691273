import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import Input from "components/OldInput";
import Api from "services/Api/Api";
import toast from "react-hot-toast";
import {
    displayWarningMessage,
    passwordValidation,
} from "utils/passwordValidation";

let Container = styled.div`
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .loader {
        width: 200px;
        height: 200px;
    }

    > .newBrand {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding-top: 4rem;

        > img {
            height: 32px;
        }
    }
    > .newBrand-text {
        padding-top: 1rem;
        display: flex;
        text-align: center;
    }
`;

let Form = styled.form`
    display: flex;
    flex-direction: column;

    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 32px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;

    > .inputFields {
        margin-bottom: 16px;
    }

    > .logo {
        width: 100%;
        margin-bottom: 20px;

        > .c-logo {
            width: 100%;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    > .forgot {
        text-align: center;
        margin-top: 20px;
        gap: 1rem;
        display: flex;
        justify-content: center;

        > a {
            text-decoration: none;
            font-size: 10px;
            text-align: center;
            font-weight: bold;
            color: #777;
        }
        > a:hover {
            text-decoration: underline;
        }
    }

    > .disclaimer {
        text-align: center;
        margin-top: 20px;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.5);
    }
`;

const ResetPassword = ({ admin }) => {
    const [form, setForm] = useState({
        password: null,
        confirmPassword: null,
    });
    const [disabled, setDisabled] = useState(false);
    const inputRef = useRef();
    const token = window.location.href.split("?token=")[1];

    useEffect(() => {
        if (!token) {
            window.location.href = "/";
        }
    }, []);

    useEffect(() => {
        if (!disabled) {
            return;
        }

        const timer = setTimeout(() => {
            setDisabled(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, [disabled]);

    async function handleSubmit(e) {
        e.preventDefault();
        setDisabled(true);

        try {
            if (inputRef.current?.validate()) {
                if (form.password !== form.confirmPassword) {
                    return toast.error("Lösenorden matchar inte");
                }

                if (!passwordValidation(form.password, admin)) {
                    return toast.error(displayWarningMessage(admin), {
                        duration: 10000,
                        style: {
                            width: "500px",
                        },
                    });
                }

                const res = await Api.post({
                    url: "/api/password/reset",
                    data: {
                        token,
                        password: form.password,
                        confirmPassword: form.confirmPassword,
                    },
                });

                if (res.status === 200) {
                    window.location.href = "/login";
                }
            }
        } catch (e) {
            const toastMessage = admin
                ? e?.response?.data?.message || e.message
                : "Något gick fel, försök igen senare. Annars kontakta våran kundtjänst.";
            toast.error(toastMessage);
        }
    }

    return (
        <Container>
            <Form>
                <Input
                    className="inputFields"
                    type="list"
                    ref={inputRef}
                    onChange={setForm}
                    value={form}
                    placeholder="Nytt lösenord"
                    object={{
                        password: {
                            placeholder: "Nytt lösenord",
                            type: "password",
                        },
                        confirmPassword: {
                            type: "password",
                            placeholder: "Bekräftta nytt lösenord",
                        },
                    }}
                />

                <input
                    type="submit"
                    className="c-button c-button--raised"
                    onClick={handleSubmit}
                    value="Byt lösenord"
                    disabled={disabled}
                />
                <div className="disclaimer">{displayWarningMessage(admin)}</div>
            </Form>
        </Container>
    );
};

export default ResetPassword;
