import React, { useContext, useEffect, useState } from "react";
import ExtensionCard from "./Components/ExtensionCard";
import { ConfigurationOptions } from "./utils/configurationOptions";
import Context from "../../context/Global";
import useIntegration from "./hooks/useIntegration";
import ConfigurableForm from "./Components/ConfigurableForm";
import Api from "../../services/Api/Api";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import ConfirmModal from "../../components/ConfirmModal";
import { updateCustomerIntegrationsList } from "./utils/authUtils";

const ExtensionDetails = ({ extensionId, history }) => {
    const context = useContext(Context);
    const [editMode, setEditMode] = useState(false);
    const [initialState, setInitialState] = useState(null);
    const [currentState, setCurrentState] = useState({});
    const [updating, setUpdating] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const {
        extension,
        loading,
        expiringExtension,
        expiredExtension,
        handleRenewExtensionAuthorization,
    } = useIntegration({
        customerId: context.user.customer.id,
        extensionId,
    });

    useEffect(() => {
        if (extension) {
            const { data, identifier } = extension;
            let configToEdit = {
                ...(data?.config || data?.settings || data),
                identifier,
            };

            setInitialState(configToEdit);
            setCurrentState(configToEdit);
        }
    }, [extension]);

    const integrationConfig = ConfigurationOptions[extension?.type] || {};
    const canUpdateSettings = integrationConfig.updateConfigurationSettings;

    const handleInputChange = (fieldKey, value) => {
        setCurrentState((prevState) => ({
            ...prevState,
            [fieldKey]: value,
        }));
    };

    const hasUnsavedChanges = () => {
        return JSON.stringify(initialState) !== JSON.stringify(currentState);
    };

    const handleUpdateSettings = async () => {
        if (hasUnsavedChanges()) {
            try {
                setUpdating(true);

                let updatedData = { ...extension.data };

                if (extension.data.config) {
                    updatedData.config = currentState;
                } else if (extension.data.settings) {
                    updatedData.settings = currentState;
                } else {
                    updatedData = currentState;
                }

                await Api.updateIntegration({
                    integrationId: extension.id,
                    data: updatedData,
                });

                setInitialState(currentState);
                toast.success("Inställningar uppdaterades");
            } catch (error) {
                toast.error("Misslyckades med att uppdatera inställningar");
            } finally {
                setUpdating(false);
            }
        }
        setEditMode(false);
    };

    const handleRemoveExtension = async () => {
        try {
            setRemoving(true);
            await Api.removeCustomerIntegration({
                customerId: context.user.customer.id,
                integrationId: extension.id,
            });

            updateCustomerIntegrationsList(context, extension.id);

            history.push("/extensions");
        } catch (error) {
            toast.error("Misslyckades med att ta bort integrationen");
        } finally {
            setRemoving(true);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="mt-10 ml-20 mr-20 flex flex-col space-y-10 min-h-[80vh]">
            {extension && (
                <div className="flex-grow space-y-10">
                    <ExtensionCard extension={extension} history={history}>
                        <div className="flex flex-col ml-8">
                            <h1 className="text-xl font-semibold">
                                {extension.data.type}
                            </h1>
                            <p className="text-base font-medium text-accent">
                                {expiredExtension ? "Utgått" : "Aktiv"}
                            </p>

                            {expiringExtension && !expiredExtension && (
                                <div className="flex items-center mt-2">
                                    <img
                                        src="/images/icons/warning-icon.svg"
                                        alt="Warning icon"
                                        className="w-5 h-5 mr-2"
                                    />
                                    <p className="text-sm text-warning">
                                        Går snart ut. Vänligen uppdatera
                                    </p>
                                </div>
                            )}

                            {expiredExtension && (
                                <div className="flex items-center mt-2">
                                    <img
                                        src="/images/icons/error-icon.svg"
                                        alt="Error icon"
                                        className="w-5 h-5 mr-2"
                                    />
                                    <p className="text-sm text-error">
                                        Aktivera igen för att fortsätta
                                        importera ordrar automatiskt
                                    </p>
                                </div>
                            )}
                        </div>
                    </ExtensionCard>

                    <ConfigurableForm
                        config={integrationConfig}
                        currentState={currentState}
                        editMode={editMode}
                        handleInputChange={handleInputChange}
                    />
                </div>
            )}

            {canUpdateSettings && (
                <div className="flex justify-start space-x-8 mb-10">
                    <button
                        className={
                            editMode
                                ? "c-button c-button--raised w-52 h-10"
                                : "c-button c-button--ghost w-52 h-10"
                        }
                        onClick={() =>
                            editMode
                                ? handleUpdateSettings()
                                : setEditMode(true)
                        }
                        disabled={
                            updating || (editMode && !hasUnsavedChanges())
                        }
                    >
                        {editMode
                            ? "Spara Inställningar"
                            : "Ändra Inställningar"}
                    </button>
                    {editMode && (
                        <button
                            className="c-button c-button--ghost w-52 h-10"
                            onClick={() => {
                                setCurrentState(initialState);
                                setEditMode(false);
                            }}
                            disabled={updating}
                        >
                            Avbryt
                        </button>
                    )}
                </div>
            )}

            <div className="flex space-x-8 w-3/4 pt-10">
                {(expiringExtension || expiredExtension) && (
                    <button
                        className="c-button c-button--ghost w-56 h-10"
                        onClick={() => handleRenewExtensionAuthorization()}
                        disabled={updating}
                    >
                        <p>Uppdatera integrationen</p>
                    </button>
                )}
                <button
                    className="c-button c-button--outline-red w-48 h-10"
                    onClick={() => setIsConfirmModalOpen(true)}
                    disabled={removing}
                >
                    <p>Ta bort integrationen</p>
                </button>

                <ConfirmModal
                    isOpen={isConfirmModalOpen}
                    setIsOpen={setIsConfirmModalOpen}
                    onConfirm={handleRemoveExtension}
                    title="Du är på väg att ta bort din koppling till Zendr"
                    description="Är du säker på att du vill ta bort kopplingen?"
                />
            </div>
        </div>
    );
};

export default ExtensionDetails;
