import ExtensionCard from "./ExtensionCard";

function ZendrApiCardSection() {
    return (
        <ExtensionCard extension={{ type: "Zendr" }}>
            <h3 className="text-xl font-semibold">Eget system - Zendr API</h3>
            <p className="font-medium text-base mt-1">
                Utvecklar ni ett eget system och vill integrera mot oss? Då har
                vi ett enkelt alternativ för att skapa integrationen själv!
            </p>
        </ExtensionCard>
    );
}

export default ZendrApiCardSection;
